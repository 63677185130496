/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ListWithFiltersAndSearch } from '../ListWithFiltersAndSearch';
import { useContentLoader } from '../../bus/calls/hooks/useContentLoader';
import { useFiltersContentLoader } from '../../bus/calls/hooks/useFiltersContentLoader';
import styles from './index.module.less';
import {
    normalizeContentType,
    getContentNormalized,
    isShareable,
} from '../../helpers';
import { ContentThumbnail } from '../ContentThumbnail';
import { PropTypeArray, PropTypeFunc } from '../../helpers/propTypes';

const { Text } = Typography;
export function ContentSelector({
    onContentChanged,
    preSelectedContents = [],
    getMessagingTemplatesByContentIdFn,
}) {
    const {
        filtersContent,
        isFetchingFiltersContent,
        error: isErrorGetFiltersContent,
    } = useFiltersContentLoader();

    const computeTableHeight = (windowHeight) => {
        const xLargeScreenHeight = 1200;
        const largeScreenHeight = 1000;
        const xlargeScreenHeightRatio = 10;
        const largeScreenHeightRatio = 5;

        if (windowHeight >= xLargeScreenHeight) { return windowHeight / xlargeScreenHeightRatio; }
        if (windowHeight > largeScreenHeight) { return windowHeight / largeScreenHeightRatio; }
        return 220;
    };

    /**
     * Get Height of Antd table base on different window height
     */
    const getTableScrollY = () => {
        const { innerHeight } = window;
        return innerHeight / 2 - computeTableHeight(innerHeight);
    };

    /**
     * this function return a number base on window height to reduce the height of table
     * base on window height
     * @param {Number} windowHeight
     */

    const {
        contents,
        selectedContents,
        setSelectedContents,
        searchTerm: searchContentTerm,
        handleSearch: handleSearchContent,
        currentPage: currentPageContent,
        handlePageChange: handlePageChangeContent,
        selectedFilters: selectedFiltersContent,
        handleSelectedFilters: handleSelectedFiltersContent,
        exactFilters: exactFiltersContent,
        handleExactFilters: handleExactFiltersContent,
        isFetching: isFetchingContent,
        error: isErrorGetContent,
    } = useContentLoader({ initialSelectedContents: preSelectedContents });
    useEffect(() => {
        if (selectedContents.length > 0) {
            onContentChanged(selectedContents[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContents]);
    const handleContentSearch = (e) => {
        e.persist();
        const { value } = e.target;
        handleSearchContent(value);
    };
    const { total: totalContents } = contents?.meta || [];
    const onChangeContentPage = (page) => handlePageChangeContent(page);
    const columnsContents = [
        {
            title: <FormattedMessage className="ml-10" id="app.content" />,
            dataIndex: 'thumbnailUrl',
            className: styles.thumbnail,
            render: (prop, record) => {
                const version = record?.versions?.[0]?.number;
                return (
                    <div className="flex items-center">
                        <ContentThumbnail version={version} src={prop} />
                        <span className="ml-5">{record.name}</span>
                    </div>
                );
            },
        },
        {
            title: <FormattedMessage id="app.type" />,
            dataIndex: 'type',
            render: (prop) => <Text>{normalizeContentType(prop)}</Text>,
        },
    ];

    return (
        <ListWithFiltersAndSearch
            filters={filtersContent}
            errorFilters={
                isErrorGetFiltersContent && (
                    <FormattedMessage id="error.filters" />
                )
            }
            isFetchingFilters={isFetchingFiltersContent}
            selectedFilters={selectedFiltersContent}
            setSelectedFilters={handleSelectedFiltersContent}
            exactFilters={exactFiltersContent}
            setExactFilters={handleExactFiltersContent}
            onSearchInputChange={(e) => handleContentSearch(e)}
            searchTerm={searchContentTerm}
            error={
                isErrorGetContent && <FormattedMessage id="error.getContents" />
            }
            loadingTable={isFetchingContent}
            dataSource={
                contents.data.filter((c) => isShareable(c, getMessagingTemplatesByContentIdFn(c.id))) || []
            }
            columns={columnsContents}
            paginationTotalItems={totalContents}
            onChangePage={onChangeContentPage}
            currentPage={currentPageContent}
            selectedItemsNormalized={getContentNormalized(preSelectedContents)}
            selectedItems={preSelectedContents}
            setSelectedItems={setSelectedContents}
            showMessageOnEmptySelection={false}
            tableProps={{ scroll: { y: getTableScrollY() + 100 } }}
        />
    );
}

ContentSelector.propTypes = {
    onContentChanged: PropTypeArray,
    preSelectedContents: PropTypeArray,
    getMessagingTemplatesByContentIdFn: PropTypeFunc,
};
