import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import schema from '../../schemas/callsSchema.json';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* getCallsWorker({ payload }) {
    const options = {
        fetcher: api.calls.getCalls,
        fetcherParam: payload,
        successAction: callsActions.fillCalls,
        startFetching: callsActions.getCallsStartFetching,
        stopFetching: callsActions.getCallsStopFetching,
        setErrorAction: callsActions.getCallsError,
        schema,
    };

    yield makeRequestWorker(options);
}
