import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { ContentThumbnail } from '../ContentThumbnail';

export const ContentItem = ({ title, description, thumbnailUrl, version, handleClick }) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="ml-4 flex items-center cursor-pointer" onClick={handleClick} tabIndex={0} role="button" aria-pressed="false">
        <ContentThumbnail version={version} src={thumbnailUrl} />
        <span className="ml-3 font-medium">{title}</span>
        {description && (
            <Popover
                title="Description" /* TODO: translate */
                content={(
                    <pre
                        className="overflow-y-scroll overflow-x-auto text-gray-600 max-w-xs text-vsm"
                        style={{ maxHeight: ' 260px' }}
                    >
                        {description}
                    </pre>
                )}
            >
                <span className="ml-1 mb-6">
                    <InfoCircleFilled style={{ fontSize: '12px', color: '#d0d0d0' }} />
                </span>
            </Popover>
        )}
    </div>
);

ContentItem.defaultProps = {
    description: null,
    thumbnailUrl: null,
};

ContentItem.propTypes = {
    title: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    description: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
};
