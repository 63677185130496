import React from 'react';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';

export function ProvisionStateError() {
    return (
        <Alert
            message={<FormattedMessage id="error.provisioning.provision" />}
            type="error"
            closable
        />
    );
}
