import { useEffect, useState } from 'react';
import { sortChannelByPriority } from '../../../helpers';

export const useEditCallOptions = (contents) => {
    const [contentsOptions, setContentsOptions] = useState([]);

    /* Normalize content (get only 'remote' content) */
    useEffect(() => {
        if (contents) {
            const remoteContents = contents.filter(((content) => content.channels.includes('remote'))).map((content) => ({ ...content, channels: sortChannelByPriority(content.channels) }));
            setContentsOptions(remoteContents);
        }
    }, [contents]);

    return {
        contentsOptions,
    };
};
