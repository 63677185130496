/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as messagingTemplateActions from '../actions';

export const useMessagingTemplates = () => {
    const dispatch = useDispatch();

    const isFetching = useSelector((state) => state.messagingTemplate.isFetching);
    const isFetched = useSelector((state) => state.messagingTemplate.isFetched);
    const templates = useSelector((state) => state.messagingTemplate.data);

    const loadMessagingTemplates = () => {
        dispatch(messagingTemplateActions.fetchMessagingTemplate());
    };

    useEffect(() => {
        loadMessagingTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTemplatesByContentId = (contentId) => templates.filter((template) => template.contentIds.includes(contentId));
    const getTemplateById = (templateId) => templates.find((t) => t.id === templateId);
    return {
        isFetching,
        isFetched,
        getTemplatesByContentId,
        getTemplateById,
        templates,
    };
};
