/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Alert, Button, PageHeader, Table, Tooltip, Typography } from 'antd';
import cookie from 'js-cookie';
import { Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { callsActions } from '../../bus/calls/actions';
import {
    CALL_STATUS_FILTER,
    CALLS_PER_PAGE,
    MOBILIZER_TOKEN,
    USER_PERMISSIONS,
} from '../../config/constants';
import { book } from '../../routing/book';
import { useGetCalls } from '../../bus/calls/hooks/useGetCalls';
import { EditOrCreateCallModal } from '../EditOrCreateCallModal';
import { useDeleteCall } from '../../bus/calls/hooks/useDeleteCall';
import styles from './index.module.less';
import { CallFieldsModal } from '../CallFieldsModal';
import { useCallFieldsLoader } from '../../bus/calls/hooks/useCallFieldsLoader';
import { getContentNormalized } from '../../helpers';
import { ContentThumbnail } from '../ContentThumbnail';
import { useBreakpoint } from '../../bus/calls/hooks/useBreakpoint';
import { CallDetails } from './CallDetails';
import ActionColumn from './ActionColumn';
import DateTime from './DateTime';

const { Text } = Typography;

export const CallsList = ({
    userPermissions,
    disableCreateCallButton,
    disablePagination,
    CustomHeader,
    customCallFilter,
}) => {
    const bp = useBreakpoint();
    const token = cookie.get(MOBILIZER_TOKEN);
    const dispatch = useDispatch();
    const {
        calls,
        isFetching: isFetchingGetCalls,
        error: isErrorGetCalls,
        handleTableChange,
        listParams,
        highlightedCallId,
    } = useGetCalls(CALL_STATUS_FILTER);

    useCallFieldsLoader(); // pre-loads the post-call fields
    useEffect(
        () => () => {
            dispatch(callsActions.clearHighlightedCallId());
        },
        [dispatch],
    );

    const { deleteCall, errorDeleteCall, isFetchingDeleteCall } = useDeleteCall();

    const handleDelete = (call) => {
        deleteCall(call);
    };

    const errorGetCallsJSX = isErrorGetCalls && (
        <Alert message={<FormattedMessage id="error.getCalls" />} type="error" />
    );
    const errorDeleteCallJSX = errorDeleteCall && (
        <Alert
            message={<FormattedMessage id="error.deleteCall" />}
            className="mb-1"
            type="error"
        />
    );

    const { totalResult } = (calls || []).meta || [];

    const getPresentersJoin = (call) => {
        const presentersJoin = (call && call.joins).find((join) => join.type === 'presenter') || {};
        return presentersJoin.id;
    };

    let normalizedData = [];

    if (calls && calls.data && Boolean(calls.data.length)) {
        calls.data.forEach((call) => normalizedData.push({
            key: call.id,
            timestamp: call.timestamp,
            modifiedAt: parseInt(call.modificationStamp.split('time_').pop(), 10),
            call,
            contacts:
          call
          && call.contacts
          && call.contacts.map(
              (contact) => `${contact.firstName} ${contact.lastName}`,
          ),
            content: call && call.contents && getContentNormalized(call.contents),
            meetNowLink: call.meet_now_url, // mapping meet_now_url
            presentersUrl: `${
                process.env.REACT_APP_REMOTE
            }/join/${getPresentersJoin(call)}?token=${token}&client=remote-web`,
        }));

        if (customCallFilter) {
            normalizedData = normalizedData.filter(customCallFilter);
        }
    }

    const contentTitleTooltip = (content) => (
        <div className="text-white text-center">
            <p className="block text-white font-semibold">{content.label}</p>
            <p className="block text-white text-sm font-semibold">
                ver {content.version}
            </p>
        </div>
    );

    const expandable = {
        expandedRowRender: (record) => <CallDetails call={record.call} />,
        defaultExpandedRowKeys: highlightedCallId ? [highlightedCallId] : [],
        onExpand: (expanded, record) => {
            if (!expanded && record.call.id === highlightedCallId) {
                dispatch(callsActions.clearHighlightedCallId());
            }
        },
    };

    const columns = [
        {
            title: <FormattedMessage id="app.date" />,
            sorter: true,
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp) => <DateTime {...{ timestamp }} />,
        },
        {
            title: <FormattedMessage id="app.updated" />,
            sorter: true,
            // defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            dataIndex: 'modifiedAt',
            key: 'modifiedAt',
            render: (modifiedAt) => <DateTime {...{ timestamp: modifiedAt }} />,
        },
        {
            title: <FormattedMessage id="app.participants" />,
            dataIndex: 'contacts',
            key: 'contacts',
            render: (contacts) => (
                <span>
                    {contacts && contacts.length ? (
                        contacts.map((contact, index) => (
                            <Text key={index} className={styles.contact}>
                                <span style={{ whiteSpace: 'nowrap' }}>{contact}</span>
                            </Text>
                        ))
                    ) : (
                        <></>
                    )}
                </span>
            ),
        },
        {
            title: <FormattedMessage id="app.content" />,
            dataIndex: 'content',
            key: 'content',
            render: (contents) => (
                <div className="flex flex-start flex-wrap">
                    {contents.map((content) => (
                        <Tooltip
                            key={content.value}
                            className="m-1"
                            title={contentTitleTooltip(content)}
                        >
                            <Text>
                                <ContentThumbnail
                                    src={content.thumbnail}
                                    version={content.version}
                                />
                            </Text>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        {
            title: (
                <span className="mr-4">
                    <FormattedMessage id="app.actions" />
                </span>
            ),
            align: 'right',
            render: (text, record) => <ActionColumn {...{ text, record, userPermissions, handleDelete }} />,
        },
    ];

    const createCallButtonJSX = userPermissions.includes(
        USER_PERMISSIONS.PLAN_CALLS,
    )
    && !disableCreateCallButton && (
        <Button type="primary" key="createCall">
            <NavLink to={book.createCall}>
                <FormattedMessage id="app.create" />
            </NavLink>
        </Button>
    );

    return (
        <>
            {errorGetCallsJSX}
            {errorDeleteCallJSX}
            {CustomHeader ? (
                <CustomHeader />
            ) : (
                <PageHeader
                    title={(
                        <span className="text-gray-700 tracking-wide">
                            <FormattedMessage id="app.calls" />
                        </span>
                    )}
                    extra={[createCallButtonJSX]}
                />
            )}
            {!errorGetCallsJSX && (
                <>
                    <Table
                        scroll={{ x: 600, y: 'calc(100vh - 370px)' }}
                        expandable={expandable}
                        sortOrder={listParams.order}
                        loading={isFetchingDeleteCall || isFetchingGetCalls}
                        dataSource={normalizedData}
                        columns={columns}
                        onChange={handleTableChange}
                        pagination={
                            disablePagination
                                ? false
                                : {
                                    defaultPageSize: CALLS_PER_PAGE,
                                    size: bp <= 3 ? 'small' : 'default',
                                    simple: bp === 1,
                                    total: totalResult,
                                    current: listParams.page,
                                    showTotal: (total, range) => (
                                        <FormattedMessage
                                            id="pagination.range"
                                            values={{
                                                range: `${range[0]} - ${range[1]}`,
                                                total,
                                            }}
                                        />
                                    ),
                                    showSizeChanger: false,
                                    position: ['bottomCenter'],
                                }
                        }
                    />

                    {/* Call Fields Modal */}
                    <Route path={`${book.calls}/:callId/call-fields`} key={1}>
                        <CallFieldsModal />
                    </Route>

                    {/** Modal Edit Call */}
                    {userPermissions.includes(USER_PERMISSIONS.PLAN_CALLS) && (
                        <Route path={`${book.calls}/:callId`} key={2} exact>
                            <EditOrCreateCallModal />
                        </Route>
                    )}
                </>
            )}
        </>
    );
};

CallsList.propTypes = {
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    disableCreateCallButton: PropTypes.bool,
    disablePagination: PropTypes.bool,
    CustomHeader: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.any,
    ]),
    customCallFilter: PropTypes.func,
};

CallsList.defaultProps = {
    disableCreateCallButton: false,
    disablePagination: false,
    CustomHeader: null,
    customCallFilter: null,
};
