import React from 'react';
import Iframe from 'react-iframe';
import { REVEALER_URL } from '../../config/constants';

export const Revealer = () => (
    <Iframe
        url={REVEALER_URL}
        width="100%"
        height="100%"
        className="w-full h-full h-screen"
        allowFullScreen
    />
);
