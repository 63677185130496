import usFlag from '../images/flags/US.png';
import gbFlag from '../images/flags/GB.png';
import deFlag from '../images/flags/DE.png';
import esFlag from '../images/flags/ES.png';
import frFlag from '../images/flags/FR.png';
import itFlag from '../images/flags/IT.png';
import jaFlag from '../images/flags/JP.png';
import nlFlag from '../images/flags/NL.png';
import plFlag from '../images/flags/PL.png';
import ptFlag from '../images/flags/PT.png';
import ruFlag from '../images/flags/RU.png';
import trFlag from '../images/flags/TR.png';
import zhFlag from '../images/flags/CN.png';

export const LOCALES = {
    'en-US': {
        value: 'en-US',
        label: 'English (US)',
        flag: usFlag,
    },
    'en-GB': {
        value: 'en-GB',
        label: 'English (GB)',
        flag: gbFlag,
    },
    'de-DE': {
        value: 'de-DE',
        label: 'Deutsch',
        flag: deFlag,
    },
    'es-ES': {
        value: 'es-ES',
        label: 'Español',
        flag: esFlag,
    },
    'fr-FR': {
        value: 'fr-FR',
        label: 'Français',
        flag: frFlag,
    },
    'it-IT': {
        value: 'it-IT',
        label: 'Italiano',
        flag: itFlag,
    },
    'ja-JP': {
        value: 'ja-JP',
        label: '日本語',
        flag: jaFlag,
    },
    'nl-NL': {
        value: 'nl-NL',
        label: 'Nederlands',
        flag: nlFlag,
    },
    'pl-PL': {
        value: 'pl-PL',
        label: 'Polski',
        flag: plFlag,
    },
    'pt-PT': {
        value: 'pt-PT',
        label: 'Português',
        flag: ptFlag,
    },
    'ru-RU': {
        value: 'ru-RU',
        label: 'Русский',
        flag: ruFlag,
    },
    'tr-TR': {
        value: 'tr-TR',
        label: 'Türkçe',
        flag: trFlag,
    },
    'zh-CN': {
        value: 'zh-CN',
        label: '中文',
        flag: zhFlag,
    },
};
