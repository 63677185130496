import PropTypes from 'prop-types';

const PropTypeObject = PropTypes.object;
const PropTypeArray = PropTypes.array;
const PropTypeString = PropTypes.string;
const PropTypeNumber = PropTypes.number;
const PropTypeBoolean = PropTypes.bool;
const PropTypeFunc = PropTypes.func;
const PropTypeObjectOfObject = PropTypes.objectOf(PropTypes.object);
const PropTypeArrayOfObject = PropTypes.arrayOf(PropTypes.object);
const PropTypeArrayOfString = PropTypes.arrayOf(PropTypes.string);
const PropTypeElement = PropTypes.element;
const PropTypeDate = PropTypes.instanceOf(Date);

export {
    PropTypeObject,
    PropTypeArray,
    PropTypeString,
    PropTypeNumber,
    PropTypeBoolean,
    PropTypeObjectOfObject,
    PropTypeArrayOfObject,
    PropTypeArrayOfString,
    PropTypeFunc,
    PropTypeElement,
    PropTypeDate,
};
