import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
// locales for react-datepicker
import { de, enGB, enUS, es, fr, it, ja, nl, pl, pt, ru, tr, zhCN } from 'date-fns/locale';

// locales for antd components
import enUSAntLocale from 'antd/es/locale/en_US';
import enGBAntLocale from 'antd/es/locale/en_GB';
import deDEAntLocale from 'antd/es/locale/de_DE';
import esESAntLocale from 'antd/es/locale/es_ES';
import frFRAntLocale from 'antd/es/locale/fr_FR';
import itITAntLocale from 'antd/es/locale/it_IT';
import jaJPAntLocale from 'antd/es/locale/ja_JP';
import nlNLAntLocale from 'antd/es/locale/nl_NL';
import plPLAntLocale from 'antd/es/locale/pl_PL';
import ptPTAntLocale from 'antd/es/locale/pt_PT';
import ruRUAntLocale from 'antd/es/locale/ru_RU';
import trTRAntLocale from 'antd/es/locale/tr_TR';
import zhCNAntLocale from 'antd/es/locale/zh_CN';
import { messages } from '../messages';

export const IntlProviderWrapper = ({ children, userData }) => {
    const { locale } = userData;

    /**
     * Makes a locale in ISO639 format (e.g. "en", "de", etc)
     */
    // const getLocaleISO639 = (loc) => loc.slice(0, 2);

    const antLocales = {
        'en-US': enUSAntLocale,
        'en-GB': enGBAntLocale,
        'de-DE': deDEAntLocale,
        'es-ES': esESAntLocale,
        'fr-FR': frFRAntLocale,
        'it-IT': itITAntLocale,
        'ja-JP': jaJPAntLocale,
        'nl-NL': nlNLAntLocale,
        'pl-PL': plPLAntLocale,
        'pt-PT': ptPTAntLocale,
        'ru-RU': ruRUAntLocale,
        'tr-TR': trTRAntLocale,
        'zh-CN': zhCNAntLocale,
    };

    /**  localization for react-datepicker */
    registerLocale('en-US', enUS);
    registerLocale('en-GB', enGB);
    registerLocale('de-DE', de);
    registerLocale('es-ES', es);
    registerLocale('fr-FR', fr);
    registerLocale('it-IT', it);
    registerLocale('ja-JP', ja);
    registerLocale('nl-NL', nl);
    registerLocale('pl-PL', pl);
    registerLocale('pt-PT', pt);
    registerLocale('ru-RU', ru);
    registerLocale('tr-TR', tr);
    registerLocale('zh-CN', zhCN);
    setDefaultLocale(locale);

    /**
     * If the locale doesn't exist, use browsers locale. If browsers locale is en-GB, use en-US
     * @param {string} usersLocale - current selected locale
     * @return {string} A good string
     */

    const detectLocale = () => {
        const browsersLocale = navigator.language || navigator.userLanguage;
        let result;
        switch (browsersLocale.slice(0, 2)) {
        case 'en': result = browsersLocale === 'en-GB' ? 'en-GB' : 'en-US'; break;
        case 'de': result = 'de-DE'; break;
        case 'es': result = 'es-ES'; break;
        case 'fr': result = 'fr-FR'; break;
        case 'it': result = 'it-IT'; break;
        case 'ja': result = 'ja-JP'; break;
        case 'nl': result = 'nl-NL'; break;
        case 'pl': result = 'pl-PL'; break;
        case 'pt': result = 'pt-PT'; break;
        case 'ru': result = 'ru-RU'; break;
        case 'tr': result = 'tr-TR'; break;
        case 'zh': result = 'zh-CN'; break;
        default: result = 'en-US';
        }
        return result;
    };

    const normalizeLocale = (usersLocale) => {
        const availableLocales = Object.keys(antLocales);
        if (!availableLocales.includes(usersLocale)) {
            return detectLocale();
        }
        return usersLocale;
    };

    return (
        <IntlProvider
            locale={normalizeLocale(locale)}
            messages={messages[normalizeLocale(locale)]}
        >
            {/** ConfigProvider connects ant components to translations */}
            <ConfigProvider locale={antLocales[normalizeLocale(locale)]}>
                {children}
            </ConfigProvider>
        </IntlProvider>
    );
};

IntlProviderWrapper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    userData: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
};
export const localeDateFns = (type) => {
    switch (type.slice(0, 2)) {
    case 'en': return enUS;
    case 'de': return de;
    case 'es': return es;
    case 'fr': return fr;
    case 'it': return it;
    case 'ja': return ja;
    case 'nl': return nl;
    case 'pl': return pl;
    case 'pt': return pt;
    case 'ru': return ru;
    case 'tr': return tr;
    case 'zh': return zhCN;
    default: return enUS;
    }
};
