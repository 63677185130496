import React from 'react';
import { Row, Col, PageHeader, Card } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypeArrayOfString } from '../../helpers/propTypes';
import DashboardTable from './DashboardTable';
import { LineChartComponent } from '../LineChartComponent';
import { useGetDashboardCalls } from '../../bus/calls/hooks/useGetDashboardCalls';
import { useDashboard } from '../../bus/dashboard/hooks/useDashboard';
import { SpinnerCentered } from '../../elements/SpinnerCentered';
import { PLANNED_CALL_FILTER, FINISHED_CALL_FILTER } from '../../config/constants';

function Dashboard({ userPermissions = [] }) {
    const intl = useIntl();

    const {
        plannedCalls,
        conductedCalls,
        isFetching,
    } = useGetDashboardCalls();

    const { tableCallsError, tableCallsIsFetching, listParams, tableCalls } = useDashboard();
    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    title={(
                        <span className="text-gray-700 tracking-wide">
                            <FormattedMessage id="app.dashboard" />
                        </span>
                    )}
                />
            </Col>
            <Col span={24}>
                <DashboardTable
                    allCalls={tableCalls}
                    userPermissions={userPermissions}
                    disableCreateCallButton
                    hasError={tableCallsError}
                    plannedCalls={[]}
                    postCallPending={[]}
                    isFetching={tableCallsIsFetching}
                    startedCalls={[]}
                    listParams={listParams}
                />
            </Col>
            <Row style={{
                width: '100%',
                height: 'auto',
                paddingTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'flex-end',
                marginTop: '2rem',
            }}
            >
                <Col
                    xs={24}
                    xl={11}
                    style={{
                        padding: '1rem 0',
                        height: '350px',
                    }}
                >
                    <Card bodyStyle={{ height: '300px' }} style={{ padding: 'none' }}>
                        <div className="relative w-full mb-5" style={{ height: '60%' }}>
                            <div
                                className="absolute text-white px-5 py-2 w-64 rounded-md"
                                style={{ backgroundColor: !isFetching ? '#0f749c' : 'rgba(16, 118, 158, 0.25)', height: '200px', width: '100%', top: '-3.5rem' }}
                            >
                                { isFetching && <SpinnerCentered />}
                                {(conductedCalls && !isFetching) && <LineChartComponent dataName={FINISHED_CALL_FILTER} calls={conductedCalls?.calls} name={intl.formatMessage({ id: 'app.submittedCalls' })} />}
                            </div>
                        </div>
                        <h1 style={{ textDecoration: 'underline' }}>{intl.formatMessage({ id: 'app.submittedCalls' })}</h1>
                        <p className="m-0">{intl.formatMessage({ id: 'app.showingConducted' })}</p>
                    </Card>
                </Col>
                <Col
                    xs={24}
                    xl={11}
                    style={{
                        padding: '1rem 0',
                        height: '350px',
                    }}
                >
                    <Card bodyStyle={{ height: '300px' }}>
                        <div className="relative w-full mb-5" style={{ height: '60%' }}>
                            <div
                                className="absolute text-white px-5 py-2 w-64 rounded-md"
                                style={{ backgroundColor: !isFetching ? '#0f749c' : 'rgba(16, 118, 158, 0.25)', height: '200px', width: '100%', top: '-3.5rem' }}
                            >
                                { isFetching && <SpinnerCentered />}
                                {(plannedCalls && !isFetching) && <LineChartComponent dataName={PLANNED_CALL_FILTER} calls={plannedCalls?.calls} name={intl.formatMessage({ id: 'app.planned' })} />}
                            </div>
                        </div>
                        <h1 style={{ textDecoration: 'underline' }}>{intl.formatMessage({ id: 'app.planned' })}</h1>
                        <p className="m-0">{intl.formatMessage({ id: 'app.showingPlanned' })}</p>
                    </Card>
                </Col>
            </Row>
        </Row>
    );
}

export default Dashboard;

Dashboard.propTypes = {
    userPermissions: PropTypeArrayOfString,

};
