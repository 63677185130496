/* eslint-disable max-len */
import { apply, put, select } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import { dashboardActions } from '../../../dashboard/actions';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import { CALL_STATUS_FILTER, CALLS_PER_PAGE, CLIENT } from '../../../../config/constants';
import { normalizeSortingOrderName, getDashboardTwoWeeksWindowTimeFromNowPayload } from '../../../../helpers';

function* loadCalls() {
    const { sortBy, order } = yield select((state) => state.calls.getIn(['calls', 'listParams']));
    const dashboardThisWeekListParams = yield select((state) => state.dashboard.getIn(['dashboardTableCalls', 'listParams']));
    yield put(callsActions.getCallsAsync({
        pageSize: CALLS_PER_PAGE,
        page: 1,
        callStatus: CALL_STATUS_FILTER,
        sort: sortBy,
        order: normalizeSortingOrderName(order),
    }));

    yield put(dashboardActions.getDashboardTableCallsAsync({ sortBy: dashboardThisWeekListParams.sortBy, orderBy: normalizeSortingOrderName(dashboardThisWeekListParams.orderBy) }));
    yield put(callsActions.getDashboardCallsAsync(getDashboardTwoWeeksWindowTimeFromNowPayload()));
}

function* sendNotifications(data) {
    const invitationData = {
        accountIds: [],
        contactIds: data.contactIds,
        previousModificationStamp: data.modificationStamp,
        client: CLIENT,
    };
    yield apply(api, api.calls.sendInvitation, [data.id, invitationData]);
}

function* handleSuccess(data) {
    yield loadCalls();
    yield sendNotifications(data);
}

export function* deleteCallWorker({ payload }) {
    const options = {
        fetcher: api.calls.deleteCall,
        fetcherParam: payload,
        successAction: callsActions.deleteCallSuccess,
        onSuccess: (data) => handleSuccess(data),
        successMessage: 'The call was successfully deleted',
        startFetching: callsActions.startFetchingDeleteCall,
        stopFetching: callsActions.stopFetchingDeleteCall,
        setErrorAction: callsActions.deleteCallError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
