/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PropTypeFunc } from '../../helpers/propTypes';
import { useMessagingTemplates } from '../../bus/messagingTemplate/hooks/useMessagingTemplates';
import styles from './index.module.less';

export default function MessagingTemplatesStep({ changeMessagingTemplate }) {
    const { templates } = useMessagingTemplates();
    const [selectedItems, setSelectedItems] = useState(templates?.length ? templates[0] : []);

    useEffect(() => {
        if (templates.length === 1) {
            changeMessagingTemplate(templates[0]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templates?.length]);

    return (
        <>
            <div className={styles.listHeader}>
                <FormattedMessage id="app.provision.selectTemplate" />
            </div>

            <Select
                autoFocus
                defaultValue={selectedItems?.id}
                placeholder={
                    <FormattedMessage id="app.provision.selectTemplate" />
                }
                onChange={(templateId) => {
                    changeMessagingTemplate(templates.find((t) => t.id === templateId));
                    setSelectedItems(templates.find((t) => t.id === templateId));
                }}
            >
                {templates.map((selectedTemplate) => (
                    <Select.Option key={selectedTemplate.id} value={selectedTemplate.id}>
                        {selectedTemplate.name}
                    </Select.Option>
                ))}
            </Select>

        </>
    );
}
MessagingTemplatesStep.propTypes = {
    changeMessagingTemplate: PropTypeFunc,
};
