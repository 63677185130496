import * as types from './types';

const initialState = {
    data: {
        contacts: [],
        customContacts: [],
        content: {},
        transport: 'email',
        template: null,
        substitutionData: {},
        shareables: [],
        provisionId: '',
    },
    isFetching: false,
    isFetched: false,
    error: null,
};

export default function provisionReducer(state = initialState, action) {
    switch (action.type) {
    case types.PROVISION_ADD_CUSTOM_CONTACTS:
        return {
            ...state,
            data: {
                ...state.data,
                customContacts: [
                    ...state.data.customContacts,
                    action.payload,
                ],
            },
        };
    case types.PROVISION_REMOVE_CUSTOM_CONTACTS:
        return {
            ...state,
            data: {
                ...state.data,
                customContacts: state.data.customContacts.filter(
                    (contact) => contact.value !== action.payload,
                ),
            },
        };
    case types.PROVISION_SET_CONTACTS:
        return {
            ...state,
            data: {
                ...state.data,
                contacts: action.payload,
            },
        };
    case types.PROVISION_CLEAR:
        return initialState;
    case types.PROVISION_SET_CONTENT:
        return {
            ...state,
            data: {
                ...state.data,
                content: action.payload,
            },
        };
    case types.PROVISION_ADD_SHAREABLE:
        return {
            ...state,
            data: {
                ...state.data,
                shareables: [...state.data.shareables, action.payload],
            },
        };
    case types.PROVISION_REMOVE_SHAREABLE:
        return {
            ...state,
            data: {
                ...state.data,
                shareables: state.data.shareables.filter(
                    (s) => s.id !== action.payload,
                ),
            },
        };
    case types.PROVISION_SET_TRANSPORT:
        return {
            ...state,
            data: {
                ...state.data,
                transport: action.payload,
            },
        };
    case types.PROVISION_SET_MESSAGING_TEMPLATE: {
        return {
            ...state,
            data: {
                ...state.data,
                template: action.payload,
            },
        };
    }
    case types.PROVISION_SEND_ASYNC_START:
        return {
            ...state,
            isFetching: true,
            error: null,
            isFetched: false,
        };
    case types.PROVISION_SEND_ASYNC_SUCCESSFUL:
        return {
            ...state,
            isFetching: false,
            isFetched: true,
            error: null,
        };
    case types.PROVISION_SEND_ASYNC_ERROR:
        return {
            ...state,
            isFetched: true,
            isFetching: false,
            error: action.payload,
        };
    default:
        return state;
    }
}
