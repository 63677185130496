import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, message } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { useProvision } from '../../bus/provision/hooks/useProvision';
import { ProvisionModalFooter, ProvisionSummaryStep, ProvisionStateError, ProvisionStepsBar } from './index';
import styles from './index.module.less';
import { CONTENT_TYPES, ERROR_400 } from '../../config/constants';
import { PropTypeBoolean, PropTypeFunc } from '../../helpers/propTypes';
import { ProvisionContactsStep } from './ProvisionContactsStep';
import { ProvisionContentShareDetailStep } from './ProvisionContentShareDetailStep';
import ProvisionTypeStep from './ProvisionTypeStep';
import { warningModal } from './WarningModal';

export function ProvisionModal({ visible = false, onModalClose }) {
    const intl = useIntl();
    const [currentStep, setCurrentStep] = useState(0);
    const [nextStepAvailable, setNextStepAvailable] = useState(false);
    const [nextStepWarningMessage, setNextStepWarningMessage] = useState('');
    const contents = useSelector((state) => state.calls.getIn(['content', 'entities', 'data'])) || [];
    const provision = useSelector((state) => state.provision);
    const [currentContent, setCurrentContent] = useState(provision.data.content || null);
    const [type, setType] = useState('');

    useEffect(() => {
        if (visible === true && provision.data.content) {
            setCurrentStep(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const isSharableSelected = (provisionObj) => {
        if (
            provisionObj.data.content.type === CONTENT_TYPES.PRESENTATION
            && provisionObj.data.shareables?.length
        ) { return true; }

        return false;
    };

    const isContactSelected = (provisionObj) => provisionObj.data.contacts.length
        || provisionObj.data.customContacts.length;

    const isMessagingTemplateSelected = (provisionObj) => !isEmpty(provisionObj.data.template)
        && isContactSelected(provisionObj)
        && isSharableSelected(provisionObj);

    const {
        clearProvision,
        addOrRemoveProvisionItem,
        addOrRemoveDefinedProvisionContacts,
        changeMessagingTemplate,
        provisionContent,
        addCustomContact,
        removeCustomContact,
    } = useProvision(type);

    useEffect(() => {
        const onStep = (available, id) => {
            setNextStepAvailable(available);
            if (id) {
                setNextStepWarningMessage(intl.formatMessage({ id }));
            } else {
                setNextStepWarningMessage('');
            }
        };
        switch (currentStep) {
        case 0:
            if (type) {
                onStep(true, '');
            } else {
                onStep(false, 'error.provision.selectTransportType');
            }
            break;
        case 1:
            if (isContactSelected(provision)) {
                onStep(true, '');
            } else {
                onStep(false, 'error.provision.contactIsRequired');
            }
            break;
        case 2:
            if (isSharableSelected(provision)) {
                onStep(true, '');
            } else {
                onStep(false, 'error.provision.sharableIsRequired');
            }
            break;
        case 3:
            if (isMessagingTemplateSelected(provision)) {
                onStep(true, '');
            } else {
                onStep(false, 'error.provision.messagingTemplateIsRequired');
            }
            break;
        default:
            onStep(false, '');
            break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provision, currentStep, type]);

    const onModalCancel = () => {
        clearProvision();
        setCurrentContent(null);
        if (onModalClose) {
            onModalClose();
        }
    };

    useEffect(() => {
        if (provision.data?.content) {
            setCurrentContent(
                contents.find((c) => c.id === provision.data.content.id),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provision.data.content]);

    useEffect(() => {
        const { error } = provision;
        const has400Error = error?.statusCode === ERROR_400;
        if (provision.isFetched) {
            if (has400Error) {
                warningModal(error.message, intl);
                onModalCancel();
            } else if (!error) {
                message.success(intl.formatMessage({ id: 'notification.successfulProvision' }));
                onModalCancel();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provision.isFetched]);

    return (
        <Modal
            title={(
                <div className="flex justify-center">
                    <FormattedMessage id="app.shareContent" />
                </div>
            )}
            visible={visible}
            centered
            bodyStyle={{ height: window.innerHeight * 0.82, overflowY: 'auto', padding: '1rem' }}
            width="100%"
            maskClosable={false}
            onCancel={onModalCancel}
            className={styles.antModal}
            footer={(
                <ProvisionModalFooter
                    provisionStep={currentStep}
                    onCancel={onModalCancel}
                    onNext={() => setCurrentStep((val) => val + 1)}
                    onPrevious={() => setCurrentStep((val) => (val !== 0 ? val - 1 : 0))}
                    isNextStepAvailable={nextStepAvailable}
                    nextStepWarningMessage={nextStepWarningMessage}
                    isPreviousStepAvailable={currentStep !== 0}
                    onProvision={provisionContent}
                    isFetchingProvision={provision.isFetching}
                />
            )}
        >
            <>
                <Row>
                    <ProvisionStepsBar
                        currentStep={currentStep}
                        onChange={(e) => setCurrentStep(e)}
                        isContentSectionsDisable={!isContactSelected(provision)}
                        isSummaryDisable={!isSharableSelected(provision)}
                        type={type}
                    />
                </Row>
                <Row className="pt-5" style={{ height: '95%' }}>
                    <Col span={24}>
                        {currentStep === 0 ? (
                            <ProvisionTypeStep {...{ type, setType }} />
                        ) : null}
                        {currentStep === 1 ? (
                            <ProvisionContactsStep
                                addCustomContact={addCustomContact}
                                removeCustomContact={removeCustomContact}
                                onDefinedContactsChanged={
                                    addOrRemoveDefinedProvisionContacts
                                }
                                preSelectedContacts={provision.data.contacts}
                                customContacts={provision.data.customContacts}
                                type={type}
                            />
                        ) : null}
                        {currentStep === 2 ? (
                            <ProvisionContentShareDetailStep
                                content={currentContent}
                                selectedShareablesId={provision.data.shareables.map(
                                    (s) => s.id,
                                )}
                                addOrRemoveSharableItem={
                                    addOrRemoveProvisionItem
                                }
                            />
                        ) : null}
                        {currentStep === 3 ? (
                            <ProvisionSummaryStep
                                key="summary"
                                provision={provision.data}
                                changeMessagingTemplate={changeMessagingTemplate}
                                currentContentId={currentContent?.id}
                                type={type}
                            />
                        ) : null}
                    </Col>
                </Row>
            </>
            {provision.isFetched && provision.error && provision.error?.statusCode !== ERROR_400 ? (
                <ProvisionStateError />
            ) : null}
        </Modal>
    );
}

ProvisionModal.propTypes = {
    visible: PropTypeBoolean,
    onModalClose: PropTypeFunc,
};
