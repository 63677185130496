import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.less';
import { AvatarDropdown } from '../AvatarDropdown';
import { SelectLang } from '../../components/SelectLang';
import { Greeting } from '../Greeting';

export const HeaderRightContent = ({ userData }) => (
    <div className={styles.right}>
        <span className={styles.greeting}>
            <Greeting userData={userData} />
        </span>
        <SelectLang />
        <AvatarDropdown userData={userData} />
    </div>
);

HeaderRightContent.propTypes = {
    userData: PropTypes.shape({
        username: PropTypes.string,
    }).isRequired,
};
