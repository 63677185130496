/* eslint-disable max-len */
import { apply, put, select } from 'redux-saga/effects';
import { xor } from 'lodash';
import { callsActions } from '../../actions';
import { dashboardActions } from '../../../dashboard/actions';
import { api } from '../../../../config/api';
import { normalizeSortingOrderName, getDashboardTwoWeeksWindowTimeFromNowPayload } from '../../../../helpers';
import { CALL_STATUS_FILTER, CALLS_PER_PAGE, CLIENT } from '../../../../config/constants';
import history from '../../../../routing/history';
import { book } from '../../../../routing/book';
import { staffActions } from '../../../staff/actions';

function* sendInvitation(data, previousCall, params) {
    const isTimeOrDurationChanged = (previousCall.duration !== params.duration)
        || (previousCall.timestamp !== params.startUtcTimestamp);
    const isTimezoneChanged = !(previousCall.timezone === params.timezoneIanaName);
    let contactIdsToNotify = [];

    const previousCallContactIds = previousCall.contacts.map((contact) => contact.id);
    if (isTimeOrDurationChanged || isTimezoneChanged) { /* send to everybody */
        contactIdsToNotify = [...data.contactIds];
    } else {
        contactIdsToNotify = xor(previousCallContactIds, params.contactIds);
    }

    const invitationData = {
        accountIds: [],
        contactIds: contactIdsToNotify,
        previousModificationStamp: data.modificationStamp,
        client: CLIENT,
    };
    yield apply(api, api.calls.sendInvitation, [data.id, invitationData]);
}

function* getCalls() {
    const { page, sortBy, order } = yield select((state) => state.calls.getIn(['calls', 'listParams']));
    const dashboardThisWeekListParams = yield select((state) => state.dashboard.getIn(['dashboardTableCalls', 'listParams']));

    yield put(callsActions.getCallsAsync({
        pageSize: CALLS_PER_PAGE,
        page,
        callStatus: CALL_STATUS_FILTER,
        sort: sortBy,
        order: normalizeSortingOrderName(order),
    }));

    yield put(dashboardActions.getDashboardTableCallsAsync({ sortBy: dashboardThisWeekListParams.sortBy, orderBy: normalizeSortingOrderName(dashboardThisWeekListParams.orderBy) }));
    callsActions.getDashboardCallsAsync(getDashboardTwoWeeksWindowTimeFromNowPayload());
}

export function* editCallWorker({ payload: { callId, previousCall, params, isNotifyAttendees } }) {
    try {
        yield put(callsActions.startFetchingEditOrCreate());
        const { data } = yield apply(api, api.calls.editCall, [callId, params]);

        yield getCalls();

        yield put(callsActions.editCallSuccess());
        if (isNotifyAttendees) {
            yield sendInvitation(data, previousCall, params);
        }
        history.push(book.calls);
    } catch (error) {
        if (error.response) {
            console.error('error.response', error);
            if (error.response.status === 401) { yield put(staffActions.setSessionExpired()); }
            yield put(callsActions.editOrCreateCallError({
                message: error.response.data && error.response.data.message,
                statusCode: error.response.status,
            }));
        } else if (error.request) {
            console.error('--> error request:', error.request);
        } else {
            console.error('--> error other:', error.message);
        }
    } finally {
        yield put(callsActions.stopFetchingEditOrCreate());
    }
}
