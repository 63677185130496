/* eslint-disable camelcase */
/* eslint-disable max-len */
import { Map } from 'immutable';
import { types } from './types';
import { POST_CALL_PENDING_CALL_STATUS_FILTER, PLANNED_CALL_STATUS_FILTER } from '../../config/constants';

const initialState = Map({
    dashboardTableCalls: {
        all: [],
        planned: [],
        postCallPending: [],
        started: [],
        isFetching: false,
        error: null,
        listParams: {
            sortBy: 'timestamp',
            orderBy: 'descend',
        },
    },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    function transformCallsData(payload) {
        const transformedData = {
            data: [],
            meta: { ...payload.meta },
        };

        payload.data.forEach((call) => {
            const { _id, contacts, contents, modifiedAt, ...restCall } = call;

            const updatedContacts = contacts.map(({
                first_name,
                last_name,
                id,
                ...rest
            }) => ({
                firstName: first_name,
                lastName: last_name,
                id: id.toString(),
                ...rest,
            }));

            const updatedContent = contents.map(({
                id,
                title,
                ...rest
            }) => ({
                id: id.toString(),
                name: title,
                ...rest,
            }));

            const newCall = {
                id: _id,
                contents: updatedContent,
                contacts: updatedContacts,
                modificationStamp: `time_${modifiedAt}`,
                ...restCall,
            };

            transformedData.data.push(newCall);
        });

        return transformedData;
    }
    switch (action.type) {
    case types.DASHBOARD_TABLE_CALLS: {
        const transfrormedData = transformCallsData(action.payload);
        return state.setIn(['dashboardTableCalls', 'all'], transfrormedData.data)
            .setIn(['dashboardTableCalls', 'planned'], transfrormedData.data.filter((call) => call.status === PLANNED_CALL_STATUS_FILTER))
            .setIn(['dashboardTableCalls', 'postCallPending'], transfrormedData.data.filter((call) => call.status === POST_CALL_PENDING_CALL_STATUS_FILTER))
            .setIn(['dashboardTableCalls', 'isFetching'], false)
            .setIn(['dashboardTableCalls', 'error'], null);
    }
    case types.DASHBOARD_TABLE_CALLS_ERROR:
        return state.setIn(['dashboardTableCalls', 'error'], action.payload).setIn(['dashboardTableCalls', 'isFetching'], false);
    case types.DASHBOARD_TABLE_CALLS_START_FETCHING:
        return state.setIn(['dashboardTableCalls', 'isFetching'], true).setIn(['dashboardTableCalls', 'error'], null);
    case types.DASHBOARD_SET_TABLE_CALLS_LIST_PARAMS:
        return state.setIn(['dashboardTableCalls', 'listParams'], action.payload);
    default:
        return state;
    }
};
