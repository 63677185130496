/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import { callsActions } from '../actions';

export const useEndCall = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const isFetchingEndCall = useSelector((state) => state.calls.get('isFetchingEndCall'));
    const errorEndCall = useSelector((state) => state.calls.get('errorEndCall'));

    const endCall = useCallback(({ call }) => {
        if (call.status === 'started') {
            dispatch(callsActions.endCallAsync({
                callId: call.id,
                status: call.status,
                successMessage: intl.formatMessage({ id: 'notification.endCallSuccess' }),
            }));
        } else {
            message.error(intl.formatMessage({ id: 'notification.endCallCouldNotDelete' }));
        }
    }, [dispatch]);

    return {
        endCall,
        isFetchingEndCall,
        errorEndCall,
    };
};
