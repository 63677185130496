/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import noImage from '../../images/no-image.jpg';
import styles from './index.module.less';

const { Text } = Typography;

export const SelectedItem = (props) => {
    const { value, label, thumbnail, onCloseTag, truncateLabel, icon: Icon, iconClassNames, showCloseButton } = props;

    const maxCharacters = 14;
    const labelNormalized = truncateLabel === true && label.length > maxCharacters ? `${label.substring(0, maxCharacters)}...` : label;

    const borderRadius = '4px';

    return (
        <div className={styles.selectedTag}>
            {thumbnail !== undefined && (
                <div style={{
                    float: 'left',
                    height: '28px',
                    width: '28px',
                    backgroundImage: `url(${thumbnail || noImage})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: `${borderRadius} 0 0 ${borderRadius}`,
                }}
                />
            )}
            {(Icon && !thumbnail) ? (
                <div style={{
                    width: '28px',
                    height: '28px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                    <Icon className={iconClassNames} />
                </div>
            ) : null}
            <div className={styles.content}>
                <Tooltip title={label}>
                    <Text className={styles.value}>{labelNormalized}</Text>
                </Tooltip>
                {showCloseButton && onCloseTag && (
                    <CloseOutlined
                        className={styles.closeTagIcon}
                        onClick={() => {
                            onCloseTag(value);
                        }}
                    />
                )}
            </div>
        </div>
    );
};

SelectedItem.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    onCloseTag: PropTypes.func,
    truncateLabel: PropTypes.bool,
    icon: PropTypes.any,
    iconClassNames: PropTypes.string,
    showCloseButton: PropTypes.bool,
};
