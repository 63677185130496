import { takeEvery, all, call } from 'redux-saga/effects';
import { types } from '../types';
import { getCallsWorker } from './workers/getCallsWorker';
import { getContactsWorker } from './workers/getContactsWorker';
import { getContentWorker } from './workers/getContentWorker';
import { getFiltersContactsWorker } from './workers/getFiltersContactsWorker';
import { getFiltersContentWorker } from './workers/getFiltersContentWorker';
import { editCallWorker } from './workers/editCallWorker';
import { createCallWorker } from './workers/createCallWorker';
import { deleteCallWorker } from './workers/deleteCallWorker';
import { getCallFieldsWorker } from './workers/getCallFieldsWorker';
import { submitCallFieldsWorker } from './workers/submitCallFieldsWorker';
import { getInvitationEmailWorker } from './workers/getInvitationEmailWorker';
import { endCallWorker } from './workers/endCallWorker';
import { getDashboardCallsWorker } from './workers/getDashboardCallsWorker';
import { startedCallWorker } from './workers/startedCallWorker';
import { getContentPreviewUrlWorker } from './workers/getContentPreviewUrlWorker';

function* getCallsWatcher() {
    yield takeEvery(types.CALLS_GET_CALLS_ASYNC, getCallsWorker);
}
function* getDashboardCallsWatcher() {
    yield takeEvery(types.CALLS_GET_DASHBOARD_CALLS_ASYNC, getDashboardCallsWorker);
}
function* getContactsWatcher() {
    yield takeEvery(types.CALLS_GET_CONTACTS_ASYNC, getContactsWorker);
}
function* getContentWatcher() {
    yield takeEvery(types.CALLS_GET_CONTENT_ASYNC, getContentWorker);
}
function* getFiltersContactsWatcher() {
    yield takeEvery(types.CALLS_GET_FILTERS_CONTACTS_ASYNC, getFiltersContactsWorker);
}
function* getFiltersContentWatcher() {
    yield takeEvery(types.CALLS_GET_FILTERS_CONTENT_ASYNC, getFiltersContentWorker);
}
function* getContentPreviewUrlWatcher() {
    yield takeEvery(types.CALLS_GET_CONTENT_PREVIEW, getContentPreviewUrlWorker);
}
function* editCallWatcher() {
    yield takeEvery(types.CALLS_EDIT_CALL_ASYNC, editCallWorker);
}
function* createCallWatcher() {
    yield takeEvery(types.CALLS_CREATE_CALL_ASYNC, createCallWorker);
}
function* deleteCallWatcher() {
    yield takeEvery(types.CALLS_DELETE_CALL_ASYNC, deleteCallWorker);
}
function* endCallWatcher() {
    yield takeEvery(types.CALLS_END_CALL_ASYNC, endCallWorker);
}
function* getCallFieldsWatcher() {
    yield takeEvery(types.CALLS_GET_CALL_FIELDS_ASYNC, getCallFieldsWorker);
}
function* submitCallFieldsWatcher() {
    yield takeEvery(types.CALLS_SUBMIT_FIELDS_ASYNC, submitCallFieldsWorker);
}
function* getInvitationEmailWatcher() {
    yield takeEvery(types.CALLS_GET_INVITATION_EMAIL_ASYNC, getInvitationEmailWorker);
}
function* getStartedCallWatcher() {
    yield takeEvery(types.CALLS_GET_STARTED_CALLS_ASYNC, startedCallWorker);
}

export function* callsWatchers() {
    yield all(
        [
            call(getCallsWatcher),
            call(getDashboardCallsWatcher),
            call(getContactsWatcher),
            call(getContentWatcher),
            call(getContentPreviewUrlWatcher),
            call(getFiltersContactsWatcher),
            call(getFiltersContentWatcher),
            call(editCallWatcher),
            call(createCallWatcher),
            call(deleteCallWatcher),
            call(endCallWatcher),
            call(getCallFieldsWatcher),
            call(submitCallFieldsWatcher),
            call(getInvitationEmailWatcher),
            call(getStartedCallWatcher),
        ],
    );
}
