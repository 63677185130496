import { GlobalOutlined } from '@ant-design/icons';
import { Spin, Menu } from 'antd';
import React from 'react';
import classNames from 'classnames';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.module.less';
import { LOCALES } from '../../i18n/constants';
import { useStaff } from '../../bus/staff/hooks/useStaff';

export const SelectLang = () => {
    const { changeLocale, userData, isFetchingSetLocale } = useStaff();
    const { locale } = userData;

    const handleLocaleChange = ({ key }) => changeLocale(key);

    const langMenu = (
        <Menu
            className={styles.menu}
            selectedKeys={[locale]}
            onClick={handleLocaleChange}
        >
            {Object.keys(LOCALES).map((loc) => (
                <Menu.Item key={loc}>
                    <span role="img" aria-label={LOCALES[loc].label}>
                        <img
                            className="inline-block w-4"
                            src={LOCALES[loc].flag}
                            alt={LOCALES[loc].value}
                        />
                    </span>{' '}
                    {LOCALES[loc].label}
                </Menu.Item>
            ))}
        </Menu>
    );

    if (isFetchingSetLocale) {
        return <Spin className={styles.languageSpinner} size="small" />;
    }

    return (
        <HeaderDropdown overlay={langMenu} placement="bottomRight">
            <span className={classNames(styles.dropDown)}>
                <GlobalOutlined />
            </span>
        </HeaderDropdown>
    );
};
