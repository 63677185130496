/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useStartedCalls } from '../../bus/calls/hooks/useStartedCalls';
import { useEndCall } from '../../bus/calls/hooks/useEndCall';

export const StartedCall = () => {
    const [modal, contextHolder] = Modal.useModal();
    const { startedCalls } = useStartedCalls();
    const { endCall, isFetchingEndCall } = useEndCall();

    useEffect(() => {
        if (startedCalls.length > 0 && !isFetchingEndCall) {
            const lastCall = [...startedCalls].pop();
            const modalInstance = modal.confirm({
                title: <FormattedMessage id="app.callInProgressTitle" />,
                content: <FormattedMessage id="app.callInProgressContent" />,
                okText: <FormattedMessage id="app.callInProgressJoin" />,
                cancelText: <FormattedMessage id="app.callInProgressFinish" />,
                cancelButtonProps: {
                    danger: true,
                },
                confirmLoading: true,
                onOk: () => {
                    modalInstance.update({
                        okButtonProps: { loading: true },
                    });
                    window.location.href = lastCall.presentersUrl;
                },
                onCancel: () => {
                    const { call } = lastCall;
                    endCall({ call });
                },
            }, []);
        }
    }, [startedCalls?.length]);
    return <>{contextHolder}</>;
};
