import { api } from '../../../../config/api';
import { dashboardActions } from '../../actions';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* getDashboardThisWeekCallsWorker({ payload }) {
    const options = {
        fetcher: api.calls.dashboardCalls.getDashboardTableCalls,
        fetcherParam: payload,
        successAction: dashboardActions.fillDashboardTableCalls,
        startFetching: dashboardActions.getDashboardTableCallsStartFetching,
        setErrorAction: dashboardActions.getDashboardTableCallsError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
