/* eslint-disable max-len */
import { put, select } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import { dashboardActions } from '../../../dashboard/actions';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import { CALL_STATUS_FILTER, CALLS_PER_PAGE, STARTED_CALL_STATUS_FILTER } from '../../../../config/constants';
import { normalizeSortingOrderName } from '../../../../helpers';

export function* loadCalls(payload) {
    const { sortBy, order } = yield select((state) => state.calls.getIn(['calls', 'listParams']));
    const dashboardThisWeekListParams = yield select((state) => state.dashboard.getIn(['dashboardTableCalls', 'listParams']));
    if (payload.status === STARTED_CALL_STATUS_FILTER) {
        yield put(callsActions.clearStartedCall(payload.callId));
    }
    yield put(callsActions.getCallsAsync({
        pageSize: CALLS_PER_PAGE,
        page: 1,
        callStatus: CALL_STATUS_FILTER,
        sort: sortBy,
        order: normalizeSortingOrderName(order),
    }));
    yield put(dashboardActions.getDashboardTableCallsAsync({ sortBy: dashboardThisWeekListParams.sortBy, orderBy: normalizeSortingOrderName(dashboardThisWeekListParams.orderBy) }));
}

function* handleSuccess(payload) {
    yield loadCalls(payload);
}

export function* endCallWorker({ payload }) {
    const options = {
        fetcher: api.calls.endCall,
        fetcherParam: { callId: payload.callId },
        successAction: callsActions.endCallSuccess,
        onSuccess: () => handleSuccess(payload),
        successMessage: payload.successMessage,
        startFetching: callsActions.startFetchingEndCall,
        stopFetching: callsActions.stopFetchingEndCall,
        setErrorAction: callsActions.endCallError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
