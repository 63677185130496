/* eslint-disable max-len */
import { sleep } from '../../../helpers/handlers/index';

export const disabledElements = [
    'viewControlsButton',
    'toolsButton',
    'searchButton',
    'selectToolButton',
    'selectTool',
    'pageNavOverlay',
    'leftPanelTabs',
    'menuButton',
    'dataElement',
    'toolbarGroup-Edit',
    'documentControl',
    'thumbnailControl',
    'leftPanelResizeBar',
    'thumbnailsSizeSlider',
    'pageNavigation',

];

export const handleInstance = async (instance) => {
    const { Core, Feature, setLayoutMode, disableAnnotations, disableDownload, disablePrint, disableTextSelection, disableTools } = instance;
    setLayoutMode('Single');
    disableAnnotations();
    disableDownload();
    disablePrint();
    disableTextSelection();
    disableTools();
    await sleep(200);
    instance.disableFeatures([Feature.PageNavigation]);
    const iframeDoc = instance.iframeWindow.document;
    const dataElementHeader = iframeDoc.querySelector('[data-element="header"]');
    const zoomOverlayButton = iframeDoc.querySelector('[data-element="zoomOverlayButton"]');

    dataElementHeader.style.backgroundColor = '#fff';
    zoomOverlayButton.firstChild.firstChild.style.order = 3;
    zoomOverlayButton.firstChild.style.backgroundColor = '#fff';
    instance.setHeaderItems((header) => {
        let items = header.getItems();
        items = [items[0], items[1], items[5], items[3]];
        header.update(items);
    });
    const { documentViewer } = Core;

    documentViewer.setWatermark({
        // Draw diagonal watermark in middle of the document
        diagonal: {
            fontSize: 70, // or even smaller size
            fontFamily: 'sans-serif',
            color: '#000000',
            opacity: 40, // from 0 to 100
            text: 'Preview Mode',
        },
    });
};
