/* eslint-disable react/prop-types */
import React from 'react';
import { Badge, Button, Dropdown, Popconfirm, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { USER_PERMISSIONS } from '../../config/constants';
import { ReactComponent as LaunchIcon } from '../../ag-icons/Rainmaker_soft_icon_06_Launch.svg';
import { ReactComponent as EditIcon } from '../../ag-icons/Rainmaker_soft_icon_07_Edit.svg';
import { ReactComponent as PostCallIcon } from '../../ag-icons/Rainmaker_soft_icon_43_PostCall.svg';
import { ReactComponent as DeleteIcon } from '../../ag-icons/Rainmaker_soft_icon_09_Delete.svg';
import ActionMenu from './ActionMenu';

import styles from './index.module.less';

const ActionColumn = ({ text, record, userPermissions, handleDelete }) => {
    const { call, presentersUrl, meetNowLink } = record;
    const intl = useIntl();
    const actionIconsClassNames = 'w-7 h-7 fill-current';
    const actionButtonProps = { type: 'text', className: 'flex justify-center items-center' };

    return (
        <div className="flex justify-end">
            {!(call.status === 'post-call-pending')
                && userPermissions?.includes(USER_PERMISSIONS.CONDUCT_CALLS) && (
                <Popconfirm
                    placement="topLeft"
                    icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                    title={(
                        <FormattedMessage
                            id={
                                call.status === 'started'
                                    ? 'message.continueCall'
                                    : 'message.startCall'
                            }
                        />
                    )}
                    onConfirm={() => {
                        window.location.href = presentersUrl;
                    }}
                    okText={(
                        <FormattedMessage
                            id={
                                call.status === 'started'
                                    ? 'app.continue'
                                    : 'app.start'
                            }
                        />
                    )}
                    cancelText="Cancel"
                >
                    <Button
                        {...actionButtonProps}
                        icon={(
                            <div>
                                <LaunchIcon
                                    className={`${actionIconsClassNames} text-primary`}
                                />
                                {call.status === 'started' && (
                                    <Tooltip
                                        className="m-1"
                                        title={intl.formatMessage({
                                            id: 'app.callInProgress',
                                        })}
                                    >
                                        <Badge
                                            status="processing"
                                            className={`${styles.inProgress} text-cool-gray-500`}
                                        />
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    />
                </Popconfirm>
            )}

            {!(
                call.status === 'post-call-pending' || call.status === 'started'
            )
                && userPermissions?.includes(USER_PERMISSIONS.PLAN_CALLS) && (
                <NavLink to={`/calls/${call.id}`}>
                    <Button
                        {...actionButtonProps}
                        icon={(
                            <EditIcon
                                className={`${actionIconsClassNames} text-gray-600`}
                            />
                        )}
                    />
                </NavLink>
            )}

            {!(
                call.status === 'post-call-pending' || call.status === 'started'
            ) && (
                <Popconfirm
                    placement="topLeft"
                    title={<FormattedMessage id="message.deleteCall" />}
                    onConfirm={() => handleDelete(call)}
                    okText="Yes"
                    cancelText="Cancel"
                >
                    <Button
                        {...actionButtonProps}
                        icon={(
                            <DeleteIcon
                                className={`${actionIconsClassNames} text-red-400`}
                            />
                        )}
                    />
                </Popconfirm>
            )}

            {call.status === 'post-call-pending' && (
                <NavLink to={`/calls/${call.id}/call-fields`}>
                    <Button
                        type="text"
                        className="mt-4 mr-8 flex justify-center items-center"
                        icon={
                            <PostCallIcon className={actionIconsClassNames} />
                        }
                    />
                </NavLink>
            )}

            {call.status !== 'post-call-pending' && (
                <Dropdown.Button
                    overlay={<ActionMenu {...{ meetNowLink, intl }} />}
                    buttonsRender={([, rightButton]) => [
                        null,
                        React.cloneElement(rightButton, {
                            ...actionButtonProps,
                            type: 'text',
                        }),
                    ]}
                />
            )}
        </div>
    );
};

export default ActionColumn;
