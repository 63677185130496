import React from 'react';
import { Empty, Typography, Space, Row, Col, Button, Card, message, Divider } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactComponent as UserIcon } from '../../../ag-icons/Rainmaker_soft_icon_37_User.svg';
import styles from '../index.module.less';
import { ContentItem } from '../../ContentItem';
import { DURATION_TIME_FORMAT } from '../../../config/constants';
import { ReactComponent as RemindMinuteIcon } from '../../../ag-icons/Rainmaker_soft_icon_30_RemindMinute.svg';

const { Text } = Typography;

export const CallDetails = ({ call }) => {
    const intl = useIntl();
    return (
        <Row gutter={16}>
            <Col xs={24} md={5} lg={6}>
                <Card className="min-h-full shadow" bordered={false}>
                    <h2 className="mb-2 text-s text-link text-semibold">
                        <FormattedMessage id="app.call" />
                    </h2>
                    <Divider orientation="left" plain>
                        <FormattedMessage id="app.duration" />
                    </Divider>
                    <div className="flex items-center whitespace-no-wrap">
                        <RemindMinuteIcon className="mr-2 w-8 h-8 fill-current text-cool-gray-500 " />
                        <Text strong>
                            {moment.utc(moment.duration(call.duration, 'seconds').asMilliseconds()).format(DURATION_TIME_FORMAT)}
                        </Text>
                    </div>
                </Card>
            </Col>
            <Col xs={24} md={10} lg={9}>
                <Card className="min-h-full shadow" bordered={false}>
                    <h2 className="mb-2 text-s text-link text-semibold">
                        <FormattedMessage id="app.participants" /> ({call.contacts.length})
                    </h2>
                    {!call.contacts.length
                        ? (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={intl.formatMessage({ id: 'app.noParticipants' })}
                            />
                        )
                        : (
                            <table>
                                <tbody>
                                    {call.contacts.map((contact) => (
                                        <tr key={contact.id} className="my-1 ml-px text-gray-600 text-s">
                                            <td className="py-2 pr-5 w-56 whitespace-no-wrap truncate">
                                                <UserIcon
                                                    className="w-5 h-5 mx-1 float-left mr-2 fill-current text-disabled"
                                                />
                                                {`${contact.firstName} ${contact.lastName}`}
                                            </td>
                                            {
                                                contact.invitationURL && (
                                                    <td className="whitespace-no-wrap overflow-x-hidden">
                                                        <CopyToClipboard
                                                            text={contact.invitationURL}
                                                            onCopy={() => message.success(intl.formatMessage({ id: 'app.copied' }), 2)}
                                                            className="cursor-pointer"
                                                        >
                                                            <div className={styles.meetNowLink}>
                                                                <Button
                                                                    size="small"
                                                                    type="text"
                                                                    icon={<CopyOutlined />}
                                                                />
                                                                <span>{contact.invitationURL}</span>
                                                            </div>
                                                        </CopyToClipboard>
                                                    </td>
                                                )
                                            }

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                </Card>
            </Col>
            <Col xs={24} md={9} lg={9}>
                <Card className="min-h-full shadow" bordered={false}>
                    <h2 className="mb-2 text-s text-link text-semibold">
                        <FormattedMessage id="app.content" /> ({call.contents.length})
                    </h2>

                    {!call.contents.length
                        ? (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={intl.formatMessage({ id: 'app.noContent' })}
                            />
                        )
                        : (
                            <Space direction="vertical" className="my-2">
                                {call.contents.map((content) => (
                                    <ContentItem
                                        key={content.id}
                                        title={content.name}
                                        thumbnailUrl={(content || {}).thumbnailUrl}
                                        description={content.description || null}
                                        version={content.version_number}
                                    />
                                ))}
                            </Space>
                        )}
                </Card>
            </Col>
        </Row>
    );
};

CallDetails.propTypes = {
    call: PropTypes.shape({
        contacts: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        })).isRequired,
        contents: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            thumbnailUrl: PropTypes.string,
        })).isRequired,
        duration: PropTypes.number.isRequired,
    }).isRequired,
};
