import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { callsActions } from '../actions';

export const useEditOrCreateCall = (callId, currentCall, isNewCall) => {
    const dispatch = useDispatch();
    const isFetchingEditOrCreate = useSelector((state) => state.calls.get('isFetchingEditOrCreate'));

    const currentTime = moment();
    const remainder = 30 - (currentTime.minute() % 30);
    const roundedTime = moment(currentTime).add(remainder, 'minutes');

    const [startTime, setStartTime] = useState(roundedTime.unix());
    const [duration, setDuration] = useState(1800);

    useEffect(() => {
        if (!isNewCall && currentCall) {
            setStartTime(currentCall.timestamp);
            setDuration(currentCall.duration);
        }
    }, [currentCall, callId, isNewCall]);

    const submitEdit = useCallback((params, previousCall, isNotifyAttendees) => {
        dispatch(callsActions.editCallAsync({ callId, params, previousCall, isNotifyAttendees }));
    }, [dispatch, callId]);

    const submitNew = useCallback((params) => {
        dispatch(callsActions.createCallAsync({ callId, params }));
    }, [dispatch, callId]);

    const errorEditOrCreateCall = useSelector((state) => state.calls.get('errorEditOrCreate'));

    return {
        isFetchingEditOrCreate,
        submitEdit,
        submitNew,
        startTime,
        setStartTime,
        duration,
        setDuration,
        errorEditOrCreateCall,
    };
};
