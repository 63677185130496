import React from 'react';
import { Alert, PageHeader, Table, Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CALLS_PER_PAGE, CONDUCTED_CALL_STATUS_FILTER } from '../../config/constants';
import styles from './index.module.less';
import { useCallFieldsLoader } from '../../bus/calls/hooks/useCallFieldsLoader';
import { getContentNormalized } from '../../helpers';
import { ContentThumbnail } from '../ContentThumbnail';
import { useGetCalls } from '../../bus/calls/hooks/useGetCalls';
import { useBreakpoint } from '../../bus/calls/hooks/useBreakpoint';
import DateTime from '../CallsList/DateTime';

const { Text } = Typography;

export const ConductedCallsList = () => {
    const {
        calls,
        isFetching: isFetchingGetCalls,
        error: isErrorGetCalls,
        handleTableChange,
        listParams,
    } = useGetCalls(CONDUCTED_CALL_STATUS_FILTER);

    useCallFieldsLoader(); // pre-loads the post-call fields
    const bp = useBreakpoint();

    const errorGetCallsJSX = isErrorGetCalls && <Alert message={<FormattedMessage id="error.getCalls" />} type="error" />;

    const { totalResult } = (calls || []).meta || [];

    const normalizedData = [];

    if (calls && calls.data && Boolean(calls.data.length)) {
        calls.data.forEach((call) => normalizedData.push({
            call,
            key: call.id,
            timestamp: call.timestamp,
            modifiedAt: parseInt(call.modificationStamp.split('time_').pop(), 10),
            contacts: call && call.contacts && call.contacts.map((contact) => `${contact.firstName} ${contact.lastName}`),
            content: call && call.contents && getContentNormalized(call.contents),
        }));
    }

    const contentTitleTooltip = (content) => (
        <div className="text-white text-center">
            <p className="block text-white font-semibold">{content.label}</p>
            <p className="block text-white text-sm font-semibold">ver {content.version}</p>
        </div>
    );

    const columns = [
        {
            title: <FormattedMessage id="app.date" />,
            sorter: true,
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp) => <DateTime {...{ timestamp }} />,

        },
        {
            title: <FormattedMessage id="app.callEndDateAndTime" />,
            sorter: true,
            // defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            dataIndex: 'modifiedAt',
            key: 'modifiedAt',
            render: (modifiedAt) => <DateTime {...{ timestamp: modifiedAt }} />,
        },
        {
            title: <FormattedMessage id="app.participants" />,
            dataIndex: 'contacts',
            key: 'contacts',
            render: (contacts) => (
                <span>
                    {contacts && contacts.length ? contacts.map((contact, index) => (
                        <Text key={index} className={styles.contact}>
                            <span style={{ whiteSpace: 'nowrap' }}>{contact}</span>
                        </Text>
                    )) : (<></>) }
                </span>
            ),
        },
        {
            title: <FormattedMessage id="app.content" />,
            dataIndex: 'content',
            key: 'content',
            render: (contents) => (
                <div className="flex flex-start flex-wrap">
                    { contents.map((content) => (
                        <Tooltip
                            key={content.value}
                            className="m-1"
                            title={contentTitleTooltip(content)}
                        >
                            <Text>
                                <ContentThumbnail
                                    src={content.thumbnail}
                                    version={content.version}
                                />
                            </Text>
                        </Tooltip>
                    )) }
                </div>
            ),
        },
    ];

    return (
        <>
            <PageHeader
                title={(<span className="text-gray-700 tracking-wide"><FormattedMessage id="app.conductedCalls" /></span>)}
            />
            {errorGetCallsJSX}
            {!errorGetCallsJSX && (
                <>
                    <Table
                        scroll={{ x: 600, y: 'calc(100vh - 370px)' }}
                        sortOrder={listParams.order}
                        loading={isFetchingGetCalls}
                        dataSource={normalizedData}
                        columns={columns}
                        onChange={handleTableChange}
                        pagination={{
                            defaultPageSize: CALLS_PER_PAGE,
                            size: (bp <= 3) ? 'small' : 'default',
                            simple: bp === 1,
                            total: totalResult,
                            current: listParams.page,
                            showTotal: (total, range) => (
                                <FormattedMessage
                                    id="pagination.range"
                                    values={{
                                        range: (`${range[0]} - ${range[1]}`),
                                        total,
                                    }}
                                />
                            ),
                            showSizeChanger: false,
                            position: ['bottomCenter'],
                        }}
                    />
                </>
            )}
        </>
    );
};
