import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import styles from './index.module.less';
import { SiderContent } from '../../components/SiderContent';

export const OnlySiderLayout = (props) => {
    const { Content } = Layout;
    const { children, userPermissions } = props;

    return (
        <Layout className={styles.mainLayout}>
            <SiderContent
                userPermissions={userPermissions}
            />
            <Layout className="site-layout">
                <Layout>
                    <Content className={`${styles.content} ${styles.siteLayoutBackground}`}>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

OnlySiderLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
