import { apply, put, select } from 'redux-saga/effects';
import { callsActions } from '../../actions';
import { api } from '../../../../config/api';
import { normalizeSortingOrderName } from '../../../../helpers';
import { CALL_STATUS_FILTER, CALLS_PER_PAGE, CLIENT } from '../../../../config/constants';
import history from '../../../../routing/history';
import { book } from '../../../../routing/book';
import { staffActions } from '../../../staff/actions';

export function* sendInvitation(data) {
    const invitationData = {
        accountIds: [],
        contactIds: data.contactIds,
        previousModificationStamp: data.modificationStamp,
        client: CLIENT,
    };
    yield apply(api, api.calls.sendInvitation, [data.id, invitationData]);
}

function* getCalls() {
    const { page, sortBy, order } = yield select((state) => state.calls.getIn(['calls', 'listParams']));

    yield put(callsActions.getCallsAsync({
        pageSize: CALLS_PER_PAGE,
        page,
        callStatus: CALL_STATUS_FILTER,
        sort: sortBy,
        order: normalizeSortingOrderName(order),
    }));
}

export function* createCallWorker({ payload: { callId, params } }) {
    try {
        yield put(callsActions.startFetchingEditOrCreate());
        const { data } = yield apply(api, api.calls.createCall, [callId, params]);
        yield put(callsActions.createCallSuccess());

        yield getCalls();
        yield sendInvitation(data);
        history.push(book.calls);
    } catch (error) {
        if (error.response) {
            console.error('error.response', error);
            if (error.response.status === 401) { yield put(staffActions.setSessionExpired()); }
            yield put(callsActions.editOrCreateCallError({
                message: error.response.data && error.response.data.message,
                statusCode: error.response.status,
            }));
        } else if (error.request) {
            console.error('--> error request:', error.request);
        } else {
            console.error('--> error other:', error.message);
        }
    } finally {
        yield put(callsActions.stopFetchingEditOrCreate());
    }
}
