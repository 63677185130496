export const types = Object.freeze({
    /* Get Calls */
    CALLS_GET_CALLS_ASYNC: 'CALLS_GET_CALLS_ASYNC',
    CALLS_FILL_CALLS: 'CALLS_FILL_CALLS',
    CALLS_SET_LIST_PARAMS: 'CALLS_SET_LIST_PARAMS',
    CALLS_SET_LIST_PARAMS_PAGE: 'CALLS_SET_LIST_PARAMS_PAGE',
    CALLS_SET_HIGHLIGHTED_CALL_ID: 'CALLS_SET_HIGHLIGHTED_CALL_ID',
    CALLS_CLEAR_HIGHLIGHTED_CALL_ID: 'CALLS_CLEAR_HIGHLIGHTED_CALL_ID',
    CALLS_GET_CALLS_ERROR: 'CALLS_GET_CALLS_ERROR',
    CALLS_GET_CALLS_START_FETCHING: 'CALLS_GET_CALLS_START_FETCHING',
    CALLS_GET_CALLS_STOP_FETCHING: 'CALLS_GET_CALLS_STOP_FETCHING',
    CALLS_CLEAR_CALLS: 'CALLS_CLEAR_CALLS',

    /* Get Conducted Calls */
    CALLS_GET_DASHBOARD_CALLS_ASYNC: 'CALLS_GET_DASHBOARD_CALLS_ASYNC',
    CALLS_FILL_DASHBOARD_CALLS: 'CALLS_FILL_DASHBOARD_CALLS',
    CALLS_GET_DASHBOARD_CALLS_ERROR: 'CALLS_GET_DASHBOARD_CALLS_ERROR',
    CALLS_GET_DASHBOARD_CALLS_START_FETCHING: 'CALLS_GET_DASHBOARD_CALLS_START_FETCHING',
    CALLS_GET_DASHBOARD_CALLS_STOP_FETCHING: 'CALLS_GET_DASHBOARD_CALLS_STOP_FETCHING',

    /* Get Contacts */
    CALLS_GET_CONTACTS_ASYNC: 'CALLS_GET_CONTACTS_ASYNC',
    CALLS_FILL_CONTACTS: 'CALLS_FILL_CONTACTS',
    CALLS_GET_CONTACTS_ERROR: 'CALLS_GET_CONTACTS_ERROR',
    CALLS_GET_CONTACTS_START_FETCHING: 'CALLS_GET_CONTACTS_START_FETCHING',
    CALLS_GET_CONTACTS_STOP_FETCHING: 'CALLS_GET_CONTACTS_STOP_FETCHING',

    /* Get Content */
    CALLS_GET_CONTENT_ASYNC: 'CALLS_GET_CONTENT_ASYNC',
    CALLS_FILL_CONTENT: 'CALLS_FILL_CONTENT',
    CALLS_GET_CONTENT_ERROR: 'CALLS_GET_CONTENT_ERROR',
    CALLS_GET_CONTENT_START_FETCHING: 'CALLS_GET_CONTENT_START_FETCHING',
    CALLS_GET_CONTENT_STOP_FETCHING: 'CALLS_GET_CONTENT_STOP_FETCHING',

    /* Get Content Preview */

    CALLS_GET_CONTENT_PREVIEW: 'CALLS_GET_CONTENT_PREVIEW',
    CALLS_FILL_CONTENT_PREVIEW: 'CALLS_FILL_CONTENT_PREVIEW',
    CALLS_GET_CONTENT_PREVIEW_ERROR_MESSAGE: 'CALLS_GET_CONTENT_PREVIEW_ERROR_MESSAGE',

    /* Get Filters Contacts */
    CALLS_GET_FILTERS_CONTACTS_ASYNC: 'CALLS_GET_FILTERS_CONTACTS_ASYNC',
    CALLS_FILL_FILTERS_CONTACTS: 'CALLS_FILL_FILTERS_CONTACTS',
    CALLS_GET_FILTERS_CONTACTS_ERROR: 'CALLS_GET_FILTERS_CONTACTS_ERROR',
    CALLS_GET_FILTERS_CONTACTS_START_FETCHING: 'CALLS_GET_FILTERS_CONTACTS_START_FETCHING',
    CALLS_GET_FILTERS_CONTACTS_STOP_FETCHING: 'CALLS_GET_FILTERS_CONTACTS_STOP_FETCHING',

    /* Get Filters Contacts */
    CALLS_GET_FILTERS_CONTENT_ASYNC: 'CALLS_GET_FILTERS_CONTENT_ASYNC',
    CALLS_FILL_FILTERS_CONTENT: 'CALLS_FILL_FILTERS_CONTENT',
    CALLS_GET_FILTERS_CONTENT_ERROR: 'CALLS_GET_FILTERS_CONTENT_ERROR',
    CALLS_GET_FILTERS_CONTENT_START_FETCHING: 'CALLS_GET_FILTERS_CONTENT_START_FETCHING',
    CALLS_GET_FILTERS_CONTENT_STOP_FETCHING: 'CALLS_GET_FILTERS_CONTENT_STOP_FETCHING',

    /* Edit or Create Call */
    CALLS_EDIT_CALL_ASYNC: 'CALLS_EDIT_CALL_ASYNC',
    CALLS_CREATE_CALL_ASYNC: 'CALLS_CREATE_CALL_ASYNC',
    CALLS_EDIT_CALL_SUCCESS: 'CALLS_EDIT_CALL_SUCCESS',
    CALLS_CREATE_CALL_SUCCESS: 'CALLS_CREATE_CALL_SUCCESS',
    CALLS_START_FETCHING_EDIT_OR_CREATE: 'CALLS_START_FETCHING_EDIT_OR_CREATE',
    CALLS_STOP_FETCHING_EDIT_OR_CREATE: 'CALLS_STOP_FETCHING_EDIT_OR_CREATE',
    CALLS_EDIT_OR_CREATE_ERROR: 'CALLS_EDIT_OR_CREATE_ERROR',

    /* Delete Call */
    CALLS_DELETE_CALL_ASYNC: 'CALLS_DELETE_CALL_ASYNC',
    CALLS_DELETE_CALL_SUCCESS: 'CALLS_DELETE_CALL_SUCCESS',
    CALLS_DELETE_CALL_START_FETCHING: 'CALLS_DELETE_CALL_START_FETCHING',
    CALLS_DELETE_CALL_STOP_FETCHING: 'CALLS_DELETE_CALL_STOP_FETCHING,',
    CALLS_DELETE_CALL_ERROR: 'CALLS_DELETE_CALL_ERROR',

    /* Get Post Call Fields */
    CALLS_GET_CALL_FIELDS_ASYNC: 'CALLS_GET_CALL_FIELDS_ASYNC',
    CALLS_FILL_CALL_FIELDS: 'CALLS_FILL_CALL_FIELDS',
    CALLS_GET_CALL_FIELDS_START_FETCHING: 'CALLS_GET_CALL_FIELDS_START_FETCHING',
    CALLS_GET_CALL_FIELDS_STOP_FETCHING: 'CALLS_GET_CALL_FIELDS_STOP_FETCHING',
    CALLS_GET_CALL_FIELDS_ERROR: 'CALLS_GET_CALL_FIELDS_ERROR',

    /* Submit Post Call Fields */
    CALLS_SUBMIT_FIELDS_ASYNC: 'CALLS_SUBMIT_FIELDS_ASYNC',
    CALLS_SUBMIT_FIELDS_SUCCESS: 'CALLS_SUBMIT_FIELDS_SUCCESS',
    CALLS_SUBMIT_FIELDS_START_FETCHING: 'CALLS_SUBMIT_FIELDS_START_FETCHING',
    CALLS_SUBMIT_FIELDS_STOP_FETCHING: 'CALLS_SUBMIT_FIELDS_STOP_FETCHING',
    CALLS_SUBMIT_FIELDS_ERROR: 'CALLS_SUBMIT_FIELDS_ERROR',

    /* Get Invitation Email Preview */
    CALLS_GET_INVITATION_EMAIL_ASYNC: 'CALLS_GET_INVITATION_EMAIL_ASYNC',
    CALLS_GET_INVITATION_EMAIL_SUCCESS: 'CALLS_GET_INVITATION_EMAIL_SUCCESS',
    CALLS_GET_INVITATION_EMAIL_START_FETCHING: 'CALLS_GET_INVITATION_EMAIL_START_FETCHING',
    CALLS_GET_INVITATION_EMAIL_STOP_FETCHING: 'CALLS_GET_INVITATION_EMAIL_STOP_FETCHING',
    CALLS_GET_INVITATION_EMAIL_ERROR: 'CALLS_GET_INVITATION_EMAIL_ERROR',
    CALLS_GET_INVITATION_EMAIL_CLEAR: 'CALLS_GET_INVITATION_EMAIL_CLEAR',

    CLEAR_ALL_ERRORS: 'CLEAR_ALL_ERRORS',

    /* END  Call */
    CALLS_END_CALL_ASYNC: 'CALLS_END_CALL_ASYNC',
    CALLS_END_CALL_SUCCESS: 'CALLS_END_CALL_SUCCESS',
    CALLS_END_CALL_START_FETCHING: 'CALLS_END_CALL_START_FETCHING',
    CALLS_END_CALL_STOP_FETCHING: 'CALLS_END_CALL_STOP_FETCHING',
    CALLS_END_CALL_ERROR: 'CALLS_END_CALL_ERROR',

    /** STARTED CALLS */
    CALLS_GET_STARTED_CALLS_ASYNC: 'CALLS_GET_STARTED_CALLS_ASYNC',
    CALLS_GET_STARTED_CALLS_START_FETCHING: 'CALLS_GET_STARTED_CALLS_START_FETCHING',
    CALLS_GET_STARTED_CALLS_STOP_FETCHING: 'CALLS_GET_STARTED_CALLS_STOP_FETCHING',
    CALLS_GET_STARTED_CALLS_ERROR: 'CALLS_GET_STARTED_CALLS_ERROR',
    CALLS_FILL_STARTED_CALLS: 'CALLS_FILL_STARTED_CALLS',
    CALLS_CLEAR_STARTED_CALL: 'CALLS_CLEAR_STARTED_CALL',
});
