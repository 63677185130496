import React from 'react';
import PropTypes from 'prop-types';

export const ChannelIcon = ({ channel, iconClassName }) => {
    const f2fIconPath = 'M24.8,15.3c2,0,3.6-1.5,3.6-3.3V11c0-1.9-1.6-3.3-3.6-3.3s-3.6,1.5-3.6,3.3V12C21.2,13.9,22.8,15.3,24.8,15.3z\n'
    + '\t M22.7,11c0-1,0.9-1.8,2.1-1.8s2.1,0.8,2.1,1.8V12c0,1-0.9,1.8-2.1,1.8s-2.1-0.8-2.1-1.8V11z M27.7,16.4h-5.4\n'
    + '\tc-2.3,0-4.2,1.9-4.2,4.2v4.7c0,1,0.8,1.8,1.8,1.8h9.7c1,0,1.8-0.8,1.8-1.8v-4.7C31.4,18.2,29.9,16.4,27.7,16.4z M29.9,25.4\n'
    + '\tc0,0.2-0.1,0.3-0.3,0.3h-9.7c-0.2,0-0.3-0.2-0.3-0.3v-4.7c0-1.5,1.2-2.7,2.7-2.7h5.4c1.7,0,2.3,1.5,2.3,2.8V25.4z M8.4,15.3\n'
    + '\tc2,0,3.6-1.5,3.6-3.3V11c0-1.9-1.6-3.3-3.6-3.3S4.8,9.1,4.8,11V12C4.8,13.9,6.4,15.3,8.4,15.3z M6.3,11c0-1,0.9-1.8,2.1-1.8\n'
    + '\ts2.1,0.8,2.1,1.8V12c0,1-0.9,1.8-2.1,1.8S6.3,13.1,6.3,12V11z M11.3,16.4H5.9c-2.3,0-4.2,1.9-4.2,4.2v4.7c0,1,0.8,1.8,1.8,1.8h9.7\n'
    + '\tc1,0,1.8-0.8,1.8-1.8v-4.7C15,18.2,13.5,16.4,11.3,16.4z M13.5,25.4c0,0.2-0.1,0.3-0.3,0.3H3.5c-0.2,0-0.3-0.2-0.3-0.3v-4.7\n'
    + '\tc0-1.5,1.2-2.7,2.7-2.7h5.4c1.7,0,2.3,1.5,2.3,2.8V25.4z M18.2,12.4c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9\n'
    + '\tS18.7,12.4,18.2,12.4z M15.8,11.5c0,0.5-0.4,0.9-0.9,0.9S14,12,14,11.5s0.4-0.9,0.9-0.9S15.8,11,15.8,11.5z';

    const remoteIconPath = 'M20.3,11.4c-1.9,0-3.4,1.4-3.4,3.2v1c0,1.8,1.5,3.2,3.4,3.2s3.4-1.4,3.4-3.2v-1C23.7,12.8,22.2,11.4,20.3,11.4z\n'
    + '\t M22.2,15.6c0,1-0.8,1.7-1.9,1.7s-1.9-0.7-1.9-1.7v-1c0-1,0.8-1.7,1.9-1.7s1.9,0.7,1.9,1.7V15.6z M27.8,8.6H12.6\n'
    + '\tc-0.6-1.4-2-2.4-3.7-2.4c-2.2,0-4,1.6-4,3.7v1.2c0,1.8,1.4,3.3,3.3,3.6v1.6H6.1c-2.6,0-4.7,2.1-4.7,4.7v5.4c0,1.1,0.9,2,2,2h11.1\n'
    + '\tc1.1,0,1.9-0.9,2-2h11.4c2.2,0,4-1.8,4-4v-9.9C31.8,10.4,30,8.6,27.8,8.6z M6.4,11.1V9.9c0-1.3,1.1-2.2,2.5-2.2\n'
    + '\tc1.4,0,2.5,0.9,2.5,2.2v1.2c0,1.3-1.1,2.2-2.5,2.2C7.5,13.3,6.4,12.4,6.4,11.1z M14.9,26.5c0,0.3-0.2,0.5-0.5,0.5H3.3\n'
    + '\tc-0.3,0-0.5-0.2-0.5-0.5v-5.4c0-1.8,1.4-3.2,3.2-3.2h6.1c2,0,2.7,1.8,2.7,3.3V26.5z M22.5,21.8c1.8,0,3.3,1.5,3.3,3.3c0,0,0,0,0,0\n'
    + '\th-9.3v-3.3H22.5z M30.3,22.5c0,1.4-1.1,2.5-2.5,2.5h-0.5c0,0,0,0,0,0c0-2.6-2.1-4.8-4.8-4.8h-6.1c-0.3-2.3-1.9-3.9-4.1-3.9H9.7v-1.6\n'
    + '\tc1.8-0.3,3.2-1.8,3.2-3.6v-1h14.9c1.4,0,2.5,1.1,2.5,2.5V22.5z';

    const provisioningIconPath = 'M12.8,16.9c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S12.8,14.8,12.8,16.9z M18.9,16.9\n'
    + '\tc0,1.3-1,2.3-2.3,2.3s-2.3-1-2.3-2.3s1-2.3,2.3-2.3S18.9,15.6,18.9,16.9z M7.6,17.6H3.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h3.4\n'
    + '\tc0.4-5,4.6-9,9.7-9c3.7,0,7.1,2.1,8.8,5.5c0.2,0.4,0,0.8-0.3,1c-0.4,0.2-0.8,0-1-0.3c-1.4-2.9-4.2-4.6-7.4-4.6\n'
    + '\tc-4.6,0-8.3,3.7-8.3,8.3C8.3,17.3,8,17.6,7.6,17.6z M30.4,16.9c0,0.4-0.3,0.8-0.8,0.8h-3.4c-0.4,5-4.6,9-9.7,9\n'
    + '\tc-3.7,0-7.1-2.1-8.8-5.5c-0.2-0.4,0-0.8,0.3-1c0.4-0.2,0.8,0,1,0.3c1.4,2.9,4.2,4.6,7.4,4.6c4.6,0,8.3-3.7,8.3-8.3\n'
    + '\tc0-0.4,0.3-0.8,0.8-0.8h4.1C30.1,16.1,30.4,16.5,30.4,16.9z';

    const getPathData = (type) => {
        switch (type) {
        case 'face_to_face':
            return f2fIconPath;
        case 'remote':
            return remoteIconPath;
        case 'microsites':
            return provisioningIconPath;
        default:
            return '';
        }
    };

    return (
        <svg
            className={iconClassName}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 33.1 33.1"
        >
            <path d={getPathData(channel)} />
        </svg>
    );
};

ChannelIcon.defaultProps = {
    channel: '',
    iconClassName: '',
};

ChannelIcon.propTypes = {
    channel: PropTypes.string,
    iconClassName: PropTypes.string,
};
