import React from 'react';
import { PageHeader, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useContactsLoader } from '../../bus/calls/hooks/useContactsLoader';
import { useFiltersContactsLoader } from '../../bus/calls/hooks/useFiltersContactsLoader';
import { ListWithFiltersAndSearch } from '../ListWithFiltersAndSearch';
import { ContactCard } from '../ContactCard';
import { ContactDetails } from './ContactDetails';

export const Contacts = () => {
    const {
        contacts: contactsAndAccounts,
        currentPage: contactsCurrentPage,
        handlePageChange: handlePageChangeContacts,
        searchTerm: searchContactsTerm,
        handleSearch: handleSearchContacts,
        selectedFilters: selectedFiltersContacts,
        handleFilters: handleFiltersContacts,
        exactFilters: exactFiltersContacts,
        handleExactFilters: handleExactFiltersContacts,
        isFetching: isFetchingContacts,
        error: isErrorGetContacts,
        contactsPerPage,
    } = useContactsLoader({ initialSelectedContacts: [], dynamicItemsPerPage: true });

    const { totalContacts } = contactsAndAccounts?.meta || [];

    const { Text } = Typography;

    const { contacts } = contactsAndAccounts?.data || [];

    const {
        filtersContacts,
        isFetchingFiltersContacts,
        error: isErrorGetFiltersContact,
    } = useFiltersContactsLoader();

    const onChangeContactPage = (page) => handlePageChangeContacts(page);

    const handleContactsSearch = (e) => {
        e.persist();
        const { value } = e.target;
        handleSearchContacts(value);
    };

    const expandable = {
        expandedRowRender: (record) => <ContactDetails record={record} />,
    };

    const columnsContacts = [
        {
            title: <FormattedMessage id="app.name" />,
            render: (text, record) => (
                <span className="font-semibold">
                    {`${record.firstName} ${record.lastName}`}
                </span>
            ),
        },
        {
            title: <FormattedMessage id="app.email" />,
            dataIndex: 'email',
        },
        {
            title: <FormattedMessage id="app.mainPhone" />,
            dataIndex: 'mainPhone',
        },
        {
            title: <FormattedMessage id="app.optIn" />,
            align: 'center',
            render: (text, record) => (
                <Text>
                    {record.optIn ? <span className="text-success"><FormattedMessage id="app.yes" /></span> : ''}
                </Text>
            )
            ,
        },
    ];

    const cardRender = (record) => (
        <ContactCard
            key={record.id}
            contact={record}
        />
    );

    return (
        <>
            <PageHeader
                title={(<span className="text-gray-700 tracking-wide"><FormattedMessage id="app.contacts" /></span>)}
            />
            <ListWithFiltersAndSearch
                filters={filtersContacts}
                isFetchingFilters={isFetchingFiltersContacts}
                errorFilters={isErrorGetFiltersContact && <FormattedMessage id="error.filters" />}
                selectedFilters={selectedFiltersContacts}
                setSelectedFilters={handleFiltersContacts}
                exactFilters={exactFiltersContacts}
                setExactFilters={handleExactFiltersContacts}
                onSearchInputChange={(e) => handleContactsSearch(e)}
                searchTerm={searchContactsTerm}
                error={isErrorGetContacts && <FormattedMessage id="error.getContacts" />}
                loadingTable={isFetchingContacts}
                dataSource={contacts}
                columns={columnsContacts}
                paginationTotalItems={totalContacts}
                onChangePage={onChangeContactPage}
                currentPage={contactsCurrentPage}
                isSelectable={false}
                expandable={expandable}
                gridView={{
                    cardRender,
                    stateKey: 'contacts-view-mode',
                    initialViewMode: 'grid',
                }}
                itemsPerPage={contactsPerPage}
            />
        </>
    );
};
