import { takeEvery, all, call } from 'redux-saga/effects';
import { types } from '../types';
import { getDashboardThisWeekCallsWorker } from './workers/getDashboardThisWeekCallsWorker';

function* getDashboardThisWeekCallsWatcher() {
    yield takeEvery(types.DASHBOARD_TABLE_CALLS_ASYNC, getDashboardThisWeekCallsWorker);
}

export function* dashboardWatchers() {
    yield all(
        [
            call(getDashboardThisWeekCallsWatcher),
        ],
    );
}
