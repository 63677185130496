/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Typography, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useStaff } from '../../bus/staff/hooks/useStaff';
import { useFiltersContactsLoader } from '../../bus/calls/hooks/useFiltersContactsLoader';
import { useContactsLoader } from '../../bus/calls/hooks/useContactsLoader';
import { USER_PERMISSIONS } from '../../config/constants';
import { ListWithFiltersAndSearch } from '../ListWithFiltersAndSearch';
import { filterContactByProperties } from '../../helpers/index';
import { PropTypeArray, PropTypeFunc, PropTypeString } from '../../helpers/propTypes';

const { Text } = Typography;

export function ContactSelector({
    onContactsChanged,
    preSelectedContacts = [],
    normalizerContactFn,
    filterContactsBy,
    type,
}) {
    const { userPermissions } = useStaff();
    const {
        filtersContacts,
        isFetchingFiltersContacts,
        error: isErrorGetFiltersContact,
    } = useFiltersContactsLoader();

    const {
        contacts: contactsAndAccounts,
        selectedContacts,
        setSelectedContacts,
        searchTerm: searchContactsTerm,
        handleSearch: handleSearchContacts,
        currentPage: currentPageContacts,
        handlePageChange: handlePageChangeContacts,
        selectedFilters: selectedFiltersContacts,
        handleFilters: handleFiltersContacts,
        exactFilters: exactFiltersContacts,
        handleExactFilters: handleExactFiltersContacts,
        isFetching: isFetchingContacts,
        error: isErrorGetContacts,
    } = useContactsLoader(false, preSelectedContacts);

    const handleContactsSearch = (e) => {
        e.persist();
        const { value } = e.target;
        handleSearchContacts(value);
    };

    useEffect(() => {
        if (onContactsChanged) {
            onContactsChanged(selectedContacts);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContacts]);

    const { contacts: preFilteredContacts } = contactsAndAccounts?.data || [];
    const contacts = filterContactsBy
        ? filterContactByProperties(preFilteredContacts || [], filterContactsBy)
        : preFilteredContacts;
    const { totalContacts } = contactsAndAccounts?.meta || [];

    const columnsContacts = [
        {
            title: <FormattedMessage id="app.name" />,
            render: (text, record) => (
                <Text>{`${record.firstName} ${record.lastName}`}</Text>
            ),
        },
        {
            title: <FormattedMessage id="app.email" />,
            dataIndex: 'email',
        },
        {
            title: <FormattedMessage id="app.cellPhone" />,
            dataIndex: 'cellPhone',
        },
    ];

    const onChangeContactPage = (page) => handlePageChangeContacts(page);

    const errorNoAccessToContactsJSX = (
        <Alert
            message={<FormattedMessage id="error.noAccessToContacts" />}
            banner
        />
    );

    return userPermissions.includes(USER_PERMISSIONS.CONTACTS) ? (
        <>
            <ListWithFiltersAndSearch
                filters={filtersContacts}
                errorFilters={
                    isErrorGetFiltersContact && (
                        <FormattedMessage id="error.filters" />
                    )
                }
                isFetchingFilters={isFetchingFiltersContacts}
                selectedFilters={selectedFiltersContacts}
                setSelectedFilters={handleFiltersContacts}
                exactFilters={exactFiltersContacts}
                setExactFilters={handleExactFiltersContacts}
                onSearchInputChange={(e) => handleContactsSearch(e)}
                searchTerm={searchContactsTerm}
                error={
                    isErrorGetContacts && (
                        <FormattedMessage id="error.getContacts" />
                    )
                }
                loadingTable={isFetchingContacts}
                dataSource={contacts}
                columns={columnsContacts}
                paginationTotalItems={totalContacts}
                onChangePage={onChangeContactPage}
                currentPage={currentPageContacts}
                /** Check and rename preSelectContacts and replace with selected and .... */
                selectedItemsNormalized={normalizerContactFn(
                    preSelectedContacts,
                )}
                selectedItems={preSelectedContacts}
                setSelectedItems={setSelectedContacts}
                showMessageOnEmptySelection={false}
                tableProps={{ scroll: { y: '30vh' } }}
                type={type}
            />
        </>
    ) : (
        errorNoAccessToContactsJSX
    );
}

ContactSelector.propTypes = {
    onContactsChanged: PropTypeFunc,
    preSelectedContacts: PropTypeArray,
    normalizerContactFn: PropTypeFunc,
    filterContactsBy: PropTypeArray,
    type: PropTypeString,
};
