import * as types from './types';

/**
 * set batch of messaging templates
 * @param {Object} templates
 * @returns void
 */
export const setMessagingTemplates = (templates) => ({
    type: types.MESSAGING_TEMPLATES_SET,
    payload: templates,
});

export const clearMessagingTemplates = () => ({
    type: types.MESSAGING_TEMPLATES_CLEAR,
});

export const startFetchingMessagingTemplates = () => ({
    type: types.MESSAGING_TEMPLATES_START_FETCHING,
});

export const stopFetchingMessagingTemplates = () => ({
    type: types.MESSAGING_TEMPLATES_STOP_FETCHING,
});

export const setFetchedMessagingTemplates = () => ({
    type: types.MESSAGING_TEMPLATES_FETCHED,
});

export const setMessageTemplatesError = (error) => ({
    type: types.MESSAGING_TEMPLATES_SET_ERROR,
    payload: error,
});

export const fetchMessagingTemplate = () => ({
    type: types.MESSAGING_TEMPLATES_FETCH,
});
