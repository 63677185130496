/* eslint-disable max-len */
import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SelectedItem } from '../SelectedItem';

export const SelectedItems = (props) => {
    const { items, onCloseTag = () => {}, loading = false, truncateLabel = false, showMessageOnEmptySelection = true, showCloseButton = true } = props;

    if (!items?.length && showMessageOnEmptySelection) { return <FormattedMessage id="app.noneSelected" />; }
    if (!items?.length && !showMessageOnEmptySelection) return <span />;

    return (
        <Spin spinning={loading}>
            <div style={
                {
                    width: '100%',
                    padding: 0,
                    marginBottom: 0,
                    display: 'flex',
                    flexWrap: 'wrap',
                }
            }
            >
                {items.map((item) => (
                    <SelectedItem
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        thumbnail={item.thumbnail}
                        onCloseTag={onCloseTag}
                        truncateLabel={truncateLabel}
                        icon={item.icon}
                        iconClassNames={item.iconClassNames}
                        showCloseButton={showCloseButton}
                    />
                ))}
            </div>
        </Spin>
    );
};

SelectedItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
    onCloseTag: PropTypes.func,
    loading: PropTypes.bool,
    truncateLabel: PropTypes.bool,
    showMessageOnEmptySelection: PropTypes.bool,
    showCloseButton: PropTypes.bool,
};
