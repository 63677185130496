export default Object.freeze({
    STAFF_AUTH_ASYNC: 'STAFF_AUTH_ASYNC',
    STAFF_FILL_USER_DATA: 'STAFF_FILL_USER_DATA',
    STAFF_AUTH_SET_ERROR: 'STAFF_AUTH_SET_ERROR',
    STAFF_SET_LOCALE_ASYNC: 'STAFF_SET_LANGUAGE_ASYNC',
    STAFF_SET_LOCALE_ERROR: 'STAFF_SET_LOCALE_ERROR',
    STAFF_SET_LOCALE_START_FETCHING: 'STAFF_SET_LOCALE_START_FETCHING',
    STAFF_SET_LOCALE_STOP_FETCHING: 'STAFF_SET_LOCALE_STOP_FETCHING',
    STAFF_SET_SESSION_EXPIRED: 'STAFF_SET_SESSION_EXPIRED',
});
