import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from '../actions';

export const useSubmitCallFields = () => {
    const dispatch = useDispatch();

    const submitCallFields = (callId, callFields, values) => {
        const resultCallFields = [];

        callFields.forEach((field) => {
            const newField = {};
            Object.keys(field).forEach((key) => {
                newField[key] = field[key];
                const currentValue = values[field.id];

                if ((currentValue === null) || (currentValue === '')) return;
                newField.value = currentValue;
                if (field.type === 'options') {
                    newField.value = currentValue;
                    return;
                }
                if (field.type === 'external') {
                    const { url, required, waitForCompletion } = field;
                    newField.url = url;
                    newField.required = required;
                    newField.waitForCompletion = waitForCompletion;
                    newField.value = currentValue.startTime ? [currentValue] : [];
                    return;
                }
                newField.value = values[field.id];
            });
            resultCallFields.push(newField);
        });

        const data = {
            meetingId: callId,
            callFields: resultCallFields,
        };

        dispatch(callsActions.submitFieldsAsync({
            data,
        }));
    };

    const isFetchingSubmitFields = useSelector((state) => state.calls.get('isFetchingSubmitFields'));
    const error = useSelector((state) => state.calls.get('errorSubmitFields'));

    return {
        submitCallFields,
        isFetchingSubmitFields,
        error,
    };
};
