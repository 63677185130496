import { takeEvery, all, call } from 'redux-saga/effects';
import * as types from '../types';
import { provisionWorker } from './workers/provisionWorker';

function* provisionContentWatcher() {
    yield takeEvery(types.PROVISION_SEND_ASYNC, provisionWorker);
}

export function* provisionWatchers() {
    yield all([call(provisionContentWatcher)]);
}
