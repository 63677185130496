/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Tooltip } from 'antd';
import { truncate } from 'lodash';
import cx from 'classnames';
import { Thumbnail } from './index';
import styles from './index.module.less';
import {
    PropTypeBoolean,
    PropTypeFunc,
    PropTypeObject,
} from '../../helpers/propTypes';

export function ShareableCard({
    sharable,
    isSelected,
    onSelect,
    truncateTitle = false,
}) {
    return (
        <Card
            size="small"
            onClick={() => onSelect(sharable)}
            className={cx(
                'rounded-16',
                'shadow',
                'overflow-hidden',
                'cursor-pointer',
                'border-solid',
                {
                    'border-2 border-blue-700': isSelected,
                    'border border-gray-400': !isSelected,
                },
                styles.provisionCard,
            )}
            rol="button"
            bordered={false}
            cover={<Thumbnail thumbnailUrl={sharable.thumbnailUrl} />}
        >
            <Card.Meta
                className={styles.description}
                style={{ backgroundColor: isSelected ? '#175f92' : '#fdfdfd' }}
                title={(
                    <Tooltip title={sharable.name}>
                        <p
                            className="text-sm text-center"
                            style={{ color: isSelected ? '#fff' : '#000', height: '24px' }}
                        >
                            {truncateTitle
                                ? truncate(sharable.name, {
                                    length: 18,
                                    separator: '...',
                                })
                                : sharable.name}
                        </p>
                    </Tooltip>
                )}
            />
        </Card>
    );
}

ShareableCard.propTypes = {
    sharable: PropTypeObject,
    isSelected: PropTypeBoolean,
    onSelect: PropTypeFunc,
    truncateTitle: PropTypeBoolean,
};
