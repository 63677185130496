/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Input, Select, Radio, InputNumber, Button } from 'antd';
import { FormattedMessage } from 'react-intl';

function CallFields({ formikProps, field, handleExternalClick }) {
    const [selectedItems, setSelectedItems] = useState([]);
    const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched } = formikProps;
    if (field.type === 'text') {
        return (
            <Input
                id={field.id}
                type="text"
                value={values[field.id]}
                onChange={handleChange}
                onBlur={handleBlur}
                className={errors[field.id] && touched[field.id] ? 'text-input error' : 'text-input'}
            />
        );
    }
    if (field.type === 'options') {
        const filteredOptions = field.options.items.filter((o) => !selectedItems.includes(o.value));

        return (
            <Select
                id={field.id}
                mode="multiple"
                value={selectedItems}
                onChange={(value) => {
                    const optionValue = value?.length ? value : null;
                    setFieldValue(field.id, optionValue);
                    setSelectedItems(value);
                }}
                onBlur={() => setFieldTouched(field.id, true)}
                showArrow
                allowClear
                bordered
                style={{ width: '100%' }}
            >
                {filteredOptions.map((item) => (
                    <Select.Option key={item.value} value={item.value} data-testid={`${field.id}-${item.value}`}>
                        {item.label}
                    </Select.Option>
                ))}
            </Select>

        );
    }
    if (field.type === 'toggle') {
        return (
            <Radio.Group
                onChange={(e) => setFieldValue(field.id, e.target.value)}
                value={values[field.id]}
                defaultValue="a"
                buttonStyle="solid"
                size="medium"
            >
                <Radio.Button data-testid="call-fields-radio-group-yes" value><FormattedMessage id="app.yes" /></Radio.Button>
                <Radio.Button value={false}><FormattedMessage id="app.no" /></Radio.Button>
            </Radio.Group>
        );
    }
    if (field.type === 'number') {
        return (
            <InputNumber
                id={field.id}
                min="0"
                onChange={(value) => setFieldValue(field.id, value)}
                value={values[field.id]}
                onBlur={() => setFieldTouched(field.id, true)}
                type="number"
                data-testid="input-number"
            />
        );
    }
    if (field.type === 'external') {
        return <Button onClick={() => handleExternalClick(field)}>Open</Button>;
    }
    return null;
}

export default CallFields;
