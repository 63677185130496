import * as types from './types';

export const clearProvisioning = () => ({
    type: types.PROVISION_CLEAR,
});
/** Content Actions */

export const setProvisionContent = (content) => ({
    type: types.PROVISION_SET_CONTENT,
    payload: content,
});

/** End of Content Actions */

/** Shareable Actions */

export const addProvisionShareable = (item) => ({
    type: types.PROVISION_ADD_SHAREABLE,
    payload: item,
});

export const removeProvisionShareable = (itemId) => ({
    type: types.PROVISION_REMOVE_SHAREABLE,
    payload: itemId,
});

/** End of Shareable Actions */

/** Contacts Actions */
export const addCustomContact = (contact) => ({
    type: types.PROVISION_ADD_CUSTOM_CONTACTS,
    payload: contact,
});
export const removeCustomContact = (contact) => ({
    type: types.PROVISION_REMOVE_CUSTOM_CONTACTS,
    payload: contact,
});

export const setContacts = (contacts) => ({
    type: types.PROVISION_SET_CONTACTS,
    payload: contacts,
});
/** End of Contacts Actions */

/** Transport Actions */

export const setProvisionTransport = (transport) => ({
    type: types.PROVISION_SET_TRANSPORT,
    payload: transport,
});

/** End of Transport Actions */

/** Messaging Template Actions */
export const setProvisionMessagingTemplate = (template) => ({
    type: types.PROVISION_SET_MESSAGING_TEMPLATE,
    payload: template,
});
/** End of Messaging Templates Actions */

/** Send Async Actions */
export const sendProvision = (payload) => ({
    type: types.PROVISION_SEND_ASYNC,
    payload,
});

export const sendProvisionStart = () => ({
    type: types.PROVISION_SEND_ASYNC_START,
});

export const sendProvisionSuccessful = () => ({
    type: types.PROVISION_SEND_ASYNC_SUCCESSFUL,
});

export const sendProvisionHasError = (error) => ({
    type: types.PROVISION_SEND_ASYNC_ERROR,
    payload: error,
});
/** End of Send Async Actions */
