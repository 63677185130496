import { Redirect, Route, Router, Switch } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { MainLayout } from '../../layouts/MainLayout';
import { Contacts } from '../../components/Contacts';
import { book } from '../book';
import { CallsList } from '../../components/CallsList';
import { ConductedCallsList } from '../../components/ConductedCallsList';
import { Contents } from '../../components/Content';
import { Revealer } from '../../components/Revealer';
import { Dashboard } from '../../components/Dashboard';
import hist from '../history';
import { USER_PERMISSIONS } from '../../config/constants';
import { NoMatch } from '../../components/NoMatch';
import { OnlySiderLayout } from '../../layouts/OnlySiderLayout';
import { StartedCall } from '../../components/StartedCall';

export const Private = (props) => {
    const { userData, userPermissions } = props;

    const mainLayoutProps = {
        userData,
        userPermissions,
    };

    return (
        <>
            <StartedCall />
            <Router history={hist}>
                <Switch>
                    <Route
                        path={book.noMatch}
                        render={() => (<NoMatch />)}
                    />
                    <Route
                        key={1}
                        path={book.calls}
                        render={() => (
                            <MainLayout {...mainLayoutProps}>
                                <CallsList userPermissions={userPermissions} />
                            </MainLayout>
                        )}
                    />
                    <Route
                        key={2}
                        path={book.conductedCalls}
                        render={() => (
                            <MainLayout {...mainLayoutProps}>
                                <ConductedCallsList />
                            </MainLayout>
                        )}
                    />
                    {userPermissions.includes(USER_PERMISSIONS.CONTACTS) && (
                        <Route
                            key={3}
                            path={book.contacts}
                            render={() => (
                                <MainLayout {...mainLayoutProps}>
                                    <Contacts />
                                </MainLayout>
                            )}
                        />
                    )}
                    <Route
                        key={4}
                        path={book.content}
                        render={() => (
                            <MainLayout {...mainLayoutProps}>
                                <Contents />
                            </MainLayout>
                        )}
                    />
                    {userPermissions.includes(USER_PERMISSIONS.REVEALER) && (
                        <Route
                            key={5}
                            path={book.revealer}
                            render={() => (
                                <OnlySiderLayout userPermissions={userPermissions}>
                                    <Revealer />
                                </OnlySiderLayout>
                            )}
                        />
                    )}
                    <Route
                        key={6}
                        path={book.dashboard}
                        render={() => (
                            <MainLayout {...mainLayoutProps}>
                                <Dashboard userPermissions={userPermissions} />
                            </MainLayout>
                        )}
                    />
                    <Route
                        key={7}
                        path="*"
                    >
                        <Redirect to={book.dashboard} />
                    </Route>
                </Switch>
            </Router>
        </>
    );
};

Private.propTypes = {
    userData: PropTypes.shape({
        username: PropTypes.string,
    }).isRequired,
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
};

Private.defaultProps = {
    location: {
        pathname: '',
    },
};
