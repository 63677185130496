import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'js-cookie';
import { staffActions } from '../actions';
import { MOBILIZER_TOKEN } from '../../../config/constants';
import { goToLogin } from '../../../helpers';

const getAuthToken = () => {
    const queryString = window.location.search;
    const query = new URLSearchParams(queryString);
    const mobilizerTokenFromUrl = query.get(MOBILIZER_TOKEN);

    if (mobilizerTokenFromUrl) {
        cookie.set(MOBILIZER_TOKEN, mobilizerTokenFromUrl);
    }

    return cookie.get(MOBILIZER_TOKEN);
};

export const useStaff = () => {
    const token = getAuthToken();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.staff.get('userData'));

    useEffect(() => {
        if (!token) {
            goToLogin();
        } else if (!userData) {
            dispatch(staffActions.getUserData());
        }
    }, [dispatch, token, userData]);

    const changeLocale = (value) => {
        const data = { locale: value };
        dispatch(staffActions.setLocaleAsync(data));
    };

    const isFetchingSetLocale = useSelector((state) => state.staff.getIn(['locale', 'isFetching']));

    const userPermissions = (userData && userData.settings && userData.settings.features) || [];

    const error = useSelector((state) => state.staff.get('error'));

    return {
        userData,
        changeLocale,
        isFetchingSetLocale,
        userPermissions,
        error,
    };
};
