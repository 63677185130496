import { takeEvery, all, call } from 'redux-saga/effects';
import * as types from '../types';
import * as messagingTemplatesActions from '../actions';
import { api } from '../../../config/api';
import { makeRequestWorker } from '../../../workers/makeRequestWorker';
import schema from '../schemas/messagingTemplatesSchema.json';

function* messagingTemplatesWorker() {
    const options = {
        fetcher: api.messagingTemplates.getMessagingTemplates,
        fetcherParam: null,
        successAction: messagingTemplatesActions.setMessagingTemplates,
        setErrorAction: messagingTemplatesActions.setMessageTemplatesError,
        schema,
    };

    yield makeRequestWorker(options);
}
function* getMessagingTemplatesWatcher() {
    yield takeEvery(types.MESSAGING_TEMPLATES_FETCH, messagingTemplatesWorker);
}

export function* messagingTemplateWatchers() {
    yield all([call(getMessagingTemplatesWatcher)]);
}
