import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from '../actions';
import { CALLS_PER_PAGE } from '../../../config/constants';
import { normalizeSortingOrderName } from '../../../helpers';

export const useGetCalls = (callFilters) => {
    const dispatch = useDispatch();
    const calls = useSelector((state) => state.calls.getIn(['calls', 'entities']));
    const isFetching = useSelector((state) => state.calls.getIn(['calls', 'isFetching']));
    const error = useSelector((state) => state.calls.getIn(['calls', 'error']));
    const listParams = useSelector((state) => state.calls.getIn(['calls', 'listParams']));
    const highlightedCallId = useSelector((state) => state.calls.getIn(['calls', 'highlightedCallId']));
    const loadCalls = (page, sortBy, order) => {
        dispatch(callsActions.getCallsAsync(
            {
                pageSize: CALLS_PER_PAGE,
                page,
                callStatus: callFilters,
                sort: sortBy,
                order: normalizeSortingOrderName(order),
            },
        ));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        dispatch(callsActions.setListParams({
            page: pagination.current,
            sortBy: sorter.columnKey,
            order: sorter.order,
        }));

        loadCalls(pagination.current, sorter.columnKey, sorter.order);
    };

    /* initial load */
    useEffect(() => {
        loadCalls(1, 'timestamp', 'descend');
        return () => dispatch(callsActions.clearCalls());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return {
        calls,
        isFetching,
        error,
        listParams,
        handleTableChange,
        loadCalls,
        highlightedCallId,
    };
};
