import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from 'antd';
import { ReactComponent as UserIcon } from '../../ag-icons/Rainmaker_soft_icon_37_User.svg';

export const ContactCard = (props) => {
    const { contact } = props;
    const { Text } = Typography;

    const contactName = (record) => (`${record.firstName} ${record.lastName}`);

    const space = '\xa0'; // blank space

    const subTitle = (record) => {
        const title = !record.jobTitle ? '' : record.jobTitle;
        const contactSpeciality = !record.primarySpecialty ? '' : record.primarySpecialty;
        const divider = title && contactSpeciality ? ' | ' : space;
        return `${title}${divider}${contactSpeciality}`;
    };

    const classList = (classes) => Object
        .entries(classes)
        .filter((entry) => entry[1])
        .map((entry) => entry[0])
        .join(' ');

    return (
        <div
            className="w-64 relative my-2 mx-3 pt-4 px-4 pb-2 max-w-sm w-full bg-white rounded overflow-hidden shadow"
            style={{ width: '300px' }}
        >
            <div className="mb-4">
                <div className="flex items-center">
                    <div id="user-icon" className="hidden md:block w-4 py-1 -mt-1 mr-2">
                        <UserIcon
                            className={classList({
                                'w-5 h-5 fill-current': true,
                                'text-success': contact.optIn,
                                'text-disabled': !contact.optIn,
                            })}
                        />
                    </div>
                    <Tooltip className="tracking-tight text-sm leading-tight font-semibold text-gray-700" title={contactName(contact)}>
                        <Text className="font-bold text-sm truncate">{contactName(contact)}</Text>
                    </Tooltip>
                </div>
                <div className="flex justify-start">
                    <div className="ml-1 text-vsm font-light text-gray-600">{subTitle(contact)}</div>
                </div>
            </div>
            <div className="flex flex-col text-vsm">
                <div className="font-light text-gray-600">{contact.city || space}</div>
                <div className="hidden md:block font-light text-link">{contact.email || space}</div>
                <div className="font-light text-gray-600"><span>{contact.mainPhone || space}</span></div>
            </div>
        </div>
    );
};

ContactCard.propTypes = {
    contact: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        optIn: PropTypes.bool,
        kol: PropTypes.bool,
        speaker: PropTypes.bool,
        noSeePhysician: PropTypes.bool,
        eDetailing: PropTypes.bool,
        formalTitle: PropTypes.string,
        middleName: PropTypes.string,
        gender: PropTypes.string,
        jobTitle: PropTypes.string,
        specialty: PropTypes.string,
        primarySpecialty: PropTypes.string,
        address: PropTypes.string,
        address2: PropTypes.string,
        address3: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string,
        geography: PropTypes.string,
        brick: PropTypes.string,
        mainPhone: PropTypes.string,
        cellPhone: PropTypes.string,
        email: PropTypes.string,
        url: PropTypes.string,
        bttcDay: PropTypes.string,
        bttcStartTime: PropTypes.string,
        bttcEndTime: PropTypes.string,
    }).isRequired,
};
