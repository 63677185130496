/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { callsActions } from '../actions';
import {
    DEBOUNCE_TIME,
    DEFAULT_ITEMS_PER_PAGE,
    CONTENT_CARD_SIZING_PROPS,
} from '../../../config/constants';
import {
    isResizeExpandedByItemsPerPage,
    getAnchorPosition,
    getMaxItemsPerPage,
    calculatePageNumber,
} from '../../../helpers';

const getMaxItems = () => getMaxItemsPerPage(
    undefined,
    CONTENT_CARD_SIZING_PROPS.breakPoint,
    CONTENT_CARD_SIZING_PROPS.tileM,
    CONTENT_CARD_SIZING_PROPS.tileXL,
);

/**
 *
 * @param {Boolean} dynamicItemsPerPage if true then it tries to find best number of items per page
 * @returns
 */
export const useContentLoader = (dynamicItemsPerPage = false, initialSelectedContents = []) => {
    const initItemsPerPage = dynamicItemsPerPage
        ? getMaxItems()
        : DEFAULT_ITEMS_PER_PAGE;
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedContents, setSelectedContents] = useState(initialSelectedContents);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [exactFilters, setExactFilters] = useState([]);
    const [contentsPerPage, setContentsPerPage] = useState(initItemsPerPage);
    const getSelectedFiltersIds = (filters) => filters.map((filter) => filter.value);
    const contents = useSelector((state) => state.calls.getIn(['content', 'entities']));
    const isFetching = useSelector((state) => state.calls.getIn(['content', 'isFetching']));
    const error = useSelector((state) => state.calls.getIn(['content', 'error']));

    const loadContent = (
        page,
        term,
        filters,
        exactFiltersIds,
        limit = contentsPerPage,
    ) => {
        let val = '';
        if (term.length > 1) {
            val = term.toLowerCase();
        }

        dispatch(
            callsActions.getContentAsync({
                limit,
                searchTerm: val,
                page,
                filters,
                exactFiltersIds,
            }),
        );
    };

    const debounceLoadContent = useCallback(
        debounce(loadContent, DEBOUNCE_TIME),
        [],
    );
    const handleResize = () => {
        const newItemsPerPage = getMaxItems();
        if (newItemsPerPage !== contentsPerPage) {
            const currentAnchor = getAnchorPosition(currentPage, contentsPerPage);
            const page = calculatePageNumber(
                isResizeExpandedByItemsPerPage(contentsPerPage, newItemsPerPage),
                currentAnchor,
                newItemsPerPage,
            );
            setContentsPerPage(newItemsPerPage);
            setCurrentPage(page);
            loadContent(
                page,
                searchTerm,
                getSelectedFiltersIds(selectedFilters),
                exactFilters,
                newItemsPerPage,
            );
        }
    };
    const debouncedHandleResize = useCallback(
        () => { debounce(handleResize, DEBOUNCE_TIME); },
        [currentPage, setCurrentPage, contentsPerPage, setContentsPerPage],
    );
    /* Initial load */
    useEffect(() => {
        loadContent(1, searchTerm, getSelectedFiltersIds(selectedFilters), exactFilters);
        // return () => dispatch(callsActions.clearContent()); /* TODO */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handlePageChange = (page) => {
        loadContent(page, searchTerm, getSelectedFiltersIds(selectedFilters), exactFilters);
        setCurrentPage(page);
    };

    const handleSearch = (term) => {
        if (term.length !== 1) {
            setCurrentPage(1);
            debounceLoadContent(1, term, getSelectedFiltersIds(selectedFilters), exactFilters);
        }
        setSearchTerm(term);
    };

    const handleSelectedFilters = (filters) => {
        setCurrentPage(1);
        setSelectedFilters(filters);
        loadContent(1, searchTerm, getSelectedFiltersIds(filters), exactFilters);
    };

    const handleExactFilters = (exactFltrs) => {
        setCurrentPage(1);
        setExactFilters(exactFltrs);
        loadContent(1, searchTerm, getSelectedFiltersIds(selectedFilters), exactFltrs);
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (dynamicItemsPerPage) {
            window.addEventListener('resize', debouncedHandleResize);
            return () => {
                window.removeEventListener('resize', debouncedHandleResize);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedHandleResize]);

    return {
        contents,
        selectedContents,
        setSelectedContents,
        currentPage,
        handlePageChange,
        searchTerm,
        handleSearch,
        selectedFilters,
        handleSelectedFilters,
        exactFilters,
        handleExactFilters,
        isFetching,
        error,
        contentsPerPage,
    };
};
