import { put } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { staffActions } from '../../actions';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import schema from '../../schemas/authSchema.json';
import { callsActions } from '../../../calls/actions';

function* handleSuccess() {
    yield yield put(callsActions.getStartedCallsAsync());
}

export function* authenticateWorker() {
    const options = {
        fetcher: api.staff.getUserData,
        fetcherParam: null,
        successAction: staffActions.fillUserData,
        setErrorAction: staffActions.authError,
        onSuccess: () => handleSuccess(),
        schema,
    };

    yield makeRequestWorker(options);
}
