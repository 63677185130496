/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import { useStaff } from '../../bus/staff/hooks/useStaff';
import styles from './index.module.less';
import { Private } from '../../routing/Private';
import { logout } from '../../helpers';
import { IntlProviderWrapper } from '../../i18n/IntlProviderWrapper';

const App = () => {
    const { userData, userPermissions, error } = useStaff();

    const isSessionExpired = useSelector((state) => state.staff.get('isSessionExpired'));

    if (isSessionExpired || error) {
        return (
            <Modal.error
                title="It appears that your session has expired. Please sign in again" /* this message is not translated because with expired token we can not get the users preferred language  */
                onOk={() => logout()}
                onCancel={() => logout()}
            >
                <p>Some content...</p>
            </Modal.error>

        );
    }

    const authenticated = !error && userData;

    if (!authenticated) {
        return (
            <div className={styles.mainSpinner}>
                <Spin />
            </div>
        );
    }

    return (
        <IntlProviderWrapper userData={userData}>
            { authenticated && <Private userData={userData} userPermissions={userPermissions} /> }
        </IntlProviderWrapper>
    );
};

export default App;
