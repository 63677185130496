import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

export const EditOrCreateSubmitButton = (props) => {
    const {
        isNewCall,
        disabled,
        loading,
        handleSubmitNew,
        handleSubmitEdit,
    } = props;

    return isNewCall ? (
        <Button
            type="primary"
            onClick={() => handleSubmitNew()}
            disabled={disabled}
            loading={loading}
            className="ml-3"
        >
            {/* <FormattedMessage id="app.save" /> */}
            Create and send notifications
        </Button>
    ) : (
        <Dropdown.Button
            type="primary"
            onClick={() => handleSubmitEdit(true)}
            icon={<DownOutlined />}
            trigger="click"
            disabled={disabled}
            overlay={(
                <Menu onClick={() => handleSubmitEdit(false)}>
                    <Menu.Item disabled={disabled}>
                        Save without sending notification
                    </Menu.Item>
                </Menu>
            )}
            buttonsRender={([leftButton, rightButton]) => [
                leftButton,
                React.cloneElement(rightButton, { loading }),
            ]}
            className="ml-3"
        >
            Save and send notifications
        </Dropdown.Button>
    );
};

EditOrCreateSubmitButton.propTypes = {
    isNewCall: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    handleSubmitNew: PropTypes.func.isRequired,
    handleSubmitEdit: PropTypes.func.isRequired,
};
