import { isFileExtensionSupported } from '../../helpers/handlers/index';

export const getSupportedFileName = (fileList) => {
    for (const file of fileList) {
        if (isFileExtensionSupported(file.relativePath)) {
            return file.relativePath;
        }
    }
    return null;
};
