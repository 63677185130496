/* eslint-disable max-len */
import React from 'react';
import { Space, Button, Alert, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { ReactComponent as MicrositesIcon } from '../../ag-icons/Rainmaker_soft_icon_19_Share.svg';
import styles from './index.module.less';
import { PropTypeBoolean, PropTypeFunc, PropTypeNumber, PropTypeString } from '../../helpers/propTypes';

const { Text } = Typography;

export function ProvisionModalFooter({
    provisionStep,
    onNext,
    onPrevious,
    isNextStepAvailable,
    nextStepWarningMessage,
    isPreviousStepAvailable,
    onProvision,
    isFetchingProvision,
}) {
    return (
        <div className={cx('flex justify-between', styles.modalFooter)}>
            <Space className="mr-2">
                {nextStepWarningMessage ? (
                    <Alert showIcon type="warning" message={nextStepWarningMessage} />
                ) : (
                    <Space className="w-full" />
                )}
            </Space>
            <Space>
                <Button
                    type="default"
                    onClick={onPrevious}
                    disabled={!isPreviousStepAvailable || isFetchingProvision}
                >
                    <FormattedMessage id="app.previous" />
                </Button>
                {provisionStep !== 3 ? (
                    <Button
                        type="primary"
                        onClick={onNext}
                        disabled={!isNextStepAvailable}
                    >
                        <FormattedMessage id="app.next" />
                    </Button>
                ) : null}
                {provisionStep === 3 ? (
                    <Button
                        type="primary"
                        loading={isFetchingProvision}
                        disabled={isFetchingProvision}
                        onClick={onProvision}
                        icon={<Text> <MicrositesIcon className="w-6 h-6 fill-current text-white" /></Text>}
                    >
                        <Text className="align-top" style={{ color: isFetchingProvision ? '#94a3b8' : '#fdfdfd' }}> <FormattedMessage id="app.share" /></Text>

                    </Button>
                ) : null}
            </Space>
        </div>
    );
}

ProvisionModalFooter.propTypes = {
    provisionStep: PropTypeNumber.isRequired,
    onNext: PropTypeFunc.isRequired,
    onPrevious: PropTypeFunc.isRequired,
    isNextStepAvailable: PropTypeBoolean,
    nextStepWarningMessage: PropTypeString,
    onProvision: PropTypeFunc.isRequired,
    isPreviousStepAvailable: PropTypeBoolean,
    isFetchingProvision: PropTypeBoolean.isRequired,
};
