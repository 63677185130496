/* eslint-disable max-len */
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Tooltip, Table, Badge, Alert } from 'antd';
import cookie from 'js-cookie';
import { formatDistance } from 'date-fns';
import { PropTypeArray, PropTypeArrayOfObject, PropTypeBoolean } from '../../helpers/propTypes';
import styles from './dashboardTable.module.less';
import { ReactComponent as RemoteCallIcon } from '../../ag-icons/Rainmaker_soft_icon_25_RemoteCall.svg';
import { MOBILIZER_TOKEN } from '../../config/constants';
import { ContentThumbnail } from '../ContentThumbnail';
import { getContentNormalized } from '../../helpers';
import { useEndCall } from '../../bus/calls/hooks/useEndCall';
import { useBreakpoint } from '../../bus/calls/hooks/useBreakpoint';
import { useDeleteCall } from '../../bus/calls/hooks/useDeleteCall';
import { localeDateFns } from '../../i18n/IntlProviderWrapper';
import ActionColumn from '../CallsList/ActionColumn';
import DateTime from '../CallsList/DateTime';

const { Text } = Typography;

const contentTitleTooltip = (content) => (
    <div className="text-white text-center">
        <p className="block text-white font-semibold">{content.label}</p>
        <p className="block text-white text-sm font-semibold">
            ver {content.version}
        </p>
    </div>
);

const actionIconsClassNames = 'w-7 h-7 fill-current';

const DashboardTable = ({
    startedCalls,
    isFetching,
    allCalls,
    userPermissions,
}) => {
    const breakPoint = useBreakpoint();
    const token = cookie.get(MOBILIZER_TOKEN);
    const { isFetchingEndCall } = useEndCall();
    const { deleteCall, errorDeleteCall, isFetchingDeleteCall } = useDeleteCall();

    const { locale } = useIntl();
    const calcDuration = (s) => formatDistance(0, s * 1000, { locale: localeDateFns(locale) });

    const getMaximumParticipatsToShow = () => {
        if (breakPoint <= 3) return 1;
        if (breakPoint <= 5) return 2;
        if (breakPoint <= 6) return 3;
        return 4;
    };
    const renderContacts = (contacts) => {
        const maxParticipants = getMaximumParticipatsToShow(breakPoint);
        return contacts && contacts.length ? (
            <>
                {contacts.slice(0, maxParticipants).map((contact, index) => (
                    <Text key={index} className={styles.contact}>
                        <span style={{ whiteSpace: 'nowrap' }}>{contact}</span>
                    </Text>
                ))}{' '}
                {contacts.length > maxParticipants ? (
                    <Badge
                        size="small"
                        count={contacts.length}
                        overflowCount={contacts.length - maxParticipants}
                        style={{ color: '#999', backgroundColor: '#fff', boxShadow: '0 0 0 1px #d9d9d9 inset' }}
                    />
                ) : (
                    ''
                )}
            </>
        ) : (
            <></>
        );
    };

    const columns = [
        {
            title: '',
            width: 50,
            render: () => <RemoteCallIcon className={`${actionIconsClassNames} text-primary`} />,
        },
        {
            title: <FormattedMessage id="app.date" />,
            width: 180,
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp) => <DateTime {...{ timestamp }} />,
        },
        {
            title: <FormattedMessage id="app.duration" />,
            width: 180,
            dataIndex: 'duration',
            key: 'duration',
            render: (duration) => (
                <>
                    <Text style={{ whiteSpace: 'nowrap' }} className="text-sm">
                        {duration}
                    </Text>
                </>
            ),
        },
        {
            title: <FormattedMessage id="app.participants" />,
            dataIndex: 'contacts',
            key: 'contacts',
            render: (contacts) => (
                <span>{renderContacts(contacts)}</span>
            ),
        },
        {
            title: <FormattedMessage id="app.content" />,
            dataIndex: 'content',
            key: 'content',
            render: (contents) => (
                <div className="flex flex-start flex-wrap">
                    {contents.map((content) => (
                        <Tooltip
                            key={content.value}
                            className="m-1"
                            title={contentTitleTooltip(content)}
                        >
                            <Text>
                                <ContentThumbnail
                                    src={content.thumbnail}
                                    version={content.version}
                                    responsive
                                />
                            </Text>
                        </Tooltip>
                    ))}
                </div>
            ),
        },
        {
            title: (
                <span className="mr-4">
                    <FormattedMessage id="app.actions" />
                </span>
            ),
            align: 'right',
            render: (text, record) => <ActionColumn {...{ text, record, userPermissions, handleDelete: () => deleteCall(record.call) }} />,
        },
    ];

    const getPresentersJoin = (call) => {
        const presentersJoin = (call && call.joins).find((join) => join.type === 'presenter') || {};
        return presentersJoin.id;
    };

    const errorDeleteCallJSX = errorDeleteCall && (
        <Alert
            message={<FormattedMessage id="error.deleteCall" />}
            className="mb-1"
            type="error"
        />
    );

    function normalizeCall(calls) {
        return calls.map((call) => ({
            key: call.id,
            timestamp: call.timestamp,
            duration: calcDuration(call.duration),
            call,
            contacts:
        call
        && call.contacts
        && call.contacts.map(
            (contact) => `${contact.firstName} ${contact.lastName}`,
        ),
            content: call && call.contents && getContentNormalized(call.contents),
            meetNowLink: call.meet_now_url, // mapping meet_now_url
            presentersUrl: `${process.env.REACT_APP_REMOTE}/join/${getPresentersJoin(
                call,
            )}?token=${token}&client=remote-web`,
        }));
    }
    const normalizedAllCalls = !isFetching ? normalizeCall(allCalls) : [];
    const normalizedStartedCalls = !isFetching ? normalizeCall(startedCalls) : [];
    const normalizedData = isFetching
        ? []
        : [
            ...normalizedStartedCalls,
            ...normalizedAllCalls,
        ];
    return (
        <div>
            {errorDeleteCallJSX}

            <Table
                role="table-call"
                scroll={{ x: 600, y: 300 }}
                loading={isFetching || isFetchingEndCall || isFetchingDeleteCall}
                dataSource={normalizedData}
                columns={columns}
                size="small"
                pagination={false}
            />
        </div>
    );
};

export default DashboardTable;

DashboardTable.defaultProps = {
    startedCalls: [],
    isFetching: false,
    allCalls: [],
};

DashboardTable.propTypes = {
    startedCalls: PropTypeArray,
    isFetching: PropTypeBoolean,
    allCalls: PropTypeArrayOfObject,
    userPermissions: PropTypeArray,
};
