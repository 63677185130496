import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from '../actions';

export const useCallFieldsLoader = (callId) => {
    const dispatch = useDispatch();
    const callFields = useSelector((state) => state.calls.get('callFields'));

    useEffect(() => {
        if (!callFields) {
            dispatch(callsActions.getCallFieldsAsync());
        }
    }, [dispatch, callFields, callId]);

    const isFetching = useSelector((state) => state.calls.get('isFetchingGetCallFields'));
    const error = useSelector((state) => state.calls.get('errorGetCallFields'));

    return {
        callFields,
        isFetching,
        error,
    };
};
