/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable max-len */
const getFileExtension = (str) => str?.split('.').pop();

export const SUPORTED_PDFTRON_EXTENSIONS = ['doc', 'docx', 'ppt', 'pptx', 'pdf'];

export const isFileExtensionSupported = (fileName) => SUPORTED_PDFTRON_EXTENSIONS.includes(getFileExtension(fileName));

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
