/* eslint-disable max-len */
import { useSelector } from 'react-redux';
import cookie from 'js-cookie';
import { MOBILIZER_TOKEN } from '../../../config/constants';

const getPresentersJoin = (call) => {
    const presentersJoin = (call && call.joins).find((join) => join.type === 'presenter') || {};
    return presentersJoin.id;
};

export const useStartedCalls = () => {
    const token = cookie.get(MOBILIZER_TOKEN);
    const startedCalls = useSelector((state) => state.calls.getIn(['startedCalls', 'entities']));
    return {
        startedCalls: startedCalls ? startedCalls.data.map((call) => ({
            key: call.id,
            timestamp: call.timestamp,
            modifiedAt: parseInt(call.modificationStamp.split('time_').pop(), 10),
            call,
            meetNowLink: call.meet_now_url, // mapping meet_now_url
            presentersUrl: `${process.env.REACT_APP_REMOTE}/join/${getPresentersJoin(call)}?token=${token}&client=remote-web`,
        })) : [],
    };
};
