/**
 * This hook detects the current media query breakpoint
 * */
import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

const getDeviceSize = (width) => {
    /* screen sizes are taken from Ant design standard Layout breakpoints */
    if (width < 480) {
        return 1; // xs
    } if (width >= 480 && width < 576) {
        return 2; // sm
    } if (width >= 576 && width < 768) {
        return 3; // md
    } if (width >= 768 && width < 992) {
        return 4; // lg
    } if (width >= 992 && width < 1200) {
        return 5; // xl
    } if (width >= 1200 && width < 1600) {
        return 6; // xxl
    } if (width >= 1600) {
        return 6;
    }
    return 6;
};

export const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceSize(window.innerWidth));

    useEffect(() => {
        const handleResize = throttle(() => {
            setBrkPnt(getDeviceSize(window.innerWidth));
        }, 200);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return brkPnt;
};
