import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { callsActions } from '../actions';

export const useFiltersContentLoader = () => {
    const dispatch = useDispatch();
    const filtersContent = useSelector((state) => state.calls.getIn(['filters', 'content', 'data']));

    useEffect(() => {
        if (!filtersContent.length) {
            dispatch(callsActions.getFiltersContentAsync());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const isFetchingFilters = useSelector((state) => state.calls.getIn(['filters', 'content', 'isFetching']));
    const error = useSelector((state) => state.calls.getIn(['filters', 'content', 'error']));

    return {
        filtersContent,
        isFetchingFiltersContent: isFetchingFilters,
        error,
    };
};
