import React from 'react';
import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as EmailIcon } from '../../ag-icons/Rainmaker_soft_icon_64_Email.svg';
import { ReactComponent as CallIcon } from '../../ag-icons/Rainmaker_soft_icon_53_Phone.svg';
import { SMS, EMAIL } from '../../config/constants';
import { PropTypeFunc, PropTypeString } from '../../helpers/propTypes';

const ProvisionTypeStep = ({ type, setType }) => {
    const commonStyle = (t) => `${type === t ? 'text-bgSecondary' : 'text-gray-500'} fill-current`;
    return (
        <div className="flex justify-evenly items-center h-full">
            <Card
                data-testid="email-card"
                onClick={() => {
                    if (type === EMAIL) {
                        setType('');
                        return;
                    }
                    setType(EMAIL);
                }}
                hoverable
                style={{ width: 160, height: 160 * 1.2 }}
                cover={<EmailIcon className={commonStyle(EMAIL)} style={{}} />}
            >
                <div className={`${commonStyle(EMAIL)} -mt-5 text-center text-base`}> <FormattedMessage id="app.email" /> </div>
            </Card>
            <Card
                data-testid="sms-card"
                hoverable
                onClick={() => {
                    if (type === SMS) {
                        setType('');
                        return;
                    }
                    setType(SMS);
                }}
                style={{ width: 160, height: 160 * 1.2 }}
                cover={<CallIcon className={commonStyle(SMS)} />}
            >
                <div className={`${commonStyle(SMS)} -mt-5 text-center text-base`}> <FormattedMessage id="app.provision.sms" /> </div>
            </Card>
        </div>
    );
};

export default ProvisionTypeStep;

ProvisionTypeStep.propTypes = {
    type: PropTypeString,
    setType: PropTypeFunc,
};
