import React from 'react';
import ReactDOM from 'react-dom';
import './assets/main.css';
import { Provider } from 'react-redux';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { store } from './init/store';
import './index.less';
import 'react-datepicker/dist/react-datepicker.css';
// import 'antd/dist/antd.css';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root'),
);

serviceWorker.unregister();
