import React from 'react';
import PropTypes from 'prop-types';
import ProvisionList from './ProvisionList';

export function PresentationContentTypeShareDetail({
    shareables = [],
    selectedShareablesId,
    onShareablesChange,
}) {
    const topics = shareables.filter((s) => s.type === 'storyboard');
    const chapters = shareables.filter((s) => s.type === 'structure');
    const slides = shareables.filter((s) => s.type === 'slide');

    return (
        <>
            {topics?.length ? (
                <ProvisionList
                    dataSource={topics}
                    selectedShareablesId={selectedShareablesId}
                    onShareablesChange={onShareablesChange}
                    id="app.provision.topics"
                />
            ) : null}

            {chapters?.length ? (
                <ProvisionList
                    dataSource={chapters}
                    selectedShareablesId={selectedShareablesId}
                    onShareablesChange={onShareablesChange}
                    id="app.provision.chapters"
                />
            ) : null}
            {slides?.length ? (
                <ProvisionList
                    dataSource={slides}
                    selectedShareablesId={selectedShareablesId}
                    onShareablesChange={onShareablesChange}
                    id="app.provision.slides"
                />
            ) : null}
        </>
    );
}

PresentationContentTypeShareDetail.propTypes = {
    shareables: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedShareablesId: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ).isRequired,
    onShareablesChange: PropTypes.func.isRequired,
};
