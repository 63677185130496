/* eslint-disable max-len */
import axios from 'axios';
import cookie from 'js-cookie';
import { API_URL, CONDUCTED_CALL_STATUS_FILTER, MOBILIZER_TOKEN, PLANNED_CALL_STATUS_FILTER, SOURCE_REMOTE, POST_CALL_PENDING_CALL_STATUS_FILTER, STARTED_CALL_STATUS_FILTER } from './constants';
import { buildUrl } from '../helpers';

const getLastItemAsContentId = (thePath) => thePath.substring(thePath.lastIndexOf('/') + 1);

const getParamsListLoader = (limit, page, searchTerm, filters, exactFiltersIds) => {
    const params = {};
    if (limit) { params.limit = limit; }
    if (page) { params.offset = (page - 1) * limit; }
    if (searchTerm) { params.search = searchTerm; }
    if (filters && filters.length) { params.filters = filters; }
    if (exactFiltersIds && exactFiltersIds.length) {
        params.filter_exactly = exactFiltersIds;
    }
    return params;
};

const getCallParamsLoader = (pageSize, page, callStatus, sort, order, fromDate, toDate) => {
    const params = {
        'status[in]': callStatus,
        'deletedAt[exists]': false, // filters out deleted calls
        ...(fromDate ? { 'modifiedAt[gte]': fromDate } : {}),
        ...(toDate ? { 'modifiedAt[lte]': toDate } : {}),
    };

    if (pageSize) { params.per_page = pageSize; }
    if (page) { params.page = page; }
    if (sort) { params.sort_by = sort; }
    if (order) { params.order = order; }

    return params;
};

export const api = {
    get token() {
        return cookie.get(MOBILIZER_TOKEN);
    },
    get headers() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Mobilizer-Token': cookie.get(MOBILIZER_TOKEN),
        };
    },

    staff: {
        getUserData() {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/user/me`,
                headers: { ...this.headers, source: SOURCE_REMOTE },
            });
        },

        setLocale(data) {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/user/locale`,
                headers: this.headers,
                data,
            });
        },
    },

    calls: {
        getCalls({ pageSize, page, callStatus, sort, order }) {
            const defaultCallStatus = callStatus || 'created';
            const url = buildUrl(`${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call`,
                getCallParamsLoader(pageSize, page, defaultCallStatus, sort, order));
            return axios({ method: 'get', url, headers: this.headers });
        },

        createCall(callId, params) {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call`,
                headers: this.headers,
                data: params,
            });
        },

        editCall(callId, params) {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call/${callId}`,
                headers: this.headers,
                data: params,
            });
        },

        deleteCall({ callId, data }) {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call/${callId}`,
                headers: this.headers,
                data,
            });
        },

        getContacts({ limit, page, searchTerm, filters, exactFiltersIds }) {
            const url = buildUrl(`${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/contact`,
                getParamsListLoader(limit, page, searchTerm, filters, exactFiltersIds));
            return axios({ method: 'get', url, headers: this.headers });
        },

        getContent({ limit, page, searchTerm, filters, exactFiltersIds }) {
            const url = buildUrl(`${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/content`,
                getParamsListLoader(limit, page, searchTerm, filters, exactFiltersIds));
            return axios({ method: 'get', url, headers: this.headers });
        },
        getContentPreviewUrl(contentId) {
            const url = `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/content/${contentId}`;
            return axios({
                method: 'get',
                url,
                headers: this.headers,
            });
        },

        sendInvitation(callId, invitationData) {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call/${callId}/invitation`,
                headers: this.headers,
                data: invitationData,
            });
        },

        getPostCallFields() {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/call-field`,
                headers: this.headers,
            });
        },

        submitCallFields({ data }) {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/analytics.json`,
                headers: this.headers,
                data,
            });
        },

        getFiltersContact() {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/contact/filters`,
                headers: this.headers,
            });
        },

        getFiltersContent() {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/content/filters`,
                headers: this.headers,
            });
        },

        getInvitationEmail({ data, group }) {
            let urlGroup = 'modification';

            if (group === 'modified') { urlGroup = 'modification'; }
            if (group === 'added') { urlGroup = 'new_invitation'; }
            if (group === 'removed') { urlGroup = 'user_cancellation'; }

            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call/templates/${urlGroup}`,
                headers: this.headers,
                data,
            });
        },
        /**
         * use to end the started call
         * @param {string} callId id of call that you want to end it
         * @returns {Promise}
         */
        endCall({ callId }) {
            return axios({
                method: 'GET',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call/${callId}/end`,
                headers: this.headers,
            });
        },
        getStartedCalls() {
            const url = buildUrl(
                `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call`,
                getCallParamsLoader(null, null, STARTED_CALL_STATUS_FILTER, null, null),
            );
            return axios({ method: 'get', url, headers: this.headers });
        },

        async getSingleContent(contentId) {
            const url = `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/content/${contentId}`;
            try {
                const res = await axios.get(url, { headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-Mobilizer-Token': cookie.get(MOBILIZER_TOKEN),
                } });
                const path = res.config.url;
                return { id: getLastItemAsContentId(path) };
            } catch (err) {
                const path = err.config.url;
                return { id: getLastItemAsContentId(path), error: err.response.status };
            }
        },
        conductedCalls: {
            getConductedCalls({ pageSize, page, callStatus, sort, order }) {
                const defaultCallStatus = callStatus || 'finished';
                const url = buildUrl(`${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call`,
                    getCallParamsLoader(pageSize, page, defaultCallStatus, sort, order));
                return axios({ method: 'get', url, headers: this.headers });
            },
        },

        dashboardCalls: {
            getDashboardCalls({ fromDate, toDate }) {
                const callStatus = `${CONDUCTED_CALL_STATUS_FILTER},${PLANNED_CALL_STATUS_FILTER}`;
                const url = buildUrl(`${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call`,
                    getCallParamsLoader(null, null, callStatus, 'timestamp', 'desc', fromDate, toDate));
                return axios({ method: 'get', url, headers: this.headers });
            },
            async getDashboardTableCalls({ pageSize, page, sortBy, orderBy }) {
                const url = (status) => buildUrl(`${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/remote/call`,
                    getCallParamsLoader(pageSize, page, status, sortBy, orderBy));
                const allCalls = await Promise.all([axios({ method: 'get', url: url(POST_CALL_PENDING_CALL_STATUS_FILTER), headers: this.headers }),
                    axios({ method: 'get', url: url(PLANNED_CALL_STATUS_FILTER), headers: this.headers })]);
                const [a, b] = allCalls;
                const pendingCalls = a.data?.data || [];
                const plannedCalls = b.data?.data || [];
                return { data: { data: [...pendingCalls, ...plannedCalls] } };
            },
        },
    },
    /**
     * get templates messaging
     */
    messagingTemplates: {
        getMessagingTemplates() {
            return axios({
                method: 'GET',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/messaging-template?types=provisioning`,
                headers: { source: SOURCE_REMOTE, ...this.headers },
            });
        },
    },
    provision: {
        sendProvision(data) {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_MOBILIZER_API}/${API_URL}/provision/extended`,
                headers: this.headers,
                data,
            });
        },
    },
};
