/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Modal, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { CLIENT_INFO, INTERACTION_COMPLETED, MESSAGE_KEYS, AGNITIOLIBVERSION } from '../../../config/constants';
import { uuidv4 } from '../../../helpers/handlers';

const { confirm } = Modal;

export const Iframe = ({ field, openIframeModal, setFieldValue, setIFrameModalData, setOpenIframeModal }) => {
    const { name, id, options: { waitForCompletion, url } } = field;
    let externalOrigin;
    try {
        externalOrigin = new URL(url).origin;
    } catch (e) {
        throw new Error('Invalid URL');
    }
    const intl = useIntl();
    const [startTime, setStartTime] = useState(null);
    const iframeRef = useRef(null);
    const communicationId = useRef(null);
    const [isCompleted, setIsCompleted] = useState(!waitForCompletion);
    const [errors, setErrors] = useState({ loadError: false, communicationError: false });

    function onLoad() {
        try {
            setStartTime(Date.now());
            if (waitForCompletion === false) return;
            communicationId.current = uuidv4();
            const clientInfo = { platform: CLIENT_INFO.PLATFORM, applicationFamily: CLIENT_INFO.APPLICATION_FAMILY };
            iframeRef.current.contentWindow.postMessage({ messageKey: MESSAGE_KEYS.INIT_COMMUNICATION, communicationId: communicationId.current, clientInfo,
            }, externalOrigin);
        } catch (e) {
            setErrors({ ...errors, loadError: true });
        }
    }

    const handleIframeClose = () => {
        if (isCompleted) {
            const autoCompleted = waitForCompletion && isCompleted;
            setFieldValue(id, {
                startTime,
                endTime: Date.now(),
                loadError: errors.loadError,
                url,
                agnitioLibVersion: AGNITIOLIBVERSION,
                autoCompleted,
            });
        }
        setIFrameModalData(null);
        setOpenIframeModal(false);
    };

    useEffect(() => {
        const onMessages = (event) => {
            if (event.origin !== externalOrigin) return;
            const { data, messageKey } = event.data;
            if (messageKey === MESSAGE_KEYS.IS_INITILIZED && communicationId.current !== data) {
                setErrors({ ...errors, communicationError: true });
            }
            if (messageKey === MESSAGE_KEYS.IS_COMPLETED && INTERACTION_COMPLETED.SUCCESSFULL === data) {
                setIsCompleted(true);
            }
        };
        if (waitForCompletion === true) {
            window.addEventListener('message', onMessages);
        }
        return () => {
            window.removeEventListener('message', onMessages);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function endWaitForCompletion() {
        confirm({
            title: intl.formatMessage({ id: 'notification.waitForCompletion' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'app.yes' }),
            okType: 'danger',
            cancelText: intl.formatMessage({ id: 'app.no' }),
            centered: true,
            onOk() {
                handleIframeClose();
            },
        });
    }

    const communicationError = errors.communicationError && <Alert message={<FormattedMessage id="error.communicationError" />} type="error" />;
    const loadError = errors.loadError && <Alert message={<FormattedMessage id="error.onLoadError" />} type="error" />;

    return (
        <Modal
            title={name || ''}
            visible={openIframeModal}
            maskClosable={false}
            confirmLoading={false}
            centered
            onCancel={isCompleted ? () => handleIframeClose() : endWaitForCompletion}
            bodyStyle={{ padding: '20px', height: 'calc(100vh - 240px)', width: '100%', overflowY: 'auto', overflowX: 'auto' }}
            width="calc(100vw - 240px)"
            footer={[
                <Space key="error" className="float-left mb-1">{ loadError || communicationError }</Space>,
                <Button key="submit" type="primary" onClick={isCompleted ? () => handleIframeClose() : endWaitForCompletion}>
                    <FormattedMessage id="app.ok" />
                </Button>,
            ]}
        >

            <iframe
                ref={iframeRef}
                id="callFieldsModal-iframe"
                title="external"
                src={url}
                style={{ height: '100%', border: 'none', width: '100%' }}
                onLoad={onLoad}
            />

        </Modal>

    );
};

Iframe.propTypes = {
    field: PropTypes.shape({
        options: PropTypes.objectOf(PropTypes.any),
        name: PropTypes.string,
        id: PropTypes.string,
    }),
    openIframeModal: PropTypes.bool,
    setOpenIframeModal: PropTypes.func,
    setFieldValue: PropTypes.func,
    setIFrameModalData: PropTypes.func,

};
