import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Typography, Dropdown, Menu, Space } from 'antd';
import { ReactComponent as MicrositesIcon } from '../../ag-icons/Rainmaker_soft_icon_19_Share.svg';
import { ChannelIcon } from '../Content/ChannelIcon';
import { ContentThumbnail } from '../ContentThumbnail';
import { normalizeContentType } from '../../helpers';

const MoreMenu = ({ onMenuClick }) => (
    <Menu>
        <Menu.Item
            key="provision"
            onClick={onMenuClick}
        >
            <Space className="flex flex-row">
                <MicrositesIcon className="w-6 h-6 fill-current text-gray-700" />
                <FormattedMessage id="app.share" />
            </Space>

        </Menu.Item>
    </Menu>
);

const actionButtonProps = {
    type: 'text',
};

export const ContentCard = (props) => {
    const {
        contentId,
        contentName,
        contentType,
        contentThumbnailUrl,
        contentChannels,
        contentVersions,
        isShareable,
        onProvision,
        handleClick,
    } = props;

    const { Text } = Typography;

    const getChannelLabel = (type) => {
        switch (type) {
        case 'face_to_face':
            return 'Face to Face';
        case 'remote':
            return 'Remote';
        case 'microsites':
            return 'Microsites';
        default:
            return '';
        }
    };

    const onMoreMenuClick = ({ key }) => {
        if (key === 'provision' && onProvision) {
            onProvision();
        }
    };

    const version = contentVersions[0].number;

    return (
        <div id={contentId} className="w-40 3xl:w-48 py-4 px-2 mx-0">
            <div className="shadow overflow-hidden">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div className="cursor-pointer" tabIndex={0} role="button" onClick={handleClick} data-testid="content-card">
                    {contentThumbnailUrl && (
                        <ContentThumbnail
                            src={contentThumbnailUrl}
                            version={version}
                            thumbnailClass="w-36 h-36 3xl:w-44 relative"
                        />
                    )}
                </div>
                <div className="px-2 py-3 h-12 flex flex-col justify-around border-t border-gray-100">
                    <Tooltip title={contentName}>
                        <Text className="tracking-tight leading-tight font-semibold truncate">
                            {contentName}
                        </Text>
                    </Tooltip>
                </div>
                <div className="p-2 bg-gray-100 flex justify-between text-xs text-gray-600">
                    <div className="flex justify-start">
                        {contentChannels.map((item, index) => (
                            <Tooltip key={index} title={getChannelLabel(item)}>
                                <span>
                                    <ChannelIcon
                                        channel={item}
                                        key={item}
                                        iconClassName="fill-current inline-block h-4 mr-1"
                                    />
                                </span>
                            </Tooltip>
                        ))}
                    </div>
                    <span className="leading-5" style={{ fontSize: '0.6rem' }}>
                        {normalizeContentType(contentType)}
                    </span>
                    {isShareable ? (
                        <div className="flex items-center h-4">
                            <Dropdown.Button
                                overlay={
                                    <MoreMenu onMenuClick={onMoreMenuClick} />
                                }
                                buttonsRender={([, rightButton]) => [
                                    null,
                                    React.cloneElement(rightButton, {
                                        ...actionButtonProps,
                                        type: 'text',
                                    }),
                                ]}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

ContentCard.propTypes = {
    contentId: PropTypes.string.isRequired,
    contentName: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    contentChannels: PropTypes.arrayOf(PropTypes.string).isRequired,
    contentVersions: PropTypes.arrayOf(PropTypes.object).isRequired,
    contentThumbnailUrl: PropTypes.string,
    isShareable: PropTypes.bool.isRequired,
    onProvision: PropTypes.func,
    handleClick: PropTypes.func.isRequired,

};

MoreMenu.propTypes = {
    onMenuClick: PropTypes.func.isRequired,
};
