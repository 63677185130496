/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Divider, Modal, Row, Space, Steps, TimePicker, Tooltip, Typography } from 'antd';
import { Redirect, useHistory, useParams } from 'react-router';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useEditCallOptions } from '../../bus/calls/hooks/useEditCallOptions';
import { useEditOrCreateCall } from '../../bus/calls/hooks/useEditOrCreateCall';
import styles from './index.module.less';
import { ReactComponent as CalendarMonthIcon } from '../../ag-icons/Rainmaker_soft_icon_36_CalendarMonth.svg';
import { ReactComponent as RemindMinuteIcon } from '../../ag-icons/Rainmaker_soft_icon_30_RemindMinute.svg';
import { callsActions } from '../../bus/calls/actions';
import { useStaff } from '../../bus/staff/hooks/useStaff';
import { useContactsLoader } from '../../bus/calls/hooks/useContactsLoader';
import { useContentLoader } from '../../bus/calls/hooks/useContentLoader';
import { getContactsNormalized, getContentNormalized, normalizeContentType } from '../../helpers';
import { SelectedItems } from '../SelectedItems';
import { DURATION_TIME_FORMAT, USER_PERMISSIONS, CLIENT_NAME } from '../../config/constants';
import { useFiltersContactsLoader } from '../../bus/calls/hooks/useFiltersContactsLoader';
import { ListWithFiltersAndSearch } from '../ListWithFiltersAndSearch';
import { book } from '../../routing/book';
import { SelectedContactsWithEmailPreview } from '../SelectedContactsWithEmailPreview';
import { EmailPreview } from '../EmailPreview';
import { EditOrCreateSubmitButton } from '../EditOrCreateSubmitButton';
import { ContentThumbnail } from '../ContentThumbnail';
import { useFiltersContentLoader } from '../../bus/calls/hooks/useFiltersContentLoader';
import isFutureTime from '../../helpers/time/isFutureTime';
import { getCallContents } from './handlers';

export const EditOrCreateCallModal = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isShowMaxParticipantsWarning, setIsShowMaxParticipantsWarning] = useState(false);
    const [previewEmailContactId, setPreviewEmailContactId] = useState(null);
    const [previewEmailGroup, setPreviewEmailGroup] = useState(null);

    const { callId } = useParams();
    const isNewCall = callId === 'create';

    // It disables NEXT button till to check all call contents are available.
    const [checkAvailableContents, setCheckAvailableContents] = useState(isNewCall);

    const history = useHistory();
    const dispatch = useDispatch();
    const { Text } = Typography;
    const { Step } = Steps;

    const userLocale = useSelector((state) => state.staff.getIn(['userData', 'locale']));
    const paginatedCalls = useSelector((state) => state.calls.getIn(['calls', 'entities']))?.data || [];
    const dashboardPlannedCalls = useSelector((state) => state.dashboard.getIn(['dashboardTableCalls', 'planned']))?.data || [];

    const calls = [...paginatedCalls, ...dashboardPlannedCalls];

    let currentCall = null;
    if (!isNewCall) {
        currentCall = calls.length && calls.find((call) => call.id === callId);
    }
    if (!isNewCall) {
        currentCall = calls.length && calls.find((call) => call.id === callId);
    }
    const { userData, userPermissions } = useStaff();
    const maxParticipants = (((userData.settings) || {}).remoteMaxParticipants) || null;

    /**
     * If the contact contactId is truthy, the previewEmail view is displayed
     * @param contactId is either string or null
     * @param group of contacts: "added", "deleted" or "modified"
     */
    const showEmailPreview = (contactId, group) => {
        setPreviewEmailContactId(contactId);
        setPreviewEmailGroup(group);
    };

    const {
        contacts: contactsAndAccounts,
        selectedContacts,
        setSelectedContacts,
        searchTerm: searchContactsTerm,
        handleSearch: handleSearchContacts,
        currentPage: currentPageContacts,
        handlePageChange: handlePageChangeContacts,
        selectedFilters: selectedFiltersContacts,
        handleFilters: handleFiltersContacts,
        exactFilters: exactFiltersContacts,
        handleExactFilters: handleExactFiltersContacts,
        isFetching: isFetchingContacts,
        error: isErrorGetContacts,
    } = useContactsLoader(false, []);

    const { contacts } = contactsAndAccounts?.data || [];
    const { totalContacts } = contactsAndAccounts?.meta || [];

    const {
        contents,
        selectedContents,
        setSelectedContents,
        searchTerm: searchContentTerm,
        handleSearch: handleSearchContent,
        currentPage: currentPageContent,
        handlePageChange: handlePageChangeContent,
        selectedFilters: selectedFiltersContent,
        handleSelectedFilters: handleSelectedFiltersContent,
        exactFilters: exactFiltersContent,
        handleExactFilters: handleExactFiltersContent,
        isFetching: isFetchingContent,
        error: isErrorGetContent,
    } = useContentLoader(false, []);

    useEffect(() => {
        if (currentCall) {
            setSelectedContacts(currentCall.contacts);
            if (!currentCall.contents) return;
            setSelectedContents(currentCall.contents);
            (async () => {
                const braodCastedContents = await getCallContents(currentCall.contents);
                setCheckAvailableContents(true);
                if (!braodCastedContents.length) {
                    setSelectedContents([]);
                    return;
                }
                setSelectedContents(braodCastedContents);
            })();
        }
    }, [currentCall, setSelectedContacts, setSelectedContents]);

    useEffect(() => {
        if (selectedContacts.length > maxParticipants) {
            setIsShowMaxParticipantsWarning(true);
        } else {
            setIsShowMaxParticipantsWarning(false);
        }
    }, [maxParticipants, selectedContacts]);

    const {
        filtersContacts,
        isFetchingFiltersContacts,
        error: isErrorGetFiltersContact,
    } = useFiltersContactsLoader();

    const {
        filtersContent,
        isFetchingFiltersContent,
        error: isErrorGetFiltersContent,
    } = useFiltersContentLoader();

    const { contentsOptions } = useEditCallOptions((contents || []).data);

    const {
        submitEdit,
        submitNew,
        duration,
        setDuration,
        startTime,
        setStartTime,
        isFetchingEditOrCreate,
        errorEditOrCreateCall,
    } = useEditOrCreateCall(callId, currentCall, isNewCall);

    const back = (e) => {
        if (previewEmailContactId) {
            showEmailPreview(null, null);
        } else {
            e.stopPropagation();
            dispatch(callsActions.clearAllError());
            history.push(book.calls);
        }
    };
    const { total: totalContents } = (contents || []).meta || [];

    const timezoneIanaName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleSubmitEdit = (isNotify) => {
        const contentIds = selectedContents.map((item) => (item.id));
        const contactIds = selectedContacts.map((item) => (item.id));

        const params = {
            contentIds,
            duration,
            startUtcTimestamp: startTime,
            timezoneIanaName,
            organizationId: userData.organizationId,
            accountIds: [],
            contactIds,
            previousModificationStamp: currentCall.modificationStamp,
            client: CLIENT_NAME,
        };
        submitEdit(params, currentCall, isNotify);
    };

    const handleSubmitNew = () => {
        const contentIds = selectedContents.map((item) => (item.id));
        const contactIds = selectedContacts.map((item) => (item.id));
        const params = {
            contentIds,
            duration,
            startUtcTimestamp: startTime,
            timezoneIanaName,
            organizationId: userData.organizationId,
            accountIds: [],
            contactIds,
            client: CLIENT_NAME,
        };
        submitNew(params);
    };

    const isContactSelected = Boolean(selectedContacts.length);
    const isContentSelected = Boolean(selectedContents.length && checkAvailableContents);

    const onChangeStep = (current) => {
        setCurrentStep(current);
    };

    const nextStep = () => setCurrentStep(currentStep + 1);
    const prevStep = () => setCurrentStep(currentStep - 1);

    const onChangeContactPage = (page) => handlePageChangeContacts(page);
    const onChangeContentPage = (page) => handlePageChangeContent(page);

    const handleContactsSearch = (e) => {
        e.persist();
        const { value } = e.target;
        handleSearchContacts(value);
    };

    const handleContentSearch = (e) => {
        e.persist();
        const { value } = e.target;
        handleSearchContent(value);
    };

    const isContactRequired = () => {
        const contactsAccountsRequired = userData
            && userData.settings
            && userData.settings.callRequirements
            && userData.settings.callRequirements.contactsAndAccounts;

        return contactsAccountsRequired === 'contact_or_account_required'
            || contactsAccountsRequired === 'contact_required'
            || contactsAccountsRequired === 'contact_and_account_required';
    };

    function getTitle() {
        if (previewEmailContactId) {
            return <FormattedMessage id="editCreate.emailPreview" />;
        }
        if (isNewCall) {
            return <FormattedMessage id="editCreate.newCall" />;
        }
        return <FormattedMessage id="editCreate.editCall" />;
    }

    const errorContactIsRequiredJSX = isContactRequired() && currentStep === 0 && !isContactSelected
        && <Alert message={<FormattedMessage id="error.contactIsRequired" />} banner />;

    const errorContentIsRequiredJSX = currentStep === 1 && !isContentSelected && checkAvailableContents
        && <Alert message={<FormattedMessage id="error.contentIsRequired" />} banner />;

    const errorEditOrCreateJSX = errorEditOrCreateCall
        && <Alert message={<FormattedMessage id="error.updateCall" />} type="error" closable />;

    const errorNoAccessToContactsJSX = <Alert message={<FormattedMessage id="error.noAccessToContacts" />} banner />;
    const isMaxNumberOfParticipants = () => isShowMaxParticipantsWarning
    && maxParticipants && !isErrorGetContacts && currentStep === 0;

    const notificationMaxNumberOfParticipantsJSX = isMaxNumberOfParticipants()
        && <Alert message={<FormattedMessage id="notification.maxNumberOfParticipants" values={{ maxParticipants }} />} banner />;

    const warningZeroDurationJSX = duration === 0 && <Alert message={<FormattedMessage id="notification.zeroDuration" />} banner />;
    const warningTooLongDurationJSX = duration >= 18000 && <Alert message={<FormattedMessage id="notification.tooLongDuration" />} banner />;

    const getModalFooterJSX = () => {
        if (previewEmailContactId) {
            return (
                <Button onClick={() => back()}>
                    <FormattedMessage id="app.back" />
                </Button>
            );
        }

        const isDisabledSubmit = isFetchingContent || !isContentSelected
            || (isContactRequired() && !isContactSelected);

        return (
            <div className="flex justify-between">

                <Space>
                    {errorContactIsRequiredJSX }
                    {errorContentIsRequiredJSX}
                    {notificationMaxNumberOfParticipantsJSX}

                </Space>
                <div>
                    <Space className="ml-3">
                        {currentStep > 0 && (
                            <Button onClick={() => prevStep()}>
                                <FormattedMessage id="app.previous" />
                            </Button>
                        )}
                        {currentStep < 2 && (
                            <Button
                                disabled={
                                    (isMaxNumberOfParticipants() ? true
                                        : (currentStep === 0 && !isContactSelected)
                                    && (isContactRequired()))
                            || (currentStep === 1 && !isContentSelected)
                                }
                                type="primary"
                                onClick={() => nextStep()}
                            >
                                <FormattedMessage id="app.next" />
                            </Button>
                        )}
                    </Space>
                    {currentStep === 2
                && (
                    <EditOrCreateSubmitButton
                        isNewCall={isNewCall}
                        disabled={isDisabledSubmit || isFetchingEditOrCreate}
                        loading={isFetchingEditOrCreate}
                        handleSubmitNew={handleSubmitNew}
                        handleSubmitEdit={handleSubmitEdit}
                    />
                )}
                </div>
            </div>
        );
    };

    const columnsContacts = [
        {
            title: 'Name',
            render: (text, record) => <Text>{`${record.firstName} ${record.lastName}`}</Text>,
        },
        {
            title: <FormattedMessage id="app.email" />,
            dataIndex: 'email',
        },
        {
            title: <FormattedMessage id="app.mainPhone" />,
            dataIndex: 'mainPhone',
        },
    ];

    const columnsContents = [
        {
            title: <FormattedMessage className="ml-10" id="app.content" />,
            dataIndex: 'thumbnailUrl',
            className: styles.thumbnail,
            render: (prop, record) => {
                const version = (((record || {}).versions || {})[0] || {}).number || null;
                return (
                    <div className="flex items-center">
                        <ContentThumbnail version={version} src={prop} />
                        <span className="ml-5">{record.name}</span>
                    </div>
                );
            },
        },
        {
            title: <FormattedMessage id="app.type" />,
            dataIndex: 'type',
            render: (prop) => <Text>{normalizeContentType(prop)}</Text>,
        },
    ];

    /**
     * Redirect to 404 if call not found by Id.
     * By default currentCall === null. If the call is not found, currentCall becomes undefined  */
    if (currentCall === undefined) {
        return <Redirect to={book.noMatch} />;
    }

    const stepsJSX = (
        <>
            <Steps current={currentStep} onChange={(e) => onChangeStep(e)}>
                <Step title={<FormattedMessage id="editCreate.scheduler" />} className={styles.stepIcon} />
                <Step title={<FormattedMessage id="app.content" />} disabled={isContactRequired() && !isContactSelected} className={styles.stepIcon} />
                <Step title={<FormattedMessage id="app.summary" />} disabled={!isContentSelected} className={styles.stepIcon} />
            </Steps>
            <br />

            {currentStep === 0 && (
                <>
                    <Row>
                        <Col xs={24} lg={12}>
                            <div style={{ width: 'auto' }}>
                                <Text className={styles.label}>
                                    <FormattedMessage id="app.start" />
                                </Text>
                                <CalendarMonthIcon className="float-left inline-block mr-2 w-8 h-8 fill-current text-cool-gray-500" />
                                <ReactDatePicker
                                    onChange={(time) => setStartTime(moment(time).unix())}
                                    selected={moment.unix(startTime).toDate()}
                                    className={styles.datePicker}
                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={15}
                                    timeCaption={<FormattedMessage id="app.time" />}
                                    dateFormat="Pp"
                                    locale={userLocale}
                                    minDate={moment().toDate()}
                                    maxDate={moment().add(5, 'years').toDate()}
                                    filterTime={isFutureTime}
                                />
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <div style={{ marginRight: '12px' }}>
                                <Tooltip title={<FormattedMessage id="app.duration" />}>
                                    <Text className={styles.label}><FormattedMessage id="app.duration" /></Text>
                                    <RemindMinuteIcon className="inline-block mr-2 w-8 h-8 fill-current text-cool-gray-500 align-bottom" />
                                    <TimePicker
                                        popupClassName={styles.durationDropdown}
                                        style={{ minWidth: '200px' }}
                                        format={DURATION_TIME_FORMAT}
                                        allowClear={false}
                                        minuteStep={15}
                                        placeholder={<FormattedMessage id="app.duration" />}
                                        disabledHours={
                                        // eslint-disable-next-line max-len
                                            () => [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
                                        }
                                        hideDisabledOptions
                                        onSelect={(e) => setDuration((e.valueOf() - moment().startOf('day').valueOf()) / 1000)}
                                        onChange={(e) => setDuration((e.valueOf() - moment().startOf('day').valueOf()) / 1000)}
                                        value={moment(moment().startOf('day').valueOf() + moment(duration * 1000))}
                                    />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider orientation="left">
                                <FormattedMessage id="app.contacts" />
                            </Divider>
                            {userPermissions.includes(USER_PERMISSIONS.CONTACTS)
                                ? (
                                    <>
                                        <ListWithFiltersAndSearch
                                            filters={filtersContacts}
                                            errorFilters={isErrorGetFiltersContact && <FormattedMessage id="error.filters" />}
                                            isFetchingFilters={isFetchingFiltersContacts}
                                            selectedFilters={selectedFiltersContacts}
                                            setSelectedFilters={handleFiltersContacts}
                                            exactFilters={exactFiltersContacts}
                                            setExactFilters={handleExactFiltersContacts}
                                            onSearchInputChange={(e) => handleContactsSearch(e)}
                                            searchTerm={searchContactsTerm}
                                            error={isErrorGetContacts && <FormattedMessage id="error.getContacts" />}
                                            loadingTable={isFetchingContacts}
                                            dataSource={contacts}
                                            columns={columnsContacts}
                                            paginationTotalItems={totalContacts}
                                            onChangePage={onChangeContactPage}
                                            currentPage={currentPageContacts}
                                            // eslint-disable-next-line max-len
                                            selectedItemsNormalized={getContactsNormalized(selectedContacts)}
                                            selectedItems={selectedContacts}
                                            setSelectedItems={setSelectedContacts}
                                            tableProps={{ scroll: { y: '35vh' } }}
                                        />
                                        {warningZeroDurationJSX}
                                        {warningTooLongDurationJSX}
                                    </>
                                ) : (errorNoAccessToContactsJSX)}
                        </Col>
                    </Row>
                </>
            )}

            {currentStep === 1 && (
                <ListWithFiltersAndSearch
                    filters={filtersContent}
                    errorFilters={isErrorGetFiltersContent && <FormattedMessage id="error.filters" />}
                    isFetchingFilters={isFetchingFiltersContent}
                    selectedFilters={selectedFiltersContent}
                    setSelectedFilters={handleSelectedFiltersContent}
                    exactFilters={exactFiltersContent}
                    setExactFilters={handleExactFiltersContent}
                    onSearchInputChange={(e) => handleContentSearch(e)}
                    searchTerm={searchContentTerm}
                    error={isErrorGetContent && <FormattedMessage id="error.getContents" />}
                    loadingTable={isFetchingContent || !checkAvailableContents}
                    dataSource={contentsOptions}
                    columns={columnsContents}
                    paginationTotalItems={totalContents}
                    onChangePage={onChangeContentPage}
                    currentPage={currentPageContent}
                    selectedItemsNormalized={checkAvailableContents ? getContentNormalized(selectedContents) : []}
                    selectedItems={checkAvailableContents ? selectedContents : []}
                    setSelectedItems={setSelectedContents}
                    tableProps={{
                        scroll: { y: '45vh' },
                    }}
                />
            )}
            {currentStep === 2 && (
                <>
                    <Row>
                        <Col xs={24} lg={12}>
                            <Divider orientation="left" plain>
                                <FormattedMessage id="app.start" />
                            </Divider>
                            <div className="flex items-center">
                                <CalendarMonthIcon className="float-left inline-block mr-2 w-8 h-8 fill-current text-cool-gray-500" />
                                <ReactDatePicker
                                    onChange={(time) => (setStartTime(moment(time).unix()))}
                                    selected={moment.unix(startTime).toDate()}
                                    className={styles.datePicker}
                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={15}
                                    timeCaption={<FormattedMessage id="app.time" />}
                                    dateFormat="Pp"
                                    locale={userLocale}
                                    minDate={moment().toDate()}
                                    maxDate={moment().add(5, 'years').toDate()}
                                    filterTime={isFutureTime}
                                />
                            </div>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Divider plain>
                                <FormattedMessage id="app.duration" />
                            </Divider>
                            <div className="flex items-center">
                                <RemindMinuteIcon className="inline-block mr-2 w-8 h-8 fill-current text-cool-gray-500" />
                                <Text strong>
                                    {moment.utc(moment.duration(duration, 'seconds').asMilliseconds()).format(DURATION_TIME_FORMAT)}
                                </Text>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" plain>
                            <FormattedMessage id="app.contacts" /> ({selectedContacts.length})
                        </Divider>
                        <SelectedContactsWithEmailPreview
                            currentCall={currentCall}
                            showEmailPreview={showEmailPreview}
                            selectedContacts={selectedContacts}
                            duration={duration}
                            startTime={startTime}
                        />
                        <Divider orientation="left" plain>
                            <FormattedMessage id="app.content" /> ({selectedContents.length})
                        </Divider>
                        <SelectedItems
                            items={getContentNormalized(selectedContents)}
                        />
                    </Row>
                </>
            )}
            {errorEditOrCreateJSX}
        </>
    );

    return (
        <Modal
            title={getTitle()}
            visible
            okButtonProps={{ disabled: isFetchingContent }}
            confirmLoading={isFetchingEditOrCreate}
            onCancel={back}
            width="100%"
            centered
            footer={getModalFooterJSX()}
            bodyStyle={{ height: window.innerHeight * 0.82, overflowY: 'auto', padding: '1rem' }}
            maskClosable={false}
            className={styles.antModal}
        >
            {previewEmailContactId
                ? (
                    <EmailPreview
                        contactId={previewEmailContactId}
                        group={previewEmailGroup}
                        contentIds={selectedContents.map((item) => (item.id))}
                        startUtcTimestamp={startTime}
                        timezoneIanaName={timezoneIanaName}
                        duration={duration}
                    />
                )
                : stepsJSX}
        </Modal>
    );
};
