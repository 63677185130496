import React from 'react';
import * as Yup from 'yup';
import 'yup-phone';
import { Form, Input, Button } from 'antd';
import { useIntl } from 'react-intl';
import { PropTypeArrayOfObject, PropTypeFunc, PropTypeString } from '../../helpers/propTypes';
import { SMS, EMAIL } from '../../config/constants';

export function CustomContactForm({ onSubmit, customContacts, contacts, type }) {
    const [form] = Form.useForm();
    const intl = useIntl();

    const emailSchema = Yup.object().shape({
        input: Yup.string().email(intl.formatMessage({ id: 'warning.emailValidation' })).required('Required'),
    });
    const phoneValidation = Yup.object().shape({
        input: Yup.string().phone(intl.formatMessage({ id: 'warning.phoneValidation' })).required('Required'),
    });
    const handleValidation = (rule, value) => {
        if (!value) return null;
        const isValidEmail = emailSchema.isValidSync({ input: value });
        const isValidNumber = phoneValidation.isValidSync({ input: value });
        if (!isValidEmail && type === EMAIL) {
            return Promise.reject(
                intl.formatMessage({ id: 'warning.emailValidation' }),
            );
        }
        if (!isValidNumber && type === SMS) {
            return Promise.reject(
                intl.formatMessage({ id: 'warning.phoneValidation' }),
            );
        }
        if (isValidEmail && type === EMAIL) {
            if (
                contacts.some((c) => c.email === value)
                || customContacts.some(
                    (c) => c.type === EMAIL && c.value === value,
                )
            ) {
                return Promise.reject(
                    intl.formatMessage({ id: 'error.duplicateEmail' }),
                );
            }
            return Promise.resolve();
        }
        if (isValidNumber && type === SMS) {
            if (
                customContacts.some(
                    (c) => c.type === SMS && c.value === value,
                )
            ) {
                return Promise.reject(
                    intl.formatMessage({ id: 'error.duplicatePhoneNumber' }),
                );
            }
            return Promise.resolve();
        }
        return Promise.resolve();
    };
    const onFinish = ({ input }) => {
        onSubmit(input);
        form.resetFields();
    };
    return (
        <Form
            name="basic"
            initialValues={{ input: '' }}
            onFinish={onFinish}
            form={form}
            layout="inline"
            size="small"
            aria-label="contact-form"
        >
            <Form.Item
                name="input"
                rules={[{ validator: handleValidation }]}
                style={{ minWidth: '350px' }}
            >
                <Input
                    type="text"
                    aria-label="contact-input"
                    placeholder={intl.formatMessage({ id: type === SMS ? 'app.addCellPhoneNumber' : 'app.addEmailAddress' })}
                    style={{ paddingTop: '3.75px', paddingBottom: '3.75px' }}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" size="small" style={{ height: '30px' }}>
                    Add
                </Button>
            </Form.Item>
        </Form>
    );
}

CustomContactForm.propTypes = {
    onSubmit: PropTypeFunc,
    customContacts: PropTypeArrayOfObject,
    contacts: PropTypeArrayOfObject,
    type: PropTypeString,
};
