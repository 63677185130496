import { Map } from 'immutable';
import types from './types';

const initialState = Map({
    userData: null,
    error: null,
    locale: {
        // value: LOCALES.en_US.value,
        isFetching: false,
        error: null,
    },
    isSessionExpired: false,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
    case types.STAFF_FILL_USER_DATA:
        return state.set('userData', action.payload);

    case types.STAFF_AUTH_SET_ERROR:
        return state
            .set('error', { ...action.payload, type: 'client' });

    case types.STAFF_SET_LOCALE_START_FETCHING:
        return state.setIn(['locale', 'isFetching'], true);

    case types.STAFF_SET_LOCALE_STOP_FETCHING:
        return state.setIn(['locale', 'isFetching'], false);

    case types.STAFF_SET_LOCALE_ERROR:
        return state.updateIn(['locale', 'error'], () => ({ ...action.payload }));

    case types.STAFF_SET_SESSION_EXPIRED:
        return state.set('isSessionExpired', true)
            .set('userData', null);

    default:
        return state;
    }
};
