/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import cookie from 'js-cookie';
import { isThisWeek, fromUnixTime, getUnixTime, startOfToday, endOfToday, subWeeks, addWeeks } from 'date-fns';
import { book } from '../routing/book';
import { CALL_SORT_ORDER_ASC, CALL_SORT_ORDER_DESC, MOBILIZER_TOKEN, SOURCE_REMOTE } from '../config/constants';

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const buildUrl = (url, obj) => {
    const str = [];

    Object.keys(obj).map((key) => str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`));

    return `${url}?${str.join('&')}`;
};

export const goToLogin = () => {
    cookie.remove(MOBILIZER_TOKEN);
    const continueUrl = window.location.href.split('?')[0];

    const params = {
        continue: continueUrl,
        source: SOURCE_REMOTE,
    };

    window.location = buildUrl(book.login, params);
    return null;
};

export const logout = async () => {
    cookie.remove(MOBILIZER_TOKEN);
    const continueUrl = window.location.href.split('?')[0];

    const params = {
        'rm-app': true,
        continue: continueUrl,
        source: SOURCE_REMOTE,
    };

    window.location = buildUrl(book.logout, params);
    //   to prevent entering MyLive app after sign out
    await sleep(500);
    window.location.reload();
    return null;
};

export const normalizeContentType = (type) => {
    if (type === 'presentation') {
        return 'HTML';
    }
    if (type === 'content_pdf') {
        return 'PDF';
    }
    if (type === 'content_ppt') {
        return 'PPT';
    }
    if (type === 'content_doc') {
        return 'DOC';
    }
    return type;
};

export const getContactsNormalized = (contacts) => (
    contacts.map((contact) => (
        {
            label: `${contact.firstName} ${contact.lastName}`,
            value: contact.id,
        }
    )));

export const getCurrentWeekPlannedCall = (call) => call.call.status === 'created' && isThisWeek(fromUnixTime(call.timestamp));

export const normalizeSortingOrderName = (order) => {
    switch (order) {
    case 'ascend': return CALL_SORT_ORDER_ASC;
    case 'descend': return CALL_SORT_ORDER_DESC;
    default: return CALL_SORT_ORDER_ASC;
    }
};

export const groupDashboardCalls = (calls) => {
    const startOfTodayTimestamp = getUnixTime(startOfToday());
    const endOfTodayTimestamp = getUnixTime(endOfToday());
    return {
        planned: { meta: calls.meta, calls: calls.data.filter((call) => call.status === 'created' && call.timestamp >= startOfTodayTimestamp) },
        conducted: { meta: calls.meta, calls: calls.data.filter((call) => call.status === 'finished' && call.end_time <= endOfTodayTimestamp) },
    };
};

export const sortChannelByPriority = (channels) => {
    const rest = channels.filter(
        (channel) => !['face_to_face', 'microsites', 'remote'].includes(channel),
    );
    return [
        channels.includes('face_to_face') ? 'face_to_face' : null,
        channels.includes('remote') ? 'remote' : null,
        channels.includes('microsites') ? 'microsites' : null,
        ...rest,
    ].filter(Boolean);
};

/**
 * return position of anchor, anchor is position of first item (top left item) of page in array,
 * the array is all of items from beggining until this page
 * @param {Number} currentPageNumber
 * @param {Number} itemsPerPage
 * @returns {Number} position of item
 */
// eslint-disable-next-line max-len
export const getAnchorPosition = (currentPageNumber, itemsPerPage) => (currentPageNumber - 1) * itemsPerPage + 1;

/**
 * it checks whether when we the items per page is change are they expanded or shrinked
 * @param {Number} prevItemsPerPage
 * @param {Number} nextItemsPerPage
 * @returns {Boolean} true if resize is expanded and false if resize is shrink
 */
export const isResizeExpandedByItemsPerPage = (
    prevItemsPerPage,
    nextItemsPerPage,
) => nextItemsPerPage >= prevItemsPerPage;

/**
 * calculate max items that can be fit in page
 * @param {?Object} container container width and height,
 * usally window.innerWidth and window.innerHeight
 * @param {?Number} container.width width of container usually window.innerWidth
 * @param {?Number} container.height height of container usually window.innerHeight
 * @param {Number} largeScreenBreakPoint break point if in some size the cards size is changed
 * @param {Object} tileM Medium size of tile
 * @param {Number} tileM.width width of medium tile
 * @param {Number} tileM.height height of medium tile
 * @param {Object} tileXL Large size of tile
 * @param {Number} tileXL.width width of large tile
 * @param {Number} tileXL.height height of large tile
 * @returns {Number} number of items per page
 */
export const getMaxItemsPerPage = (
    container = { width: window.innerWidth, height: window.innerHeight },
    largeScreenBreakPoint,
    tileM,
    tileXL,
) => {
    // eslint-disable-next-line max-len
    const { width: tileWidth, height: tileHeight } = container.width > largeScreenBreakPoint ? tileXL : tileM;
    // if width is bigger than 990 means navigatio is visible
    const isLeftNavigationVisible = container.width > 990;
    // 280 is the width and space of left sidebar (left navigation) when it's open and 80
    // is the width and space of navigation when it's closed(hidden).
    const widthWithoutNavigation = isLeftNavigationVisible
        ? container.width - 280
        : container.width - 80;
    const itemsPerRow = Math.floor(widthWithoutNavigation / tileWidth) || 2;
    // subtract 226 because of distance from top of window to cards container
    const itemsPerColumn = Math.abs(Math.floor((container.height - 226) / tileHeight)) || 1;
    return itemsPerRow * itemsPerColumn;
};

/**
 * this function calculate current page number after the page was resized
 * @param {Boolean} isExpanded is page expanded compare to previous state
 * @param {Number} anchor current position of anchor
 * @param {Number} itemsPerPage number of items per page
 * @returns {Number} current page number
 */
export const calculatePageNumber = (isExpanded, anchor, itemsPerPage) => {
    if (isExpanded && anchor >= itemsPerPage) {
        return Math.ceil(anchor / itemsPerPage);
    }
    if (!isExpanded && anchor >= itemsPerPage) {
        return parseInt(
            anchor / itemsPerPage + (anchor % itemsPerPage > 0 ? 1 : 0),
            10,
        );
    }
    return 1;
};

/**
 * this function return a timewindow from previous week until next week from now.
 * @returns
 */
export const getDashboardTwoWeeksWindowTimeFromNowPayload = () => {
    const now = startOfToday();
    const from = subWeeks(now, 1);
    const to = addWeeks(now, 1);

    const payload = {
        fromDate: getUnixTime(from),
        toDate: getUnixTime(to),
    };
    return payload;
};
export const filterContactByProperties = (contacts, properties) => contacts.filter((contact) => properties.some((property) => Boolean(contact[property])));
export const isShareable = (content, messagingTemplates) => {
    const hasSharableChannel = content.channels.some(
        (channel) => channel === 'sharedoc' || channel === 'microsites',
    );
    const hasSharableInVersions = content.versions
        && content.versions[0].shareables
        && content.versions[0].shareables.length > 0;
    const hasMessagingTemplate = messagingTemplates.length > 0;
    return hasSharableChannel && hasSharableInVersions && hasMessagingTemplate;
};

export const getShareableNormalized = (shareables, icon, customContact) => shareables.map((shareable) => ({
    label: shareable.name,
    value: shareable.id,
    icon,
    iconClassNames: 'ml-1',
}));

export const getContentNormalized = (contents) => contents.map((content) => ({
    label: content.name,
    value: content.id,
    thumbnail: content.thumbnailUrl,
    version: content.version_number,
    iconClassNames: 'ml-1',
}));

export const convertCustomContactArrayToTagsArray = (
    array,
    icon,
    iconClassNames,
) => array.map((item) => ({
    label: item.value,
    value: item.value,
    icon,
    iconClassNames,
}));

export const getContactsNormalizedByEmailOrCellPhone = (icon, iconClassNames) => (contacts) => contacts.map((contact) => ({
    label: `${contact.firstName} ${contact.lastName}`,
    value: contact.id,
    icon,
    iconClassNames,
}));
