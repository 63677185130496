import { api } from '../../../../config/api';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import { staffActions } from '../../../staff/actions';

export function* setLocaleWorker({ payload }) {
    const options = {
        fetcher: api.staff.setLocale,
        fetcherParam: payload,
        successAction: staffActions.getUserData,
        // TODO: successAction: staffActions.authenticateAsync instead.
        //  Get the response and put to the store (userData).
        startFetching: staffActions.setLocaleStartFetching,
        stopFetching: staffActions.setLocaleStopFetching,
        setErrorAction: staffActions.setLocaleError,
        successMessage: 'The language was successfully saved',
        schema: null,
    };

    yield makeRequestWorker(options);
}
