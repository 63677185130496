/* eslint-disable max-len */
import { put, select } from 'redux-saga/effects';
import { callsActions } from '../../actions';
import { dashboardActions } from '../../../dashboard/actions';
import { api } from '../../../../config/api';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import history from '../../../../routing/history';
import { book } from '../../../../routing/book';
import { CALL_STATUS_FILTER, CALLS_PER_PAGE } from '../../../../config/constants';
import { normalizeSortingOrderName, getDashboardTwoWeeksWindowTimeFromNowPayload } from '../../../../helpers';

function* loadCalls() {
    history.push(book.calls);
    const { /* page, */ sortBy, order } = yield select((state) => state.calls.getIn(['calls', 'listParams']));
    const dashboardThisWeekListParams = yield select((state) => state.dashboard.getIn(['dashboardTableCalls', 'listParams']));

    yield put(callsActions.getCallsAsync({
        pageSize: CALLS_PER_PAGE,
        page: 1,
        callStatus: CALL_STATUS_FILTER,
        sort: sortBy,
        order: normalizeSortingOrderName(order),
    }));

    yield put(dashboardActions.getDashboardTableCallsAsync({ sortBy: dashboardThisWeekListParams.sortBy, orderBy: normalizeSortingOrderName(dashboardThisWeekListParams.orderBy) }));
    callsActions.getDashboardCallsAsync(getDashboardTwoWeeksWindowTimeFromNowPayload());
}

export function* submitCallFieldsWorker({ payload }) {
    const options = {
        fetcher: api.calls.submitCallFields,
        fetcherParam: payload,
        successAction: callsActions.submitFieldsSuccess,
        onSuccess: () => loadCalls(),
        successMessage: 'The call was successfully submitted',
        startFetching: callsActions.submitFieldsStartFetching,
        stopFetching: callsActions.submitFieldsStopFetching,
        setErrorAction: callsActions.submitFieldsError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
