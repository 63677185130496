import { useDispatch, useSelector } from 'react-redux';
import { uuidv4 } from '../../../helpers/handlers';
import * as provisionActions from '../action';
import { SMS, EMAIL } from '../../../config/constants';

export function useProvision(type) {
    const dispatch = useDispatch();
    const provisionState = useSelector((state) => state.provision);
    const userData = useSelector((state) => state.staff.get('userData'));

    /**
     * this method clear the provision , means it's reset the provision state to initial state
     */
    const clearProvision = () => {
        dispatch(provisionActions.clearProvisioning());
    };
    const isSharableItemSelected = (shareables, item) => shareables.some((s) => s.id === item.id);
    const addOrRemoveProvisionItem = (item) => {
        if (isSharableItemSelected(provisionState.data.shareables, item)) {
            // Remove item from sharebles
            dispatch(provisionActions.removeProvisionShareable(item.id));
        } else {
            dispatch(provisionActions.addProvisionShareable(item));
        }
    };

    const addOrRemoveDefinedProvisionContacts = (contacts) => {
        dispatch(provisionActions.setContacts(contacts));
    };
    const addCustomContact = (contact) => {
        if (contact.includes('@')) {
            dispatch(
                provisionActions.addCustomContact({
                    value: contact,
                    type: EMAIL,
                }),
            );
        } else {
            dispatch(
                provisionActions.addCustomContact({
                    value: contact,
                    type: SMS,
                }),
            );
        }
    };
    const removeCustomContact = (contact) => {
        dispatch(provisionActions.removeCustomContact(contact));
    };

    const changeMessagingTemplate = (template) => {
        dispatch(provisionActions.setProvisionMessagingTemplate(template));
    };
    const provisionContent = () => {
        const contactsByEmail = provisionState.data.contacts
            .filter((c) => Boolean(c.email))
            .map((c) => c.id);
        // eslint-disable-next-line no-unused-vars
        const contactOnlyBySms = provisionState.data.contacts
            .filter((c) => !c.email && Boolean(c.cellPhone))
            .map((c) => c.id);
        const payload = {
            contactIds: contactsByEmail,
            contentId: provisionState.data.content.id,
            from: `${userData.firstName} ${userData.lastName}`,
            emailAddresses:
                provisionState.data.customContacts
                    .filter((contact) => contact.type === EMAIL)
                    .map((c) => c.value) || [],
            phoneNumbers:
                provisionState.data.customContacts
                    .filter((contact) => contact.type === SMS)
                    .map((c) => c.value) || [],
            transport: type === SMS ? SMS : EMAIL,
            templateId: provisionState.data.template.id,
            substitutionData: {},
            shareables: provisionState.data.shareables.map((s) => ({
                id: s.id,
                type: s.type,
                contentId: provisionState.data.content.id,
            })),
            provisionId: uuidv4(),
        };
        dispatch(provisionActions.sendProvision(payload));
    };

    const setContent = (content) => {
        dispatch(provisionActions.setProvisionContent(content));
    };

    return {
        clearProvision,
        addOrRemoveProvisionItem,
        addOrRemoveDefinedProvisionContacts,
        changeMessagingTemplate,
        provisionContent,
        addCustomContact,
        removeCustomContact,
        setContent,
    };
}
