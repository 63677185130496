import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { USER_PERMISSIONS } from '../../config/constants';
import { book } from '../../routing/book';
import remoteLogo from '../../images/Live_Agnitio_Logo_Stacked_White_RGB.svg';
import { ReactComponent as ContentIcon } from '../../ag-icons/Rainmaker_soft_icon_03_Content.svg';
import { ReactComponent as ContactIcon } from '../../ag-icons/Rainmaker_soft_icon_21_Contact.svg';
import { ReactComponent as CallsIcon } from '../../ag-icons/Rainmaker_soft_icon_02_Calls.svg';
import { ReactComponent as HistoryIcon } from '../../ag-icons/Rainmaker_soft_icon_23_History.svg';
import { ReactComponent as RevealerIcon } from '../../ag-icons/Rainmaker_soft_icon_05_Revealer.svg';
import { ReactComponent as DashboardIcon } from '../../ag-icons/Rainmaker_soft_icon_01_Dashboard.svg';

const { Sider } = Layout;

export const SiderContent = (props) => {
    const location = useLocation();

    const { userPermissions } = props;

    const linkProps = {
        className: 'flex justify-start',
    };

    const iconProps = {
        className: 'w-6 mr-2 fill-current self-center',
    };

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            width="inherit"
        >
            <div style={{
                overflow: 'auto',
                height: '100vh',
                position: 'sticky',
                zIndex: 0,
                top: 0,
                left: 0,
            }}
            >
                <div className="flex justify-center items-center">
                    <img
                        src={remoteLogo}
                        className="w-40 p-3 mx-3"
                        alt="Remote Logo"
                        height="83"
                        width="176"
                    />
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[book.calls]}
                    selectedKeys={[location.pathname]}
                    style={{ minWidth: '200px' }}
                >
                    <Menu.Item key={book.dashboard}>
                        <Link to={book.dashboard} {...linkProps}>
                            <DashboardIcon {...iconProps} />
                            <span><FormattedMessage id="app.dashboard" /></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={book.calls}>
                        <Link to={book.calls} {...linkProps}>
                            <CallsIcon {...iconProps} />
                            <span><FormattedMessage id="app.calls" /></span>
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={book.conductedCalls}>
                        <Link to={book.conductedCalls} {...linkProps}>
                            <HistoryIcon {...iconProps} />
                            <span><FormattedMessage id="app.conductedCalls" /></span>
                        </Link>
                    </Menu.Item>
                    {userPermissions.includes(USER_PERMISSIONS.CONTACTS) && (
                        <Menu.Item key={book.contacts}>
                            <Link to={book.contacts} {...linkProps}>
                                <ContactIcon {...iconProps} />
                                <span><FormattedMessage id="app.contacts" /></span>
                            </Link>
                        </Menu.Item>
                    )}
                    <Menu.Item key={book.content}>
                        <Link to={book.content} {...linkProps}>
                            <ContentIcon {...iconProps} />
                            <span><FormattedMessage id="app.content" /></span>
                        </Link>
                    </Menu.Item>
                    {userPermissions.includes(USER_PERMISSIONS.REVEALER) && (
                        <Menu.Item key={book.revealer}>
                            <Link to={book.revealer} {...linkProps}>
                                <RevealerIcon {...iconProps} />
                                <span><FormattedMessage id="app.revealer" /></span>
                            </Link>
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        </Sider>
    );
};

SiderContent.propTypes = {
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};
