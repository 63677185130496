/* eslint-disable max-len */
import React from 'react';
import { Col, Typography, Avatar } from 'antd';
import { FormattedMessage } from 'react-intl';
import { SelectedItems } from '../SelectedItems';
import { getShareableNormalized } from '../../helpers';
import { ReactComponent as ContentIcon } from '../../ag-icons/Rainmaker_soft_icon_03_Content.svg';
import styles from './index.module.less';
import { PropTypeArrayOfObject, PropTypeBoolean, PropTypeString } from '../../helpers/propTypes';

const { Text } = Typography;
function SelectedItemContainer({
    id,
    data,
    items,
    showMessageOnEmptySelection = true,
    showCloseButton = true,
}) {
    return (
        <>
            <Col span={24} className="my-4">
                <Text className={styles.listHeader}>
                    <FormattedMessage id={id} />
                    <Avatar className={styles.mlAvatar} size={20}>{data?.length ? data.length : items?.length}</Avatar>
                </Text>
            </Col>
            <Col span={24}>
                <SelectedItems
                    items={
                        data.length
                            ? getShareableNormalized(data, ContentIcon)
                            : items
                    }
                    showMessageOnEmptySelection={showMessageOnEmptySelection}
                    showCloseButton={showCloseButton}
                />
            </Col>
        </>
    );
}

export default SelectedItemContainer;

SelectedItemContainer.propTypes = {
    id: PropTypeString,
    data: PropTypeArrayOfObject,
    items: PropTypeArrayOfObject,
    showMessageOnEmptySelection: PropTypeBoolean,
    showCloseButton: PropTypeBoolean,
};
