import types from './types';

export const staffActions = Object.freeze({
    getUserData: () => ({
        type: types.STAFF_AUTH_ASYNC,
    }),

    fillUserData: (payload) => ({
        type: types.STAFF_FILL_USER_DATA,
        payload,
    }),

    authError: (error) => ({
        type: types.STAFF_AUTH_SET_ERROR,
        payload: error,
    }),

    setLocaleAsync: (payload) => ({
        type: types.STAFF_SET_LOCALE_ASYNC,
        payload,
    }),

    setLocale: (payload) => ({
        type: types.STAFF_SET_LOCALE,
        payload,
    }),

    setLocaleStartFetching: () => ({
        type: types.STAFF_SET_LOCALE_START_FETCHING,
    }),

    setLocaleStopFetching: () => ({
        type: types.STAFF_SET_LOCALE_STOP_FETCHING,
    }),

    setLocaleError: (payload) => ({
        type: types.STAFF_SET_LOCALE_ERROR,
        payload,
    }),

    setSessionExpired: () => ({
        type: types.STAFF_SET_SESSION_EXPIRED,
    }),
});
