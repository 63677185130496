/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { PropTypeString, PropTypeArray } from '../../helpers/propTypes';
import { getPlannedChartData, getConductedChartData } from '../../helpers/charts';
import { PLANNED_CALL_FILTER, FINISHED_CALL_FILTER } from '../../config/constants';

export const LineChartComponent = ({ name, calls, dataName }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (dataName === FINISHED_CALL_FILTER) {
            setData(getConductedChartData(calls));
        } else if (dataName === PLANNED_CALL_FILTER) {
            setData(getPlannedChartData(calls));
        }
    }, [calls?.length]);

    return (
        <ResponsiveContainer height="100%">
            <LineChart
                data={data}
                margin={{ top: 5, right: 30 }}
            >
                <CartesianGrid color="white" stroke="white" strokeDasharray="1" />
                <XAxis dataKey="name" stroke="white" tick={{ stroke: 'white' }} />
                <YAxis allowDecimals={false} stroke="white" tickCount={10} tick={{ stroke: 'white' }} type="number" domain={[0, 'auto']} />
                <Tooltip wrapperStyle={{ backgroundColor: 'transparent' }} contentStyle={{ backgroundColor: 'transparent', border: 'none', fontWeight: '600' }} />
                <Line name={name} type="linear" dataKey={dataName} stroke="white" strokeWidth={2} activeDot={{ r: 8 }} />
            </LineChart>
        </ResponsiveContainer>
    );
};

LineChartComponent.propTypes = {
    name: PropTypeString,
    calls: PropTypeArray,
    dataName: PropTypeString,
};

LineChartComponent.defaultProps = {
    name: '',
    calls: [],
    dataName: '',
};
