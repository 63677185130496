import React from 'react';
import { Result, Button } from 'antd';
import history from '../../routing/history';
import { book } from '../../routing/book';

export const NoMatch = () => (

    <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={() => history.push(book.calls)}>Back Home</Button>}
    />
);
