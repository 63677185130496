/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Iframe } from './Iframe/index';
import styles from './index.module.less';
import { CONTENT_TYPES } from '../../config/constants';
import { getSupportedFileName } from './handler';

export const ContentViewer = ({ handlePreviewClose,
    contentName = '', isVisible = false,
    contentType = '' }) => {
    const intl = useIntl();
    const contentUrl = useSelector((state) => state.calls.getIn(['content', 'currentPreviewUrl']));

    const contentUrlIsLoaded = useMemo(() => contentUrl !== '', [contentUrl]);
    const files = useSelector((state) => state.calls.getIn(['content', 'files']));

    const contentFileName = useMemo(() => {
        if (!files?.length) return null;
        switch (contentType) {
        case CONTENT_TYPES.PRESENTATION:
            return 'index.html';
        case CONTENT_TYPES.CONTENT_PDF:
        case CONTENT_TYPES.CONTENT_DOC:
        case CONTENT_TYPES.CONTENT_PPT:
            return getSupportedFileName(files);
        default:
            return null;
        }
    }, [files, contentType]);

    const url = `${contentUrl}/${contentFileName}`;

    return (
        <Modal
            title={contentName}
            visible={isVisible}
            maskClosable={false}
            confirmLoading={false}
            onCancel={handlePreviewClose}
            centered
            bodyStyle={{
                height: window.innerHeight * 0.9,
                overflowY: 'auto',
            }}
            className={styles.antModal}
            footer={null}
        >
            {!contentUrlIsLoaded && (
                <Spin
                    tip={intl.formatMessage({ id: 'app.loading' })}
                    spinning={!contentUrlIsLoaded}
                    className={styles.antNestedSpinner}
                />
            )}
            {contentUrl && contentFileName && contentType
            && <div data-testid="look-up"><Iframe url={url} contentType={contentType} /></div> }
        </Modal>
    );
};

ContentViewer.propTypes = {
    handlePreviewClose: PropTypes.func.isRequired,
    contentName: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    contentType: PropTypes.string.isRequired,
};
