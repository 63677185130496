import { all, call } from 'redux-saga/effects';
import { authWatchers } from '../bus/staff/saga/watchers';
import { callsWatchers } from '../bus/calls/saga/watchers';
import { dashboardWatchers } from '../bus/dashboard/saga/watchers';
import { messagingTemplateWatchers } from '../bus/messagingTemplate/saga/watchers';
import { provisionWatchers } from '../bus/provision/saga/watchers';

export function* rootSaga() {
    yield all([
        call(authWatchers),
        call(callsWatchers),
        call(dashboardWatchers),
        call(messagingTemplateWatchers),
        call(provisionWatchers),
    ]);
}
