import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardActions } from '../actions';
import { normalizeSortingOrderName } from '../../../helpers';

export const useDashboard = () => {
    const dispatch = useDispatch();
    const planned = useSelector((state) => state.dashboard.getIn(['dashboardTableCalls', 'planned']));
    const postCallPending = useSelector((state) => state.dashboard.getIn(['dashboardTableCalls', 'postCallPending']));
    const tableCallsIsFetching = useSelector((state) => state.dashboard.getIn(['dashboardTableCalls', 'isFetching']));
    const tableCallsError = useSelector((state) => state.dashboard.getIn(['dashboardTableCalls', 'error']));
    const listParams = useSelector((state) => state.dashboard.getIn(['dashboardTableCalls', 'listParams']));
    const entities = useSelector((state) => state.calls.getIn(['startedCalls', 'entities']));
    const startedCalls = entities?.data || [];
    const tableCalls = [...postCallPending, ...startedCalls, ...planned].slice(0, 10);

    const loadCalls = ({ sortBy, orderBy }) => {
        dispatch(
            dashboardActions.getDashboardTableCallsAsync({
                sortBy,
                orderBy: normalizeSortingOrderName(orderBy),
            }),
        );
    };

    /* initial load */
    useEffect(() => {
        loadCalls({ sortBy: 'timestamp', orderBy: 'descend' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return {
        tableCallsError,
        tableCallsIsFetching,
        listParams,
        tableCalls,
    };
};
