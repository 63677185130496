import { useEffect, useState } from 'react';

export const useStickyState = (defaultValue, key) => {
    const [value, setValue] = useState(() => {
        if (key) {
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
        }
        return null;
    });

    useEffect(() => {
        if (key) {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    }, [key, value]);
    return [value, setValue];
};
