export const book = Object.freeze({
    login: `${process.env.REACT_APP_LOGIN_APP}/login`,
    logout: `${process.env.REACT_APP_LOGIN_APP}/logout`,
    calls: '/calls',
    conductedCalls: '/conducted-calls',
    contacts: '/contacts',
    content: '/content',
    createCall: '/calls/create',
    revealer: '/revealer',
    noMatch: '/404',
    blank: '/blank',
    dashboard: '/dashboard',
});
