import { combineReducers } from 'redux';
import staff from '../bus/staff/reducer';
import calls from '../bus/calls/reducer';
import dashboard from '../bus/dashboard/reducer';
import messagingTemplate from '../bus/messagingTemplate/reducer';
import provision from '../bus/provision/reducer';

export const rootReducer = combineReducers({
    staff,
    calls,
    dashboard,
    messagingTemplate,
    provision,
});
