/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { callsActions } from '../actions';
import {
    DEBOUNCE_TIME,
    DEFAULT_ITEMS_PER_PAGE,
    CONTACT_CARD_SIZING_PROPS,
} from '../../../config/constants';
import {
    isResizeExpandedByItemsPerPage,
    getAnchorPosition,
    getMaxItemsPerPage,
    calculatePageNumber,
} from '../../../helpers';

const getMaxItems = () => getMaxItemsPerPage(
    undefined,
    CONTACT_CARD_SIZING_PROPS.breakPoint,
    CONTACT_CARD_SIZING_PROPS.tileM,
    CONTACT_CARD_SIZING_PROPS.tileXL,
);

/**
 *
 * @param {Boolean} dynamicItemsPerPage if true then it tries to find best number of items per page
 * @returns
 */
export const useContactsLoader = (dynamicItemsPerPage = false, initialSelectedContacts = []) => {
    const initItemsPerPage = dynamicItemsPerPage
        ? getMaxItems()
        : DEFAULT_ITEMS_PER_PAGE;
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedContacts, setSelectedContacts] = useState(initialSelectedContacts);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [exactFilters, setExactFilters] = useState([]);
    const [contactsPerPage, setContactsPerPage] = useState(initItemsPerPage);
    const getSelectedFiltersIds = (filters) => filters.map((filter) => filter.value);
    const contacts = useSelector((state) => state.calls.getIn(['contacts', 'entities']));
    const isFetching = useSelector((state) => state.calls.getIn(['contacts', 'isFetching']));
    const error = useSelector((state) => state.calls.getIn(['contacts', 'error']));

    const loadContacts = (
        page,
        term,
        filters,
        exactFiltersIds,
        limit = contactsPerPage,
    ) => {
        let val = '';
        if (term.length > 1) {
            val = term.toLowerCase();
        }

        dispatch(
            callsActions.getContactsAsync({
                limit,
                searchTerm: val,
                page,
                filters,
                exactFiltersIds,
            }),
        );
    };

    const debounceLoadContacts = useCallback(
        debounce(loadContacts, DEBOUNCE_TIME),
        [],
    );
    const handleResize = () => {
        const newItemsPerPage = getMaxItems();
        if (newItemsPerPage !== contactsPerPage) {
            const currentAnchor = getAnchorPosition(currentPage, contactsPerPage);
            const page = calculatePageNumber(
                isResizeExpandedByItemsPerPage(contactsPerPage, newItemsPerPage),
                currentAnchor,
                newItemsPerPage,
            );
            setContactsPerPage(newItemsPerPage);
            setCurrentPage(page);
            loadContacts(
                page,
                searchTerm,
                getSelectedFiltersIds(selectedFilters),
                exactFilters,
                newItemsPerPage,
            );
        }
    };
    const debouncedHandleResize = useCallback(
        () => {
            debounce(handleResize, DEBOUNCE_TIME);
        },
        [currentPage, setCurrentPage, contactsPerPage, setContactsPerPage],
    );
    /* Initial load */
    useEffect(() => {
        loadContacts(1, searchTerm, getSelectedFiltersIds(selectedFilters), exactFilters);
        // return () => dispatch(callsActions.clearContacts()); /* TODO */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const handlePageChange = (page) => {
        loadContacts(page, searchTerm, getSelectedFiltersIds(selectedFilters), exactFilters);
        setCurrentPage(page);
    };

    const handleSearch = (term) => {
        if (term.length !== 1) {
            setCurrentPage(1);
            debounceLoadContacts(1, term, getSelectedFiltersIds(selectedFilters), exactFilters);
        }
        setSearchTerm(term);
    };

    const handleFilters = (filters) => {
        setCurrentPage(1);
        setSelectedFilters(filters);
        loadContacts(1, searchTerm, getSelectedFiltersIds(filters), exactFilters);
    };

    const handleExactFilters = (exactFltrs) => {
        setCurrentPage(1);
        setExactFilters(exactFltrs);
        loadContacts(1, searchTerm, getSelectedFiltersIds(selectedFilters), exactFltrs);
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (dynamicItemsPerPage) {
            window.addEventListener('resize', debouncedHandleResize);
            return () => {
                window.removeEventListener('resize', debouncedHandleResize);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedHandleResize]);

    return {
        contacts,
        selectedContacts,
        setSelectedContacts,
        currentPage,
        handlePageChange,
        searchTerm,
        handleSearch,
        selectedFilters,
        handleFilters,
        exactFilters,
        handleExactFilters,
        isFetching,
        error,
        contactsPerPage,
    };
};
