import { Dropdown } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.module.less';

const HeaderDropdown = ({ overlayClassName: cls, ...restProps }) => (
    <Dropdown overlayClassName={classNames(styles.container, cls)} {...restProps} />
);

HeaderDropdown.defaultProps = {
    overlayClassName: '',
};

HeaderDropdown.propTypes = {
    overlayClassName: PropTypes.string,
};

export default HeaderDropdown;
