import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from '../actions';

export const useDeleteCall = () => {
    const dispatch = useDispatch();

    const deleteCall = useCallback((call) => {
        const data = {
            previousModificationStamp: call.modificationStamp,
        };

        dispatch(callsActions.deleteCallAsync({
            callId: call.id,
            data,
        }));
    }, [dispatch]);

    const isFetchingDeleteCall = useSelector((state) => state.calls.get('isFetchingDeleteCall'));
    const errorDeleteCall = useSelector((state) => state.calls.get('errorDeleteCall'));

    return {
        deleteCall,
        isFetchingDeleteCall,
        errorDeleteCall,
    };
};
