import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export const Greeting = ({ userData }) => {
    const greetingSuffix = () => (userData && userData.firstName && `, ${userData.firstName}`);

    return (
        <FormattedMessage
            id="app.userGreeting"
            values={{ name: greetingSuffix() }}
        />
    );
};

Greeting.defaultProps = {
    userData: {},
};

Greeting.propTypes = {
    userData: PropTypes.shape({
        firstName: PropTypes.string,
    }),
};
