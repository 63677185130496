/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader, Space, Typography, Button } from 'antd';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { callsActions } from '../../bus/calls/actions';
import styles from './index.module.less';
import { useContentLoader } from '../../bus/calls/hooks/useContentLoader';
import noImage from '../../images/no-image.jpg';
import { useEditCallOptions } from '../../bus/calls/hooks/useEditCallOptions';
import { normalizeContentType } from '../../helpers';
import { ListWithFiltersAndSearch } from '../ListWithFiltersAndSearch';
import EmailIcon from '../../ag-icons/Rainmaker_soft_icon_64_Email.svg';
import { ReactComponent as FaceToFaceIcon } from '../../ag-icons/Rainmaker_soft_icon_24_StandardCall.svg';
import { ReactComponent as RemoteCallIcon } from '../../ag-icons/Rainmaker_soft_icon_25_RemoteCall.svg';
import { ReactComponent as MicrositesIcon } from '../../ag-icons/Rainmaker_soft_icon_19_Share.svg';
import { ContentCard } from '../ContentCard';
import { useFiltersContentLoader } from '../../bus/calls/hooks/useFiltersContentLoader';
import { useMessagingTemplates } from '../../bus/messagingTemplate/hooks/useMessagingTemplates';
import { useProvision } from '../Provision';
import { CONTENT_TYPES } from '../../config/constants';
import { ProvisionModal } from '../Provision/ProvisionModal';
import { ContentItem } from '../ContentItem';
import { ContentViewer } from '../ContentViewer';

export const Contents = () => {
    const { Text } = Typography;
    const [isPreviewVisible, setPreviewVisibility] = useState(false);
    const [currentPreviewName, setPreviewName] = useState('');
    const [contentType, setContentType] = useState('');
    const { setContent } = useProvision();

    const {
        contents,
        currentPage,
        handlePageChange,
        searchTerm,
        handleSearch,
        selectedFilters,
        handleSelectedFilters,
        exactFilters,
        handleExactFilters,
        isFetching: isFetchingContent,
        error: isErrorGetContent,
        contentsPerPage,
    } = useContentLoader(true);
    const dispatch = useDispatch();
    const { isFetching: isFetchingMessagingTemplates, getTemplatesByContentId } = useMessagingTemplates();
    const [provisionModalVisibility, setProvisionModalVisibility] = useState(false);

    const { contentsOptions } = useEditCallOptions((contents || []).data);

    const showPreview = (contentId, contentName, type) => {
        if (!contentId) {
            return;
        }
        setContentType(type);

        dispatch(callsActions.getContentPreview(
            contentId,
        ));
        setPreviewName(contentName);
        setPreviewVisibility(true);
    };

    const closePreview = () => {
        setPreviewVisibility(false);
        dispatch(callsActions.fillContentPreview({ rootUrl: '' }));
    };

    const {
        filtersContent,
        isFetchingFiltersContent,
        error: isErrorGetFiltersContent,
    } = useFiltersContentLoader();

    const { total: totalContent } = (contents || {}).meta || [];
    const onChangeContentPage = (page) => handlePageChange(page);
    const handleContentSearch = (e) => {
        e.persist();
        const { value } = e.target;
        handleSearch(value);
    };

    const iconProps = {
        className: 'w-6 h-6 fill-current text-gray-700',
    };
    function openProvisionModal(content) {
        setContent(content);
        setProvisionModalVisibility(true);
    }

    const isShareable = (content, messagingTemplates) => {
        const hasSharableChannel = content.channels.some(
            (channel) => channel === 'sharedoc' || channel === 'microsites',
        );
        const hasSharableInVersions = content.versions
            && content.versions[0].shareables
            && content.versions[0].shareables.length > 0;
        const hasMessagingTemplate = messagingTemplates.length;
        // currently only html contents have provisioning
        const isHtmlContent = content.type === CONTENT_TYPES.PRESENTATION;
        return (
            hasSharableChannel
            && hasSharableInVersions
            && hasMessagingTemplate
            && isHtmlContent
        );
    };

    const columns = [
        {
            title: <span className="ml-4"><FormattedMessage className="ml-10" id="app.content" /></span>,
            dataIndex: 'name',
            className: styles.thumbnail,
            render: (prop, record) => (
                <ContentItem
                    title={record.name}
                    thumbnailUrl={record.thumbnailUrl}
                    description={(((record || {}).versions || {})[0] || {}).description || null}
                    version={(((record || {}).versions || {})[0] || {}).number || null}
                    handleClick={() => showPreview(record.id, record.name, record.type)}
                />
            ),
        },
        {
            title: <FormattedMessage id="app.type" />,
            dataIndex: 'type',
            render: (prop) => <Text>{normalizeContentType(prop)}</Text>,
        },
        {
            title: <FormattedMessage id="app.channel" />,
            dataIndex: 'channels',
            render: (channels) => {
                const getChannelIcon = (channel) => {
                    switch (channel) {
                    case 'email': return <EmailIcon key="email" {...iconProps} />;
                    case 'face_to_face': return <FaceToFaceIcon key="face_to_face" {...iconProps} />;
                    case 'microsites': return <MicrositesIcon key="microsites" {...iconProps} />;
                        // case 'sharedoc': return MicrositesIcon;
                        // TODO: there should be more conditions for displaying the microsites icon
                        //  (like content has sharebles and provisioning template )
                    case 'remote': return <RemoteCallIcon key="remote" {...iconProps} />;
                    default: return null;
                    }
                };

                return <Space>{channels.map((channel) => getChannelIcon(channel))}</Space>;
            },
        },
        {
            title: <FormattedMessage id="app.published" />,
            dataIndex: 'versions',
            render: (prop) => (
                <>
                    <Text size="sm" strong>
                        <FormattedDate
                            value={prop[0].availableFrom}
                            year="numeric"
                            month="short"
                            day="2-digit"
                        />
                    </Text>
                    <br />
                    <Text style={{ whiteSpace: 'nowrap' }} className={styles.date}>
                        <FormattedTime
                            value={prop[0].availableFrom}
                            hour="numeric"
                            minute="numeric"
                        />
                    </Text>
                </>
            ),
        },
        {
            title: <FormattedMessage id="app.actions" />,
            key: 'actions',
            render: (text, record) => {
                if (isShareable(record, getTemplatesByContentId(record.id))) {
                    return (
                        <Button
                            type="link"
                            onClick={() => openProvisionModal(record)}
                            icon={(
                                <MicrositesIcon
                                    key="microsites"
                                    {...iconProps}
                                />
                            )}
                        />
                    );
                }
                return null;
            },
        },
    ];

    const cardRender = (record) => {
        const shareable = isShareable(
            record,
            getTemplatesByContentId(record.id),
        );
        return (
            <ContentCard
                key={record.id}
                contentId={record.id}
                contentName={record.name}
                contentType={record.type}
                contentThumbnailUrl={record.thumbnailUrl || noImage}
                contentChannels={record.channels}
                contentVersions={record.versions}
                isShareable={shareable}
                handleClick={() => showPreview(record.id, record.name, record.type)}
                onProvision={shareable ? () => openProvisionModal(record) : null}
            />
        );
    };

    return (
        <>
            <PageHeader title={(
                <span className="text-gray-700 tracking-wide">
                    <FormattedMessage id="app.content" />
                </span>
            )}
            />
            <ListWithFiltersAndSearch
                filters={filtersContent}
                errorFilters={isErrorGetFiltersContent && <FormattedMessage id="error.filters" />}
                isFetchingFilters={isFetchingFiltersContent}
                selectedFilters={selectedFilters}
                setSelectedFilters={handleSelectedFilters}
                exactFilters={exactFilters}
                setExactFilters={handleExactFilters}
                onSearchInputChange={(e) => handleContentSearch(e)}
                searchTerm={searchTerm}
                error={isErrorGetContent && <FormattedMessage id="error.getContents" />}
                loadingTable={isFetchingContent || isFetchingMessagingTemplates}
                dataSource={contentsOptions}
                columns={columns}
                paginationTotalItems={totalContent}
                onChangePage={onChangeContentPage}
                currentPage={currentPage}
                isSelectable={false}
                gridView={{
                    cardRender,
                    stateKey: 'content-view-mode',
                    initialViewMode: 'grid',
                }}
                itemsPerPage={contentsPerPage}
            />
            {isPreviewVisible && contentType && (
                <ContentViewer
                    handlePreviewClose={closePreview}
                    contentName={currentPreviewName}
                    isVisible={isPreviewVisible}
                    contentType={contentType}
                />
            )}
            {provisionModalVisibility && (
                <ProvisionModal
                    visible={provisionModalVisibility}
                    onModalClose={() => setProvisionModalVisibility(false)}
                />
            )}
        </>
    );
};
