import React from 'react';
import { CONTENT_TYPES } from '../../config/constants';
import { PropTypeArray, PropTypeFunc, PropTypeObject, PropTypeString } from '../../helpers/propTypes';
import { PresentationContentTypeShareDetail } from './PresentationContentTypeShareDetail';

export function ProvisionContentShareDetailStep({
    content,
    addOrRemoveSharableItem,
    selectedShareablesId,
}) {
    if (!content) {
        return <span />;
    }
    if (content.type === CONTENT_TYPES.PRESENTATION) {
        return (
            <PresentationContentTypeShareDetail
                shareables={content.versions[0].shareables}
                onShareablesChange={addOrRemoveSharableItem}
                selectedShareablesId={selectedShareablesId}
            />
        );
    }
    return <p>There is nothing to select for sharing</p>;
}

ProvisionContentShareDetailStep.propTypes = {
    content: PropTypeObject,
    addOrRemoveSharableItem: PropTypeFunc.isRequired,
    selectedShareablesId: PropTypeArray.isRequired || PropTypeString.isRequired,
};
