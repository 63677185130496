import React from 'react';
import { Row, Col } from 'antd';
import { getContactsNormalizedByEmailOrCellPhone } from '../../helpers';
import { ListOfContacts, ContactSelector, CustomContactForm } from './index';
import { ReactComponent as ContactIcon } from '../../ag-icons/Rainmaker_soft_icon_21_Contact.svg';
import { PropTypeArray, PropTypeFunc, PropTypeString } from '../../helpers/propTypes';

export function ProvisionContactsStep({
    onDefinedContactsChanged,
    preSelectedContacts = [],
    removeCustomContact,
    addCustomContact,
    customContacts,
    type,
}) {
    return (
        <Row>
            <Col span={24} className="mb-5 mt-5">
                <CustomContactForm
                    onSubmit={(contact) => addCustomContact(contact)}
                    contacts={preSelectedContacts}
                    customContacts={customContacts}
                    type={type}
                />
            </Col>
            <Col span={24}>
                <ContactSelector
                    normalizerContactFn={getContactsNormalizedByEmailOrCellPhone(
                        ContactIcon,
                        'w-5 h-5 -mr-2 fill-current flex items-center',
                    )}
                    preSelectedContacts={preSelectedContacts}
                    onContactsChanged={onDefinedContactsChanged}
                    filterContactsBy={['email', 'cellPhone']}
                    type={type}
                />
                {customContacts.length > 0 ? (
                    <ListOfContacts
                        contacts={customContacts}
                        onDelete={(item) => removeCustomContact(item)}
                    />
                ) : null}
            </Col>
        </Row>
    );
}

ProvisionContactsStep.propTypes = {
    onDefinedContactsChanged: PropTypeFunc.isRequired,
    preSelectedContacts: PropTypeArray,
    addCustomContact: PropTypeFunc.isRequired,
    removeCustomContact: PropTypeFunc.isRequired,
    customContacts: PropTypeArray,
    type: PropTypeString,
};
