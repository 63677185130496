import React from 'react';
import { Image } from 'antd';
import noImage from '../../images/no-image.jpg';
import { ReactComponent as ContentIcon } from '../../ag-icons/Rainmaker_soft_icon_03_Content.svg';
import { PropTypeString } from '../../helpers/propTypes';
import styles from './index.module.less';

export function Thumbnail({ thumbnailUrl = '' }) {
    return (
        <div className={styles.thumbnailContainer}>
            {thumbnailUrl ? (
                <Image
                    preview={false}
                    src={thumbnailUrl}
                    fallback={noImage}
                    width="140px"
                    height="100px"
                    className="w-full"
                />
            ) : (
                <ContentIcon
                    className={styles.thumbnailIcon}
                    data-testid="content-icon"
                />
            )}
        </div>
    );
}

Thumbnail.propTypes = {
    thumbnailUrl: PropTypeString,
};
