import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Steps } from 'antd';
import styles from './index.module.less';
import {
    PropTypeBoolean,
    PropTypeFunc,
    PropTypeNumber,
    PropTypeString,
} from '../../helpers/propTypes';

export function ProvisionStepsBar({
    currentStep = 0,
    onChange,
    isContentSectionsDisable = true,
    isSummaryDisable = true,
    type,
}) {
    return (
        <Steps size="small" current={currentStep} onChange={onChange}>
            <Steps.Step
                title={<FormattedMessage id="app.provision.type" />}
                className={styles.stepIcon}
            />
            <Steps.Step
                title={<FormattedMessage id="app.contacts" />}
                disabled={!type}
                className={styles.stepIcon}
            />
            <Steps.Step
                title={<FormattedMessage id="app.share" />}
                disabled={isContentSectionsDisable}
                className={styles.stepIcon}
            />
            <Steps.Step
                title={<FormattedMessage id="app.summary" />}
                disabled={isSummaryDisable}
                className={styles.stepIcon}
            />
        </Steps>
    );
}

ProvisionStepsBar.propTypes = {
    currentStep: PropTypeNumber,
    onChange: PropTypeFunc,
    isContentSectionsDisable: PropTypeBoolean,
    isSummaryDisable: PropTypeBoolean,
    type: PropTypeString,
};
