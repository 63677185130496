/* eslint-disable import/no-anonymous-default-export */
import * as types from './types';

const initialState = {
    data: [],
    isFetching: false,
    isFetched: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
    case types.MESSAGING_TEMPLATES_START_FETCHING:
        return {
            ...initialState,
            isFetching: true,
        };
    case types.MESSAGING_TEMPLATES_SET_ERROR:
        return {
            ...initialState,
            error: action.payload,
        };
    case types.MESSAGING_TEMPLATES_SET:
        return {
            ...state,
            data: action.payload,
            isFetching: false,
            isFetched: true,
            error: null,
        };
    case types.MESSAGING_TEMPLATES_CLEAR:
        return initialState;
    default:
        return state;
    }
};
