/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spin } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { callsActions } from '../../bus/calls/actions';

export const EmailPreview = (props) => {
    const { contactId, group, contentIds, startUtcTimestamp, timezoneIanaName, duration } = props;

    const dispatch = useDispatch();

    const data = {
        contactIds: [contactId],
        contentIds,
        startUtcTimestamp,
        timezoneIanaName,
        duration,
    };

    useEffect(() => {
        dispatch(callsActions.getInvitationEmailAsync({ data, group }));
        return () => {
            dispatch(callsActions.getInvitationEmailClear());
        };
    }, [dispatch]);

    const {
        entities: invitationEmail,
        error: invitationEmailError,
        isFetching: invitationEmailIsFetching,
    } = useSelector((state) => state.calls.get('invitationEmail'));

    const emailHtmlCleaned = invitationEmail && invitationEmail[0].body.replace(/\\n/gi, '').replace(/\\/gi, '').replace(/>[\t ]+</g, '><');
    const errorInvitationEmailJSX = invitationEmailError && (
        <Alert message={<FormattedMessage id="error.invitationEmail" />} type="error" className="mb-1" />
    );
    return (
        <Spin spinning={invitationEmailIsFetching} data-testid="email-preview-spinner">
            {errorInvitationEmailJSX}
            <iframe className="container" style={{ height: '90vh', maxHeight: '800px' }} data-testid="email-preview-iframe" title="email-preview" srcDoc={emailHtmlCleaned} />
        </Spin>
    );
};

EmailPreview.propTypes = {
    contactId: PropTypes.string.isRequired,
    group: PropTypes.oneOf(['existing', 'added', 'removed']).isRequired,
    contentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    startUtcTimestamp: PropTypes.number.isRequired,
    timezoneIanaName: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
};
