import React from 'react';
import { Col, Row, Tag, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { MailOutlined } from '@ant-design/icons';
import { SpinnerCentered } from '../../elements/SpinnerCentered';
import styles from './index.module.less';

export const SelectedContactsWithEmailPreview = (props) => {
    const { loading, showEmailPreview, currentCall, selectedContacts, duration, startTime } = props;

    const resultContacts = [];
    let isModified = false;

    if (currentCall) {
        const added = selectedContacts.filter(
            (o) => !currentCall.contacts.find((o2) => o.id === o2.id),
        );
        const removed = currentCall.contacts.filter(
            (o) => !selectedContacts.find((o2) => o.id === o2.id),
        );
        const existing = currentCall.contacts.filter(
            (o) => !removed.find((o2) => o.id === o2.id),
        );

        isModified = !(currentCall.duration === duration)
            || !(currentCall.startUtcTimestamp === startTime);

        if (existing.length) {
            resultContacts.push({
                value: 'existing',
                label: <FormattedMessage id="editCreate.existing" />,
                items: existing,
            });
        }
        if (added.length) {
            resultContacts.push({
                value: 'added',
                label: <FormattedMessage id="editCreate.added" />,
                items: added,
            });
        }
        if (removed.length) {
            resultContacts.push({
                value: 'removed',
                label: <FormattedMessage id="editCreate.removed" />,
                items: removed,
            });
        }
    } else if (selectedContacts.length) {
        resultContacts.push(({
            value: 'added',
            label: <FormattedMessage id="editCreate.added" />,
            items: selectedContacts,
        }));
    }
    if (resultContacts.length === 0) { return <FormattedMessage id="app.noneSelected" />; }

    if (loading) { return <SpinnerCentered />; }

    return (
        <div className={styles.container}>
            <Row>
                {resultContacts.map((group) => (
                    <Col flex={1} key={group.value}>
                        <h4>{group.label}</h4>
                        {group.items.map((item) => (
                            <div className={styles.group} key={item.id}>
                                <Tag
                                    key={item.id}
                                    style={{ marginBottom: '5px' }}
                                >
                                    {`${item.firstName} ${item.lastName}`}
                                </Tag>

                                {!isModified && group.value === 'existing'
                                    ? null
                                    : (
                                        <Tooltip title={<FormattedMessage id="editCreate.tooltip.previewTheEmail" />}>
                                            <MailOutlined
                                                onClick={() => {
                                                    showEmailPreview(item.id, group.value);
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                            </div>
                        ))}
                    </Col>
                ))}
            </Row>
        </div>
    );
};

SelectedContactsWithEmailPreview.defaultProps = {
    loading: false,
    currentCall: null,
};

SelectedContactsWithEmailPreview.propTypes = {
    loading: PropTypes.bool,
    showEmailPreview: PropTypes.func.isRequired,
    duration: PropTypes.number.isRequired,
    startTime: PropTypes.number.isRequired,
    currentCall: PropTypes.shape({
        contactIds: PropTypes.arrayOf(PropTypes.string),
        contacts: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
        })),
        duration: PropTypes.number,
        startUtcTimestamp: PropTypes.number,
    }),
    selectedContacts: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    })).isRequired,
};
