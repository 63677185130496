/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { ReactComponent as ContactIcon } from '../../ag-icons/Rainmaker_soft_icon_21_Contact.svg';
import SelectedItemContainer from './SelectedItemContainer';
import MessagingTemplatesStep from './MessagingTemplatesStep';
import { PropTypeArrayOfObject, PropTypeObject } from '../../helpers/propTypes';

export function ProvisionSummaryStep({ provision, changeMessagingTemplate, currentContentId, type }) {
    const topics = provision.shareables.filter((s) => s.type === 'storyboard');
    const chapters = provision.shareables.filter((s) => s.type === 'structure');
    const slides = provision.shareables.filter((s) => s.type === 'slide');

    const mappedContacts = () => {
        const contacts = provision.contacts.map((contact) => ({
            value: contact.id,
            label: `${contact.firstName} ${contact.lastName}`,
            icon: ContactIcon,
            iconClassNames: 'ml-1',
        }));
        const customContacts = provision.customContacts
            .map((contact) => ({
                value: contact.value,
                label: contact.value,
                icon: ContactIcon,
                iconClassNames: 'ml-1',
            }));

        return contacts.concat(customContacts);
    };
    return (
        <Row>
            <Col span={12} sm={24} md={24} lg={12}>
                {topics.length ? (
                    <SelectedItemContainer
                        id="app.provision.topics"
                        data={topics}
                        items={[]}
                        showCloseButton={false}
                    />
                ) : null}
                {chapters.length ? (
                    <SelectedItemContainer
                        id="app.provision.chapters"
                        data={chapters}
                        items={[]}
                        showCloseButton={false}
                    />
                ) : null}
                {slides.length ? (
                    <SelectedItemContainer
                        id="app.provision.slides"
                        data={slides}
                        items={[]}
                        showCloseButton={false}
                    />
                ) : null}
                <SelectedItemContainer
                    id="app.provisioning.recipients"
                    data={[]}
                    items={mappedContacts()}
                    showMessageOnEmptySelection={false}
                    showCloseButton={false}
                />
            </Col>
            <Col span={12} sm={24} md={24} lg={12} className="sm:mt-4 md:mt-4 lg:mt-1">
                <MessagingTemplatesStep
                    changeMessagingTemplate={changeMessagingTemplate}
                />
            </Col>
        </Row>
    );
}

ProvisionSummaryStep.propTypes = {
    provision: PropTypes.shape({
        template: PropTypeObject,
        customContacts: PropTypeArrayOfObject,
        contacts: PropTypeArrayOfObject,
        shareables: PropTypeArrayOfObject,
    }),
    changeMessagingTemplate: PropTypes.func,
    currentContentId: PropTypes.string,
    type: PropTypes.string,
};
