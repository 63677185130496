import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { callsActions } from '../actions';

export const useFiltersContactsLoader = () => {
    const dispatch = useDispatch();
    const filtersContacts = useSelector((state) => state.calls.getIn(['filters', 'contacts', 'data']));

    useEffect(() => {
        if (!filtersContacts.length) {
            dispatch(callsActions.getFiltersContactsAsync());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const isFetchingFilters = useSelector((state) => state.calls.getIn(['filters', 'contacts', 'isFetching']));
    const error = useSelector((state) => state.calls.getIn(['filters', 'contacts', 'error']));

    return {
        filtersContacts,
        isFetchingFiltersContacts: isFetchingFilters,
        error,
    };
};
