import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* startedCallWorker() {
    const options = {
        fetcher: api.calls.getStartedCalls,
        fetcherParam: null,
        successAction: callsActions.fillStartedCalls,
        startFetching: callsActions.getStartedCallsStartFetching,
        stopFetching: callsActions.getStartedCallsStopFetching,
        setErrorAction: callsActions.getStartedCallsError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
