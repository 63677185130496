/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import WebViewer from '@pdftron/webviewer';
import { disabledElements, handleInstance } from './pdftronConfig';
import { CONTENT_TYPES } from '../../../config/constants';

export const Iframe = ({ url, contentType }) => {
    const [instance, setInstance] = useState(null);
    const ishtmlContent = contentType === CONTENT_TYPES.PRESENTATION && contentType;
    const viewer = useRef(null);

    useEffect(() => {
        if (!ishtmlContent) {
            WebViewer(
                {
                    path: 'pdftronLib',
                    licenseKey: process.env.REACT_APP_PDFTRON_KEY,
                    initialDoc: url,
                    disabledElements,
                },
                viewer.current,
            ).then((_instance) => {
                setInstance(_instance);
                handleInstance(_instance);
            });
        }

        return () => {
            instance?.closeDocument();
        };
    }, [url]);

    return ishtmlContent ? (
        <iframe
            title="content-preview"
            src={url}
            className="border-none h-full w-full"
            allowFullScreen
            scrolling="yes"
            style={{ height: '80vh' }}
        />
    ) : (
        <div data-testid="preview-pdftron" className="webviewer" ref={viewer} style={{ height: '80vh', width: '100%', marginTop: '-20px' }} />
    );
};

Iframe.defaultProps = {
    url: '',
    contentType: '',

};

Iframe.propTypes = {
    url: PropTypes.string,
    contentType: PropTypes.string,
};
