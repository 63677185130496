/* eslint-disable max-len */
import React from 'react';
import { Alert, Col, Input, Pagination, Row, Space, Spin, Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { Filters } from '../Filters';
import styles from '../EditOrCreateCallModal/index.module.less';
import { SelectedItems } from '../SelectedItems';
import { ReactComponent as ListViewIcon } from '../../ag-icons/Rainmaker_soft_icon_26_ListView.svg';
import { ReactComponent as TileViewIcon } from '../../ag-icons/Rainmaker_soft_icon_49_TileView.svg';
import { DEFAULT_ITEMS_PER_PAGE, SMS } from '../../config/constants';
import { useStickyState } from '../../bus/calls/hooks/useStickyState';
import { useBreakpoint } from '../../bus/calls/hooks/useBreakpoint';

export const ListWithFiltersAndSearch = (props) => {
    const intl = useIntl();
    const bp = useBreakpoint();

    const {
        filters,
        isFetchingFilters,
        selectedFilters,
        setSelectedFilters,
        exactFilters,
        setExactFilters,
        errorFilters,
        onSearchInputChange,
        loadingTable,
        error,
        dataSource,
        columns,
        searchTerm,
        paginationTotalItems,
        onChangePage,
        currentPage,
        isSelectable = true,
        selectedItemsNormalized,
        selectedItems,
        setSelectedItems,
        tableProps,
        expandable,
        bordered,
        gridView,
        itemsPerPage,
        showMessageOnEmptySelection = true,
        type,
    } = props;

    const [viewMode, setViewMode] = useStickyState(
        (gridView && gridView.initialViewMode) || 'list', /* default must be 'list' since 'grid' is optional ' */
        (gridView && gridView.stateKey) || 'view-mode',
    );

    const removeSelectedItemById = (id) => {
        const updatedArray = filter(selectedItems, (
            (c) => (c.id !== id)
        ));
        setSelectedItems(updatedArray);
    };

    const handleCloseSelectedItemTag = (id) => {
        removeSelectedItemById(id);
    };

    function addSelectedItem(selectedRow) {
        setSelectedItems((oldArray) => [...oldArray, selectedRow]);
    }

    if (error) return <Alert message={error} type="error" closable />;

    const viewToggleButtonsJSX = (
        <>
            <ListViewIcon
                onClick={() => { setViewMode('list'); }}
                className={`${styles.viewModeButton} ${viewMode === 'list' ? styles.selected : ''}`}
            />
            <TileViewIcon
                onClick={() => setViewMode('grid')}
                className={`${styles.viewModeButton} ${viewMode === 'grid' ? styles.selected : ''}`}
            />
        </>

    );

    const gridTileItems = !loadingTable
        && gridView
        && dataSource
        && dataSource.map((item) => gridView.cardRender(item));

    const gridJSX = loadingTable
        ? (
            <div
                className="flex justify-center text-center items-center"
                style={{
                    margin: 'auto',
                    height: '50%',
                    width: 'max-content',
                }}
            >
                <Spin />
            </div>
        )
        : (
            <div className="flex flex-wrap">
                {gridTileItems}
            </div>
        );

    const listJSX = (
        <Table
            loading={loadingTable}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
            bordered={bordered}
            rowSelection={isSelectable && {
                hideSelectAll: true,
                onSelect: (selectedRow) => {
                    const isFound = selectedItems.some((item) => (
                        selectedRow.id === item.id));
                    if (isFound) {
                        removeSelectedItemById(selectedRow.id);
                    } else {
                        addSelectedItem(selectedRow);
                    }
                },
                selectedRowKeys: selectedItems.map((c) => (c.id)),
                getCheckboxProps: (record) => ({
                    disabled: record.cellPhone === '' && type === SMS,
                    name: record.name,
                }),
            }}
            rowKey="id"
            expandable={expandable}
            dataSource={dataSource}
            pagination={false}
            columns={columns}
            {...tableProps}
            rowClassName={(record) => record.cellPhone === '' && type === SMS && styles.disabledRow}
        />
    );

    return (
        <>
            <Row gutter={[8, 4]}>
                <Col xs={24} sm={16} md={18} lg={18}>
                    { errorFilters
                        ? (
                            <Alert
                                message={<div style={{ margin: '-4px auto', display: 'block' }}>{errorFilters}</div>}
                                type="error"
                            />
                        )
                        : (
                            <Filters
                                filters={filters}
                                isFetchingFilters={isFetchingFilters}
                                selectedFilters={selectedFilters}
                                setSelectedFilters={setSelectedFilters}
                                exactFilters={exactFilters}
                                setExactFilters={setExactFilters}
                            />
                        )}

                </Col>
                <Col xs={24} sm={8} md={6} lg={6}>
                    <Space>
                        <Input
                            allowClear
                            placeholder={intl.formatMessage({ id: 'app.search' })}
                            onChange={onSearchInputChange}
                            className={styles.searchInput}
                            value={searchTerm}
                        />
                        {gridView && viewToggleButtonsJSX}
                    </Space>
                </Col>
            </Row>

            {viewMode === 'grid' ? gridJSX : listJSX}

            <Row justify="center">
                <Col>
                    <Pagination
                        size={(bp <= 3) ? 'small' : 'default'}
                        simple={(bp === 1)}
                        className={styles.pagination}
                        defaultCurrent={1}
                        defaultPageSize={itemsPerPage}
                        total={paginationTotalItems}
                        showTotal={(total, range) => (
                            <FormattedMessage
                                id="pagination.range"
                                values={{
                                    range: (`${range[0]} - ${range[1]}`),
                                    total,
                                }}
                            />
                        )}
                        disabled={loadingTable}
                        onChange={onChangePage}
                        current={currentPage}
                        showSizeChanger={false}
                    />
                </Col>
            </Row>
            <br />

            {isSelectable && (
                <SelectedItems
                    items={selectedItemsNormalized}
                    onCloseTag={handleCloseSelectedItemTag}
                    showMessageOnEmptySelection={showMessageOnEmptySelection}
                />
            )}
        </>
    );
};

ListWithFiltersAndSearch.defaultProps = {
    dataSource: null,
    gridView: null,
    expandable: null,
    isSelectable: true,
    errorFilters: null,
    error: null,
    selectedFilters: [],
    exactFilters: [],
    setSelectedFilters: () => {},
    setExactFilters: () => {},
    onSearchInputChange: () => {},
    paginationTotalItems: 10,
    selectedItemsNormalized: [],
    selectedItems: [],
    setSelectedItems: () => {},
    tableProps: {},
    bordered: false,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    showMessageOnEmptySelection: true,
};

ListWithFiltersAndSearch.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({})),
    filters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    showMessageOnEmptySelection: PropTypes.bool,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isFetchingFilters: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string.isRequired,
    expandable: PropTypes.shape({
        expandedRowRender: PropTypes.func.isRequired,
    }),
    loadingTable: PropTypes.bool.isRequired,
    isSelectable: PropTypes.bool,
    gridView: PropTypes.shape({
        cardRender: PropTypes.func.isRequired,
        stateKey: PropTypes.string.isRequired,
        initialViewMode: PropTypes.string.isRequired,
    }),
    onChangePage: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    errorFilters: PropTypes.node,
    error: PropTypes.node,
    selectedFilters: PropTypes.arrayOf(PropTypes.any),
    setSelectedFilters: PropTypes.func,
    exactFilters: PropTypes.arrayOf(PropTypes.any),
    setExactFilters: PropTypes.func,
    onSearchInputChange: PropTypes.func,
    paginationTotalItems: PropTypes.number,
    selectedItemsNormalized: PropTypes.arrayOf(PropTypes.any),
    selectedItems: PropTypes.arrayOf(PropTypes.any),
    setSelectedItems: PropTypes.func,
    tableProps: PropTypes.objectOf(PropTypes.any),
    bordered: PropTypes.bool,
    itemsPerPage: PropTypes.number,
    type: PropTypes.string,
};
