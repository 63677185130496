/* eslint-disable max-len */
import { addWeeks, subWeeks, format, fromUnixTime, isSameDay } from 'date-fns';
import { PLANNED_CALL_FILTER, FINISHED_CALL_FILTER, MODIFICATION_STAMP } from '../../config/constants';

export const getDaysArray = (start, end) => {
    const arr = [];
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

export const transformToChartData = (calls, dataName, fromDate, toDate, timeKey) => {
    const days = getDaysArray(fromDate, toDate);
    const modifiedAt = (time) => parseInt(time.split('time_').pop(), 10);
    const isModificationStamp = (call, day) => isSameDay(fromUnixTime(timeKey === MODIFICATION_STAMP ? modifiedAt(call[timeKey]) : call[timeKey]), day);

    return days.map((day) => {
        const callsThatDay = calls?.filter((call) => isModificationStamp(call, day));
        return {
            name: format(day, 'P').substring(0, 5),
            [dataName]: callsThatDay.length,
        };
    });
};

export const getConductedChartData = (calls) => {
    const toDate = new Date();
    const fromDate = subWeeks(toDate, 1);
    return transformToChartData(calls, FINISHED_CALL_FILTER, fromDate, toDate, MODIFICATION_STAMP);
};

export const getPlannedChartData = (calls) => {
    const fromDate = new Date();
    const toDate = addWeeks(fromDate, 1);
    return transformToChartData(calls, PLANNED_CALL_FILTER, fromDate, toDate, 'timestamp');
};
