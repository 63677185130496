import { Modal } from 'antd';
import { INVALID_EMAIL_ERROR } from '../../config/constants';

export const warningModal = (messages, intl) => {
    const isEmailError = messages[0]?.name === INVALID_EMAIL_ERROR;
    let content = messages.join(',  ');

    if (isEmailError) {
        const emails = messages.filter((m) => m.name === INVALID_EMAIL_ERROR)
            .map((m) => m.parameters.email);
        content = emails.join(',  ');
    }

    const title = intl.formatMessage({ id: isEmailError
        ? 'app.provision.emailNotSentToAddresses'
        : 'app.provision.smsNotSentToNumbers' });

    Modal.warning({ title, content });
};
