import format from 'date-fns/format';
import { Map } from 'immutable';
import { groupDashboardCalls } from '../../helpers';
import { types } from './types';

const initialState = Map({
    calls: {
        entities: null,
        isFetching: false,
        error: null,
        listParams: {
            page: 1,
            sortBy: 'timestamp',
            order: 'descend',
        },
        highlightedCallId: null,
    },
    conductedCalls: {
        entities: null,
        isFetching: false,
        error: null,
        listParams: {
            page: 1,
            sortBy: 'timestamp',
            order: 'descend',
        },
        lastUpdatedDashboard: '',
    },
    plannedCalls: {
        entities: null,
        isFetching: false,
        error: null,
        listParams: {
            page: 1,
            sortBy: 'timestamp',
            order: 'descend',
        },
    },
    startedCalls: {
        entities: null,
    },
    isFetchingEditOrCreate: false,
    errorEditOrCreate: null,
    isFetchingDeleteCall: false,
    errorDeleteCall: null,
    isFetchingEndCall: false,
    errorEndCall: null,
    callFields: null,
    isFetchingGetCallFields: false,
    errorGetCallFields: null,
    isFetchingSubmitFields: false,
    errorSubmitFields: null,
    contacts: {
        entities: null,
        isFetching: false,
        error: null,
    },
    content: {
        entities: null,
        isFetching: false,
        error: null,
    },
    filters: {
        contacts: {
            data: [],
            isFetching: false,
            error: null,
        },
        content: {
            data: [],
            isFetching: false,
            error: null,
        },
    },
    invitationEmail: {
        entities: null,
        isFetching: false,
        error: null,
    },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    function transformCallsData(payload) {
        const transformedData = {
            data: [],
            meta: { ...payload.meta },
        };

        payload.data.forEach((call) => {
            const { _id, contacts, contents, modifiedAt, ...restCall } = call;

            const updatedContacts = contacts.map(({
                // eslint-disable-next-line camelcase
                first_name,
                // eslint-disable-next-line camelcase
                last_name,
                id,
                ...rest
            }) => ({
                firstName: first_name,
                lastName: last_name,
                id: id.toString(),
                ...rest,
            }));

            const updatedContent = contents.map(({
                id,
                title,
                ...rest
            }) => ({
                id: id.toString(),
                name: title,
                ...rest,
            }));

            const newCall = {
                id: _id,
                contents: updatedContent,
                contacts: updatedContacts,
                modificationStamp: `time_${modifiedAt}`,
                ...restCall,
            };

            transformedData.data.push(newCall);
        });

        return transformedData;
    }

    switch (action.type) {
    /* Get Calls */

    case types.CALLS_FILL_CALLS:
        return state.setIn(['calls', 'entities'], transformCallsData(action.payload));

    case types.CALLS_SET_LIST_PARAMS:
        return state.setIn(['calls', 'listParams'], action.payload);
    case types.CALLS_SET_LIST_PARAMS_PAGE:
        return state.setIn(['calls', 'listParams', 'page'], action.payload);
    case types.CALLS_SET_HIGHLIGHTED_CALL_ID:
        return state.setIn(['calls', 'highlightedCallId'], action.payload);
    case types.CALLS_CLEAR_HIGHLIGHTED_CALL_ID:
        return state.setIn(['calls', 'highlightedCallId'], null);
    case types.CALLS_GET_CALLS_ERROR:
        return state
            .setIn(['calls', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_CALLS_START_FETCHING:
        return state.setIn(['calls', 'isFetching'], true);

    case types.CALLS_GET_CALLS_STOP_FETCHING:
        return state.setIn(['calls', 'isFetching'], false);

    case types.CALLS_CLEAR_CALLS:
        return state.set('calls', initialState.get('calls'));

        /** Started Calls */
    case types.CALLS_FILL_STARTED_CALLS:
        return state.setIn(['startedCalls', 'entities'], transformCallsData(action.payload));
    case types.CALLS_CLEAR_STARTED_CALL:
        return state.setIn(['startedCalls', 'entities', 'data'], state.getIn(['startedCalls', 'entities', 'data']).filter((d) => d.id !== action.payload));
        /* Get Planned Calls */

    case types.CALLS_FILL_DASHBOARD_CALLS: {
        const calls = groupDashboardCalls(transformCallsData(action.payload));
        const lastUpdated = format(new Date(), 'Pp');
        return state.setIn(['plannedCalls', 'entities'], calls.planned)
            .setIn(['conductedCalls', 'entities'], calls.conducted).setIn(['conductedCalls', 'lastUpdatedDashboard'], lastUpdated);
    }
    case types.CALLS_GET_DASHBOARD_CALLS_ERROR:
        return state
            .setIn(['plannedCalls', 'error'], { ...action.payload, type: 'client' })
            .setIn(['conductedCalls', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_DASHBOARD_CALLS_START_FETCHING:
        return state.setIn(['plannedCalls', 'isFetching'], true)
            .setIn(['conductedCalls', 'isFetching'], true);

    case types.CALLS_GET_DASHBOARD_CALLS_STOP_FETCHING:
        return state.setIn(['plannedCalls', 'isFetching'], false)
            .setIn(['conductedCalls', 'isFetching'], false);

        /* Get Contacts */

    case types.CALLS_FILL_CONTACTS:
        return state.setIn(['contacts', 'entities'], action.payload);

    case types.CALLS_GET_CONTACTS_ERROR:
        return state
            .setIn(['contacts', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_CONTACTS_START_FETCHING:
        return state.setIn(['contacts', 'isFetching'], true);

    case types.CALLS_GET_CONTACTS_STOP_FETCHING:
        return state.setIn(['contacts', 'isFetching'], false);

        /* Get Content */

    case types.CALLS_FILL_CONTENT:
        return state.setIn(['content', 'entities'], action.payload);

    case types.CALLS_GET_CONTENT_ERROR:
        return state
            .setIn(['content', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_CONTENT_START_FETCHING:
        return state.setIn(['content', 'isFetching'], true);

    case types.CALLS_GET_CONTENT_STOP_FETCHING:
        return state.setIn(['content', 'isFetching'], false);

        /* Get Content preview */

    case types.CALLS_FILL_CONTENT_PREVIEW:
        return state.setIn(['content', 'currentPreviewUrl'], action.payload.rootUrl)
            .setIn(['content', 'files'], action.payload.files?.slice(0, 6));

    case types.CALLS_GET_CONTENT_PREVIEW_ERROR_MESSAGE:
        return state.setIn(['content', 'previewErrorMessage'], action.payload);

        /* Get Filters Contacts */

    case types.CALLS_FILL_FILTERS_CONTACTS:
        return state.setIn(['filters', 'contacts', 'data'], action.payload);

    case types.CALLS_GET_FILTERS_CONTACTS_ERROR:
        return state
            .setIn(['filters', 'contacts', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_FILTERS_CONTACTS_START_FETCHING:
        return state.setIn(['filters', 'contacts', 'isFetching'], true);

    case types.CALLS_GET_FILTERS_CONTACTS_STOP_FETCHING:
        return state.setIn(['filters', 'contacts', 'isFetching'], false);

        /* Get Filters Content */

    case types.CALLS_FILL_FILTERS_CONTENT:
        return state.setIn(['filters', 'content', 'data'], action.payload);

    case types.CALLS_GET_FILTERS_CONTENT_ERROR:
        return state
            .setIn(['filters', 'content', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_FILTERS_CONTENT_START_FETCHING:
        return state.setIn(['filters', 'content', 'isFetching'], true);

    case types.CALLS_GET_FILTERS_CONTENT_STOP_FETCHING:
        return state.setIn(['filters', 'content', 'isFetching'], false);

        /** Edit Or Create Call */

    case types.CALLS_START_FETCHING_EDIT_OR_CREATE:
        return state.set('isFetchingEditOrCreate', true);

    case types.CALLS_STOP_FETCHING_EDIT_OR_CREATE:
        return state.set('isFetchingEditOrCreate', false);

    case types.CALLS_EDIT_CALL_SUCCESS: {
        return state.set('errorEditOrCreate', null);
    }

    case types.CALLS_CREATE_CALL_SUCCESS: {
        return state.set('errorEditOrCreate', null);
    }

    case types.CALLS_EDIT_OR_CREATE_ERROR:
        return state.set('errorEditOrCreate', { ...action.payload });

        /** Delete Call */

    case types.CALLS_DELETE_CALL_SUCCESS:
        return state.setIn(['calls', 'listParams', 'page'], 1)
            .set('errorDeleteCall', null);

    case types.CALLS_DELETE_CALL_START_FETCHING:
        return state.set('isFetchingDeleteCall', true);

    case types.CALLS_DELETE_CALL_STOP_FETCHING:
        return state.set('isFetchingDeleteCall', false);

    case types.CALLS_DELETE_CALL_ERROR:
        return state
            .set('errorDeleteCall', { ...action.payload, type: 'client' });

        /** End Call */

    case types.CALLS_END_CALL_SUCCESS:
        return state.setIn(['calls', 'listParams', 'page'], 1)
            .set('errorEndCall', null);

    case types.CALLS_END_CALL_START_FETCHING:
        return state.set('isFetchingEndCall', true);

    case types.CALLS_END_CALL_STOP_FETCHING:
        return state.set('isFetchingEndCall', false);

    case types.CALLS_END_CALL_ERROR:
        return state
            .set('errorEndCall', { ...action.payload, type: 'client' });

        /** Get Post Call Fields */

    case types.CALLS_FILL_CALL_FIELDS:
        return state.set('callFields', action.payload);

    case types.CALLS_GET_CALL_FIELDS_ERROR:
        return state.set('errorGetCallFields', { ...action.payload });

    case types.CALLS_GET_CALL_FIELDS_START_FETCHING:
        return state.set('isFetchingGetCallFields', true);

    case types.CALLS_GET_CALL_FIELDS_STOP_FETCHING:
        return state.set('isFetchingGetCallFields', false);

        /** Submit Post Call Fields */

    case types.CALLS_SUBMIT_FIELDS_ERROR:
        return state.set('errorSubmitFields', { ...action.payload });

    case types.CALLS_SUBMIT_FIELDS_SUCCESS:
        return state.setIn(['calls', 'listParams', 'page'], 1)
            .set('errorSubmitFields', null);

    case types.CALLS_SUBMIT_FIELDS_START_FETCHING:
        return state.set('isFetchingSubmitFields', true);

    case types.CALLS_SUBMIT_FIELDS_STOP_FETCHING:
        return state.set('isFetchingSubmitFields', false);

        /* Get Invitation Email Preview */
    case types.CALLS_GET_INVITATION_EMAIL_SUCCESS:
        return state.setIn(['invitationEmail', 'entities'], action.payload);

    case types.CALLS_GET_INVITATION_EMAIL_ERROR:
        return state
            .setIn(['invitationEmail', 'error'], { ...action.payload, type: 'client' });

    case types.CALLS_GET_INVITATION_EMAIL_START_FETCHING:
        return state.setIn(['invitationEmail', 'isFetching'], true);

    case types.CALLS_GET_INVITATION_EMAIL_STOP_FETCHING:
        return state.setIn(['invitationEmail', 'isFetching'], false);

    case types.CALLS_GET_INVITATION_EMAIL_CLEAR:
        return state.set('invitationEmail', initialState.get('invitationEmail'));

        /** Other */

    case types.CLEAR_ALL_ERRORS:
        return state
            .set('error', null)
            .set('errorEditOrCreate', null)
            .set('errorDeleteCall', null)
            .set('errorGetCallFields', null)
            .set('errorSubmitFields', null)
            .set('errorEndCall', null);

    default:
        return state;
    }
};
