/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Menu, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { CopyOutlined } from '@ant-design/icons';
import styles from './index.module.less';

const ActionMenu = ({ meetNowLink, intl }) => (
    <Menu>
        <Menu.ItemGroup title={<FormattedMessage id="app.meetNow" />}>
            <Menu.Item key={meetNowLink}>
                <CopyToClipboard
                    text={meetNowLink}
                    onCopy={() => message.success(
                        intl.formatMessage({ id: 'app.copied' }),
                        2,
                    )}
                >
                    <div className={styles.meetNowLink}>
                        <Button
                            size="small"
                            type="text"
                            icon={<CopyOutlined />}
                        />
                        <span>{meetNowLink}</span>
                    </div>
                </CopyToClipboard>
            </Menu.Item>
        </Menu.ItemGroup>
    </Menu>
);

export default ActionMenu;
