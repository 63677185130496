import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.less';
import noImage from '../../images/no-image.jpg';
import brokenImage from '../../images/broken-image.png';

export const ContentThumbnail = (props) => {
    const {
        src,
        version,
        thumbnailClass,
        thumbnailImageClass,
        versionClass,
        responsive = false,
    } = props;

    const [imageUrl, setImageUrl] = useState(src);

    return (
        <div className={`${thumbnailClass || styles.thumbnailWrapper} ${responsive ? styles.thumbnailWrapperResponsive : ''}`}>
            <span className={versionClass || styles.contentVersion}>{version}</span>
            <img
                src={imageUrl || noImage}
                onError={() => setImageUrl(brokenImage)}
                alt="thumbnail"
                className={thumbnailImageClass || styles.thumbnailImage}
            />
        </div>
    );
};
ContentThumbnail.defaultProps = {
    src: null,
    thumbnailClass: null,
    versionClass: null,
    thumbnailImageClass: null,
    responsive: false,
};
ContentThumbnail.propTypes = {
    version: PropTypes.string.isRequired,
    src: PropTypes.string,
    thumbnailClass: PropTypes.string,
    versionClass: PropTypes.string,
    thumbnailImageClass: PropTypes.string,
    responsive: PropTypes.bool,
};
