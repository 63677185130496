import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import schema from '../../schemas/callsSchema.json';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* getFiltersContentWorker() {
    const options = {
        fetcher: api.calls.getFiltersContent,
        fetcherParam: null,
        successAction: callsActions.fillFiltersContent,
        startFetching: callsActions.getFiltersContentStartFetching,
        stopFetching: callsActions.getFiltersContentStopFetching,
        setErrorAction: callsActions.getFiltersContentError,
        schema,
    };

    yield makeRequestWorker(options);
}
