import { api } from '../../config/api';

export const getCallContents = async (contents) => {
    const callContents = contents.map((content) => content);
    const promises = contents.map((content) => api.calls.getSingleContent(content.id));

    let res = [];
    try {
        res = await Promise.all(promises);
    } catch (e) {
        return [];
    }
    const braodCastedContentIds = res?.filter((c) => c?.error !== 400);
    const braodCastedContents = [];
    braodCastedContentIds.forEach((el) => {
        const av = callContents.find((c) => Number(c.id) === Number(el.id));
        braodCastedContents.push(av);
    });
    return braodCastedContents;
};
