export const MOBILIZER_TOKEN = 'mobilizer-token';
export const SOURCE_REMOTE = 'remote';
export const API_URL = 'api/remote-web/1.0';
export const DURATION_TIME_FORMAT = 'H[h] mm[m]';
export const DEBOUNCE_TIME = 400;
export const CALLS_PER_PAGE = 10;
export const CONTACTS_PER_PAGE = 10;
export const CONTENTS_PER_PAGE = 10;
export const CONDUCTED_CALL_SORT_BY_PLANNED = 'timestamp';
export const CALL_STATUS_FILTER = 'started,created,post-call-pending';
export const CONDUCTED_CALL_STATUS_FILTER = 'finished';
export const CALL_SORT_ORDER_DESC = 'desc';
export const CALL_SORT_ORDER_ASC = 'asc';
export const CLIENT = 'web';
export const REVEALER_URL = 'https://spotfire.agnitio.com';
export const PLANNED_CALL_STATUS_FILTER = 'created';
export const POST_CALL_PENDING_CALL_STATUS_FILTER = 'post-call-pending';
export const STARTED_CALL_STATUS_FILTER = 'started';
export const SUBMITTED_CALL = 'submitted';
export const SMS = 'sms';
export const EMAIL = 'email';
export const INVALID_EMAIL_ERROR = 'provisioning_token.email.invalid';
export const ERROR_400 = 400;
export const FINISHED_CALL_FILTER = 'finished';
export const PLANNED_CALL_FILTER = 'planned';
export const MODIFICATION_STAMP = 'modificationStamp';

export const USER_PERMISSIONS = Object.freeze({
    CONTACTS: 'contacts',
    CONDUCT_CALLS: 'conduct_calls',
    PLAN_CALLS: 'plan_calls',
    CALL_HISTORY: 'call_history',
    PROVISIONING: 'provisioning',
    REVEALER: 'revealer',
    REMOTE_AUDIO: 'remote_audio',
});
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const CLIENT_NAME = 'remote-web';

export const CONTENT_CARD_SIZING_PROPS = Object.freeze({
    breakPoint: 2048,
    tileM: { width: 160, height: 260 },
    tileXL: { width: 192, height: 260 },
});

export const CONTACT_CARD_SIZING_PROPS = Object.freeze({
    breakPoint: 2048,
    tileM: { width: 324, height: 200 },
    tileXL: { width: 324, height: 200 },
});

export const MESSAGE_KEYS = Object.freeze({
    INIT_COMMUNICATION: 'initCommunication',
    IS_INITILIZED: 'isInitilized',
    IS_COMPLETED: 'isCompleted',
});

export const CLIENT_INFO = Object.freeze({
    PLATFORM: 'Web',
    APPLICATION_FAMILY: 'MyLive',
});

export const INTERACTION_COMPLETED = Object.freeze({
    SUCCESSFULL: 'markInteractionCompleted',
});

export const AGNITIOLIBVERSION = '1.0.1';

export const CONTENT_TYPES = Object.freeze({
    PRESENTATION: 'presentation',
    CONTENT_PDF: 'content_pdf',
    CONTENT_PPT: 'content_ppt',
    CONTENT_DOC: 'content_doc',
});
