import { takeEvery, all, call } from 'redux-saga/effects';
import types from '../types';
import { authenticateWorker } from './workers/authenticateWorker';
import { setLocaleWorker } from '../../calls/saga/workers/setLocaleWorker';

function* authenticateWatcher() {
    yield takeEvery(types.STAFF_AUTH_ASYNC, authenticateWorker);
}

function* setLocaleWatcher() {
    yield takeEvery(types.STAFF_SET_LOCALE_ASYNC, setLocaleWorker);
}

export function* authWatchers() {
    yield all(
        [
            call(authenticateWatcher),
            call(setLocaleWatcher),
        ],
    );
}
