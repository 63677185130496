import React from 'react';
import { Collapse } from 'antd';
import { startCase, toLower } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './index.module.less';

const { Panel } = Collapse;
const titleCase = (string) => startCase(toLower(string));

export const ContactDetails = ({ record }) => {
    const intl = useIntl();
    const contactDetails = (contact) => (
        <div className="flex flex-col p-0">
            <div className="flex flex-wrap justify-between h-auto">
                <div className="w-64 sm:w-1/3 mb-4">
                    <div className="mr-4 p-6 min-h-full bg-white shadow">
                        <h2 className="mb-2 text-xs text-link text-semibold">
                            <FormattedMessage id="app.contact" />
                        </h2>
                        <div className="ml-px text-gray-600 text-xs">
                            <p className="my-px">
                                {contact.firstName} {contact.middleName} {contact.lastName}
                            </p>
                            {contact.formalTitle && (
                                <p className="my-px">{contact.formalTitle}</p>
                            )}
                            {contact.jobTitle && <p className="my-px">{contact.jobTitle}</p>}
                            {contact.mainPhone && (
                                <p className="my-px">t. {contact.mainPhone}</p>
                            )}
                            {contact.cellPhone && (
                                <p className="my-px">m. {contact.cellPhone}</p>
                            )}
                            {contact.email && <p className="my-px">{contact.email}</p>}
                        </div>
                    </div>
                </div>
                <div className="w-64 sm:w-1/3 mb-4">
                    <div className="mr-4 p-6 min-h-full bg-white shadow">
                        <h2 className="mb-2 text-xs text-link text-semibold">
                            <FormattedMessage id="app.address" />
                        </h2>
                        <div className="ml-px text-xs text-gray-600">
                            {contact.address && <p className="my-px">{contact.address}</p>}
                            {contact.address2 && <p className="my-px">{contact.address2}</p>}
                            {contact.address3 && <p className="my-px">{contact.address3}</p>}
                            {contact.city && <p className="my-px">{contact.city}</p>}
                            {contact.postalCode && (
                                <p className="my-px">{contact.postalCode}</p>
                            )}
                            {contact.country && <p className="my-px">{contact.country}</p>}
                            {contact.geography && (
                                <p className="my-px">{contact.geography}</p>
                            )}
                            {contact.brick && <p className="my-px">{contact.brick}</p>}
                        </div>
                    </div>
                </div>
                <div className="w-64 sm:w-1/3 mb-4">
                    <div className="mr-4 p-6 min-h-full bg-white shadow">
                        <h2 className="mb-2 text-xs text-link text-semibold">
                            <FormattedMessage id="app.professional" />
                        </h2>
                        <div className="ml-px">
                            {contact.speciality && (
                                <p className="my-px text-xs text-gray-700">
                                    {contact.speciality}
                                </p>
                            )}
                            {contact.primarySpecialty && (
                                <p className="my-px text-xs text-gray-700">
                                    {contact.primarySpeciality}
                                </p>
                            )}
                            {contact.kol && (
                                <p className="my-px text-xs text-gray-700">
                                    <FormattedMessage id="app.kol" />:{' '}
                                    {contact.kol ? (
                                        <FormattedMessage id="app.yes" />
                                    ) : (
                                        <FormattedMessage id="app.no" />
                                    )}
                                </p>
                            )}
                            {contact.speaker && (
                                <p className="my-px text-xs text-gray-700">
                                    <FormattedMessage id="app.speaker" />:{' '}
                                    {contact.speaker ? (
                                        <FormattedMessage id="app.yes" />
                                    ) : (
                                        <FormattedMessage id="app.no" />
                                    )}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <Collapse
                    className={`border-0 bg-transparent max-w-xs sm:max-w-full ${styles.collapse}`}
                >
                    <Panel
                        header={intl.formatMessage({ id: 'app.additionalInformation' })}
                        key="1"
                        className={`contact-panel ${styles.panel}`}
                    >
                        <div className="flex flex-no-wrap">
                            <div className="ml-2 text-right text-link font-light text-xs leading-6">
                                {contact.gender && (
                                    <div>
                                        <FormattedMessage id="app.gender" />:
                                    </div>
                                )}
                                {contact.accessibility && (
                                    <div>
                                        <FormattedMessage id="app.accessibility" />:
                                    </div>
                                )}
                                {contact.sellingStageValue && (
                                    <div>
                                        <FormattedMessage id="app.sellingStageValue" />:
                                    </div>
                                )}
                                {contact.attitude && (
                                    <div>
                                        <FormattedMessage id="app.attitude" />:
                                    </div>
                                )}
                                {contact.eDetailing && (
                                    <div>
                                        <FormattedMessage id="app.eDetailing" />:
                                    </div>
                                )}
                                {contact.targetingCategoryValue && (
                                    <div>
                                        <FormattedMessage id="app.targetingCategory" />:
                                    </div>
                                )}
                                {contact.bttcDay && (
                                    <div>
                                        <FormattedMessage id="app.bttcDay" />:
                                    </div>
                                )}
                                {contact.bttcStartTime && contact.bttcEndTime && (
                                    <div>
                                        <FormattedMessage id="app.bttc" />:
                                    </div>
                                )}
                                {contact.callFrequency !== 0 && contact.callFrequency && (
                                    <div>
                                        <FormattedMessage id="app.callFrequency" />:
                                    </div>
                                )}
                            </div>
                            <div className="ml-3 mb-0 text-left text-xs leading-6">
                                {contact.gender && <div>{titleCase(contact.gender)}</div>}
                                {contact.accessibility && <div>{contact.accessibility}</div>}
                                {contact.sellingStageValue && (
                                    <div>{contact.sellingStageValue}</div>
                                )}
                                {contact.attitude && <div>{contact.attitude}</div>}
                                {contact.eDetailing && (
                                    <div>
                                        {contact.eDetailing ? (
                                            <FormattedMessage id="app.yes" />
                                        ) : (
                                            <FormattedMessage id="app.no" />
                                        )}
                                    </div>
                                )}
                                {contact.targetingCategoryValue && (
                                    <div>{contact.targetingCategoryValue}</div>
                                )}
                                {contact.bttcDay && <div>{titleCase(contact.bttcDay)}</div>}
                                {contact.bttcStartTime && contact.bttcEndTime && (
                                    <div>
                                        {contact.bttcStartTime} - {contact.bttcEndTime}
                                    </div>
                                )}
                                {contact.callFrequency !== 0 && contact.callFrequency && (
                                    <div>{contact.callFrequency}</div>
                                )}
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
    );

    return contactDetails(record);
};
