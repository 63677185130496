import { apply, call, put } from 'redux-saga/effects';
import Ajv from 'ajv';
import { message } from 'antd';
import { api } from '../config/api';
import { staffActions } from '../bus/staff/actions';
import { INVALID_EMAIL_ERROR, ERROR_400 } from '../config/constants';

export function* makeRequestWorker(options) {
    const showSuccessMessage = (msg) => {
        message.success(msg);
        return null;
    };

    const {
        fetcher,
        fetcherParam = null,
        successAction,
        onSuccess,
        startFetching = null,
        stopFetching = null,
        setErrorAction,
        schema,
        successMessage,
    } = options;

    try {
        if (startFetching) yield put(startFetching());

        const { data } = yield apply(api, fetcher, [fetcherParam]);

        if (schema) {
            const ajv = new Ajv();
            const isValid = ajv.validate(schema, data);
            if (!isValid) {
                yield put(setErrorAction({
                    message: 'Data is not valid',
                    statusCode: null,
                }));
            }
        }
        if (data?.errors && data.errors[0].name === INVALID_EMAIL_ERROR) {
            /*
            It sets error message for email provisioning, server returns 200 with errors in data
            so we need to check if there is error message in data
            error message sms provisioning returns 400 error code, therefore
            status code is hardcoded as 400 to make it same type error in provisioning page */
            yield put(setErrorAction({ message: data.errors, statusCode: ERROR_400 }));
        } else {
            yield put(successAction(data));
        }

        if (onSuccess) {
            yield call(onSuccess, data);
        }

        if (successMessage) {
            yield call(showSuccessMessage, successMessage);
        }
    } catch (error) {
        if (error.response) {
            const { status, data } = error.response;
            console.error('error.response', error);
            if (status === 401) { yield put(staffActions.setSessionExpired()); }
            if (status === ERROR_400 && data.errors[0]?.parameters?.phoneNumbers?.length) {
                const phoneNumbers = data.errors[0]?.parameters?.phoneNumbers;
                yield put(setErrorAction({ message: phoneNumbers, statusCode: status }));
            } else {
                yield put(setErrorAction({ message: data && data.message, statusCode: status }));
            }
        } else if (error.request) {
            console.error('--> error request:', error.request);
        } else {
            console.error('--> error other:', error.message);
        }
    } finally {
        if (stopFetching) yield put(stopFetching());
    }
}
