import React from 'react';
import { List, Col, Row, Typography, Avatar } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { ShareableCard } from './ShareableCard';
import styles from './index.module.less';
import {
    PropTypeArrayOfObject,
    PropTypeFunc,
    PropTypeString,
    PropTypeArray,
} from '../../helpers/propTypes';

const { Text } = Typography;
function ProvisionList({
    dataSource,
    selectedShareablesId,
    onShareablesChange,
    id,
}) {
    return (
        <Row className="mb-4">
            <Col span={24} className="mb-4">
                <Text className={styles.listHeader}>
                    <FormattedMessage id={id} />
                    <Avatar className={styles.mlAvatar} size={20}>{dataSource.length}</Avatar>
                </Text>
            </Col>
            <Col span={24}>
                <List
                    grid={{
                        gutter: 16,
                        xs: 2,
                        sm: 2,
                        md: 4,
                        lg: 5,
                        xl: 6,
                        xxl: 6,
                    }}
                    dataSource={dataSource}
                    renderItem={(data) => {
                        const isSelected = selectedShareablesId.includes(
                            data.id,
                        );
                        return (
                            <List.Item>
                                <ShareableCard
                                    sharable={data}
                                    isSelected={isSelected}
                                    onSelect={(item) => onShareablesChange(item)}
                                    truncateTitle
                                />
                            </List.Item>
                        );
                    }}
                />
            </Col>
        </Row>
    );
}

export default ProvisionList;

ProvisionList.propTypes = {
    dataSource: PropTypeArrayOfObject,
    selectedShareablesId: PropTypes.oneOfType([PropTypeString, PropTypeArray]),
    onShareablesChange: PropTypeFunc,
    id: PropTypeString,
};
