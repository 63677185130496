/* eslint-disable linebreak-style */
import { types } from './types';

export const callsActions = Object.freeze({

    /* Get Calls */

    getCallsAsync: (payload) => ({
        type: types.CALLS_GET_CALLS_ASYNC, payload,
    }),
    getDashboardCallsAsync: (payload) => ({
        type: types.CALLS_GET_DASHBOARD_CALLS_ASYNC, payload,
    }),
    fillCalls: (calls) => ({
        type: types.CALLS_FILL_CALLS,
        payload: calls,
    }),
    fillDashboardCalls: (calls) => ({
        type: types.CALLS_FILL_DASHBOARD_CALLS,
        payload: calls,
    }),
    setListParams: (listParams) => ({
        type: types.CALLS_SET_LIST_PARAMS,
        payload: listParams,
    }),
    setListParamsPage: (page) => ({
        type: types.CALLS_SET_LIST_PARAMS_PAGE,
        payload: page,
    }),
    setHighlightedCallId: (callId) => ({
        type: types.CALLS_SET_HIGHLIGHTED_CALL_ID,
        payload: callId,
    }),
    clearHighlightedCallId: () => ({
        type: types.CALLS_CLEAR_HIGHLIGHTED_CALL_ID,
    }),
    getCallsError: (error) => ({
        type: types.CALLS_GET_CALLS_ERROR,
        payload: error,
    }),
    getCallsStartFetching: () => ({
        type: types.CALLS_GET_CALLS_START_FETCHING,
    }),
    getCallsStopFetching: () => ({
        type: types.CALLS_GET_CALLS_STOP_FETCHING,
    }),
    getDashboardCallsError: (error) => ({
        type: types.CALLS_GET_DASHBOARD_CALLS_ERROR,
        payload: error,
    }),
    getDashboardCallsStartFetching: () => ({
        type: types.CALLS_GET_DASHBOARD_CALLS_START_FETCHING,
    }),
    getDashboardCallsStopFetching: () => ({
        type: types.CALLS_GET_DASHBOARD_CALLS_STOP_FETCHING,
    }),
    clearCalls: () => ({
        type: types.CALLS_CLEAR_CALLS,
    }),

    /* Get Contacts */

    getContactsAsync: (payload) => ({
        type: types.CALLS_GET_CONTACTS_ASYNC,
        payload,
    }),
    fillContacts: (payload) => ({
        type: types.CALLS_FILL_CONTACTS,
        payload,
    }),
    getContactsError: (error) => ({
        type: types.CALLS_GET_CONTACTS_ERROR,
        payload: error,
    }),
    getContactsStartFetching: () => ({
        type: types.CALLS_GET_CONTACTS_START_FETCHING,
    }),
    getContactsStopFetching: () => ({
        type: types.CALLS_GET_CONTACTS_STOP_FETCHING,
    }),

    /* Get Content */

    getContentAsync: (payload) => ({
        type: types.CALLS_GET_CONTENT_ASYNC,
        payload,
    }),
    fillContent: (payload) => ({
        type: types.CALLS_FILL_CONTENT,
        payload,
    }),
    getContentError: (error) => ({
        type: types.CALLS_GET_CONTENT_ERROR,
        payload: error,
    }),
    getContentStartFetching: () => ({
        type: types.CALLS_GET_CONTENT_START_FETCHING,
    }),
    getContentStopFetching: () => ({
        type: types.CALLS_GET_CONTENT_STOP_FETCHING,
    }),

    /* Get Content Preview */

    getContentPreview: (payload) => ({
        type: types.CALLS_GET_CONTENT_PREVIEW,
        payload,
    }),
    fillContentPreview: (payload) => ({
        type: types.CALLS_FILL_CONTENT_PREVIEW,
        payload,
    }),

    getContentPreviewErrorMessage: (error) => ({
        type: types.CALLS_GET_CONTENT_PREVIEW_ERROR_MESSAGE,
        payload: error,
    }),

    /* Get Filters for Contacts */

    getFiltersContactsAsync: (payload) => ({
        type: types.CALLS_GET_FILTERS_CONTACTS_ASYNC,
        payload,
    }),
    fillFiltersContacts: (payload) => ({
        type: types.CALLS_FILL_FILTERS_CONTACTS,
        payload,
    }),
    getFiltersContactsError: (error) => ({
        type: types.CALLS_GET_FILTERS_CONTACTS_ERROR,
        payload: error,
    }),
    getFiltersContactsStartFetching: () => ({
        type: types.CALLS_GET_FILTERS_CONTACTS_START_FETCHING,
    }),
    getFiltersContactsStopFetching: () => ({
        type: types.CALLS_GET_FILTERS_CONTACTS_STOP_FETCHING,
    }),

    /* Get Filters for Content */

    getFiltersContentAsync: (payload) => ({
        type: types.CALLS_GET_FILTERS_CONTENT_ASYNC,
        payload,
    }),
    fillFiltersContent: (payload) => ({
        type: types.CALLS_FILL_FILTERS_CONTENT,
        payload,
    }),
    getFiltersContentError: (error) => ({
        type: types.CALLS_GET_FILTERS_CONTENT_ERROR,
        payload: error,
    }),
    getFiltersContentStartFetching: () => ({
        type: types.CALLS_GET_FILTERS_CONTENT_START_FETCHING,
    }),
    getFiltersContentStopFetching: () => ({
        type: types.CALLS_GET_FILTERS_CONTENT_STOP_FETCHING,
    }),

    /* Edit Or Create Call */

    editCallAsync: (payload) => ({
        type: types.CALLS_EDIT_CALL_ASYNC,
        payload,
    }),
    createCallAsync: (payload) => ({
        type: types.CALLS_CREATE_CALL_ASYNC,
        payload,
    }),
    editCallSuccess: () => ({
        type: types.CALLS_EDIT_CALL_SUCCESS,
    }),
    createCallSuccess: () => ({
        type: types.CALLS_CREATE_CALL_SUCCESS,
    }),

    startFetchingEditOrCreate: () => ({
        type: types.CALLS_START_FETCHING_EDIT_OR_CREATE,
    }),
    stopFetchingEditOrCreate: () => ({
        type: types.CALLS_STOP_FETCHING_EDIT_OR_CREATE,
    }),
    editOrCreateCallError: (error) => ({
        type: types.CALLS_EDIT_OR_CREATE_ERROR,
        payload: error,
    }),

    /** Delete Call */

    deleteCallAsync: (payload) => ({
        type: types.CALLS_DELETE_CALL_ASYNC,
        payload,
    }),
    deleteCallSuccess: (payload) => ({
        type: types.CALLS_DELETE_CALL_SUCCESS,
        payload,
    }),
    startFetchingDeleteCall: () => ({
        type: types.CALLS_DELETE_CALL_START_FETCHING,
    }),
    stopFetchingDeleteCall: () => ({
        type: types.CALLS_DELETE_CALL_STOP_FETCHING,
    }),
    deleteCallError: (error) => ({
        type: types.CALLS_DELETE_CALL_ERROR,
        payload: error,
    }),

    clearAllError: () => ({
        type: types.CLEAR_ALL_ERRORS,
    }),

    /** Get Post Call Fields */

    getCallFieldsAsync: (payload) => ({
        type: types.CALLS_GET_CALL_FIELDS_ASYNC,
        payload,
    }),
    fillCallFields: (payload) => ({
        type: types.CALLS_FILL_CALL_FIELDS,
        payload,
    }),
    startFetchingCallFields: () => ({
        type: types.CALLS_GET_CALL_FIELDS_START_FETCHING,
    }),
    stopFetchingCallFields: () => ({
        type: types.CALLS_GET_CALL_FIELDS_STOP_FETCHING,
    }),
    getCallFieldsError: (error) => ({
        type: types.CALLS_GET_CALL_FIELDS_ERROR,
        payload: error,
    }),

    /** Submit Post Call Fields */

    submitFieldsAsync: (payload) => ({
        type: types.CALLS_SUBMIT_FIELDS_ASYNC,
        payload,
    }),
    submitFieldsSuccess: () => ({
        type: types.CALLS_SUBMIT_FIELDS_SUCCESS,
    }),
    submitFieldsStartFetching: () => ({
        type: types.CALLS_SUBMIT_FIELDS_START_FETCHING,
    }),
    submitFieldsStopFetching: () => ({
        type: types.CALLS_SUBMIT_FIELDS_STOP_FETCHING,
    }),
    submitFieldsError: (error) => ({
        type: types.CALLS_SUBMIT_FIELDS_ERROR,
        payload: error,
    }),

    /** Get Invitation Email */

    getInvitationEmailAsync: (payload) => ({
        type: types.CALLS_GET_INVITATION_EMAIL_ASYNC,
        payload,
    }),
    getInvitationEmailSuccess: (payload) => ({
        type: types.CALLS_GET_INVITATION_EMAIL_SUCCESS,
        payload,
    }),
    getInvitationEmailStartFetching: () => ({
        type: types.CALLS_GET_INVITATION_EMAIL_START_FETCHING,
    }),
    getInvitationEmailStopFetching: () => ({
        type: types.CALLS_GET_INVITATION_EMAIL_STOP_FETCHING,
    }),
    getInvitationEmailError: (error) => ({
        type: types.CALLS_GET_INVITATION_EMAIL_ERROR,
        payload: error,
    }),
    getInvitationEmailClear: () => ({
        type: types.CALLS_GET_INVITATION_EMAIL_CLEAR,
    }),

    /** STARTED CALL */

    getStartedCallsAsync: () => ({
        type: types.CALLS_GET_STARTED_CALLS_ASYNC,
    }),
    getStartedCallsStartFetching: () => ({
        type: types.CALLS_GET_STARTED_CALLS_START_FETCHING,
    }),
    getStartedCallsStopFetching: () => ({
        type: types.CALLS_GET_STARTED_CALLS_STOP_FETCHING,
    }),

    getStartedCallsError: () => ({
        type: types.CALLS_GET_STARTED_CALLS_ERROR,
    }),

    fillStartedCalls: (payload) => ({
        type: types.CALLS_FILL_STARTED_CALLS,
        payload,
    }),
    clearStartedCall: (payload) => ({
        type: types.CALLS_CLEAR_STARTED_CALL,
        payload,
    }),

    /** End Call */

    endCallAsync: (payload) => ({
        type: types.CALLS_END_CALL_ASYNC,
        payload,
    }),
    endCallSuccess: (payload) => ({
        type: types.CALLS_END_CALL_SUCCESS,
        payload,
    }),
    startFetchingEndCall: () => ({
        type: types.CALLS_END_CALL_START_FETCHING,
    }),
    stopFetchingEndCall: () => ({
        type: types.CALLS_END_CALL_STOP_FETCHING,
    }),
    endCallError: (error) => ({
        type: types.CALLS_END_CALL_ERROR,
        payload: error,
    }),
});
