import { api } from '../../../../config/api';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import { callsActions } from '../../actions';

export function* getContentPreviewUrlWorker({ payload }) {
    const options = {
        fetcher: api.calls.getContentPreviewUrl,
        fetcherParam: payload,
        successAction: callsActions.fillContentPreview,
        schema: null,
        setErrorAction: callsActions.getContentPreviewErrorMessage,
    };

    yield makeRequestWorker(options);
}
