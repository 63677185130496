import { api } from '../../../../config/api';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import { callsActions } from '../../actions';

export function* getContentWorker({ payload }) {
    const options = {
        fetcher: api.calls.getContent,
        fetcherParam: payload,
        successAction: callsActions.fillContent,
        startFetching: callsActions.getContentStartFetching,
        stopFetching: callsActions.getContentStopFetching,
        setErrorAction: callsActions.getContentError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
