import { mergeWith } from 'lodash';
import enUSTranslations from './en-US.json';
import enGBTranslations from './en-GB.json';
import deDeTranslations from './de-DE.json';
import esEsTranslations from './es-ES.json';
import frFrTranslations from './fr-FR.json';
import itItTranslations from './it-IT.json';
import jaJpTranslations from './ja-JP.json';
import nlNlTranslations from './nl-NL.json';
import plPlTranslations from './pl-PL.json';
import ptPtTranslations from './pt-PT.json';
import ruRuTranslations from './ru-RU.json';
import trTrTranslations from './tr-TR.json';
import zhHansTranslations from './zh-CN.json';
import { LOCALES } from '../constants';

/**
 * Merges the selected language with en-US, so when the translation is missing, English will be used
 */
const mergeIgnoreEmptyString = (object, sources) => (
    mergeWith({}, object, sources, (a, b) => (b === '' ? a : undefined))
);

export const messages = {
    [LOCALES['en-US'].value]: enUSTranslations,
    [LOCALES['en-GB'].value]: mergeIgnoreEmptyString(enUSTranslations, enGBTranslations),
    [LOCALES['de-DE'].value]: mergeIgnoreEmptyString(enUSTranslations, deDeTranslations),
    [LOCALES['es-ES'].value]: mergeIgnoreEmptyString(enUSTranslations, esEsTranslations),
    [LOCALES['fr-FR'].value]: mergeIgnoreEmptyString(enUSTranslations, frFrTranslations),
    [LOCALES['it-IT'].value]: mergeIgnoreEmptyString(enUSTranslations, itItTranslations),
    [LOCALES['ja-JP'].value]: mergeIgnoreEmptyString(enUSTranslations, jaJpTranslations),
    [LOCALES['nl-NL'].value]: mergeIgnoreEmptyString(enUSTranslations, nlNlTranslations),
    [LOCALES['pl-PL'].value]: mergeIgnoreEmptyString(enUSTranslations, plPlTranslations),
    [LOCALES['pt-PT'].value]: mergeIgnoreEmptyString(enUSTranslations, ptPtTranslations),
    [LOCALES['ru-RU'].value]: mergeIgnoreEmptyString(enUSTranslations, ruRuTranslations),
    [LOCALES['tr-TR'].value]: mergeIgnoreEmptyString(enUSTranslations, trTrTranslations),
    [LOCALES['zh-CN'].value]: mergeIgnoreEmptyString(enUSTranslations, zhHansTranslations),
};
