import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import schema from '../../schemas/callsSchema.json';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* getFiltersContactsWorker() {
    const options = {
        fetcher: api.calls.getFiltersContact,
        fetcherParam: null,
        successAction: callsActions.fillFiltersContacts,
        startFetching: callsActions.getFiltersContactsStartFetching,
        stopFetching: callsActions.getFiltersContactsStopFetching,
        setErrorAction: callsActions.getFiltersContactsError,
        schema,
    };

    yield makeRequestWorker(options);
}
