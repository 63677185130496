import { types } from './types';

export const dashboardActions = Object.freeze({
    /* Get Calls */

    getCallsAsync: (payload) => ({
        type: types.CALLS_GET_CALLS_ASYNC,
        payload,
    }),
    getDashboardTableCallsAsync: ({ sortBy, orderBy }) => ({
        type: types.DASHBOARD_TABLE_CALLS_ASYNC,
        payload: {
            pageSize: 10,
            page: 1,
            sortBy,
            orderBy,
        },
    }),
    getDashboardTableCallsError: (payload) => ({
        type: types.DASHBOARD_TABLE_CALLS_ERROR,
        payload,
    }),
    getDashboardTableCallsStartFetching: () => ({
        type: types.DASHBOARD_TABLE_CALLS_START_FETCHING,
    }),
    fillDashboardTableCalls: (payload) => ({
        type: types.DASHBOARD_TABLE_CALLS,
        payload,
    }),
    setDashboardListParams: (listParams) => ({
        type: types.DASHBOARD_SET_TABLE_CALLS_LIST_PARAMS,
        payload: listParams,
    }),
});
