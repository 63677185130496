/* eslint-disable linebreak-style */
import { api } from '../../../../config/api';
import { callsActions } from '../../actions';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* getDashboardCallsWorker({ payload }) {
    const options = {
        fetcher: api.calls.dashboardCalls.getDashboardCalls,
        fetcherParam: payload,
        successAction: callsActions.fillDashboardCalls,
        startFetching: callsActions.getDashboardCallsStartFetching,
        stopFetching: callsActions.getDashboardCallsStopFetching,
        setErrorAction: callsActions.getDashboardCallsError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
