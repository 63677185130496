import React, { useState } from 'react';
import { Checkbox, Divider, Spin, TreeSelect } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;

export const Filters = ({
    filters,
    isFetchingFilters,
    selectedFilters,
    setSelectedFilters,
    setExactFilters,
}) => {
    const [isExact, setIsExact] = useState(false);

    const getNormalizedFilterTree = (allFilters) => {
        const treeData = [];

        function setItems(items) {
            const newItems = [];

            items.forEach((item) => {
                const newItem = {};

                newItem.title = item.name;
                newItem.value = item.id;
                newItem.key = item.id;
                if (item.items && item.items.length) {
                    newItem.children = setItems(item.items);
                }
                newItems.push(newItem);
            });

            return newItems;
        }

        if (allFilters && allFilters.length) {
            allFilters.forEach((filter) => {
                const newFilter = {};
                newFilter.value = filter.prefix;
                newFilter.key = filter.prefix;
                newFilter.children = setItems(filter.items);
                newFilter.title = (
                    <b>{filter.name}</b>
                );
                newFilter.checkable = false;
                newFilter.selectable = false;

                treeData.push(newFilter);
            });
        }
        return treeData;
    };

    const handleChange = (value) => {
        setSelectedFilters(value);
    };

    const handleChangeExact = (e) => {
        if (e.target.checked) {
            const exactIDs = filters.map((filter) => filter.prefix);
            setExactFilters(exactIDs);
        } else {
            setExactFilters([]);
        }

        setIsExact(e.target.checked);
    };

    return (
        <Spin spinning={isFetchingFilters}>
            <TreeSelect
                showSearch
                style={{ width: '100%' }}
                treeData={getNormalizedFilterTree(filters)}
                value={selectedFilters}
                dropdownStyle={{ maxHeight: 600, overflow: 'auto' }}
                placeholder={<FormattedMessage id="app.filters" />}
                allowClear
                onChange={handleChange}
                multiple
                treeNodeFilterProp="title"
                treeCheckable
                labelInValue
                showCheckedStrategy={isExact ? SHOW_CHILD : SHOW_PARENT}
                treeCheckStrictly={isExact}
                dropdownRender={(orig) => (
                    <div>
                        <Checkbox
                            onChange={(e) => { handleChangeExact(e); }}
                            style={{ marginLeft: '10px', color: '#afafaf' }}
                            checked={isExact}
                        >
                            Exact
                        </Checkbox>
                        <Divider
                            style={{
                                margin: '5px auto',
                            }}
                        />
                        {orig}
                    </div>
                )}
            />
        </Spin>
    );
};

Filters.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        key: PropTypes.string,
        title: PropTypes.string,
    })).isRequired,
    isFetchingFilters: PropTypes.bool.isRequired,
    selectedFilters: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
    })).isRequired,
    setSelectedFilters: PropTypes.func.isRequired,
    setExactFilters: PropTypes.func.isRequired,
};
