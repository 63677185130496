export const PROVISION_CLEAR = 'PROVISION_CLEAR';
export const PROVISION_SET_CONTENT = 'PROVISION_SET_CONTENT';
export const PROVISION_ADD_SHAREABLE = 'PROVISION_ADD_SHAREABLE';
export const PROVISION_REMOVE_SHAREABLE = 'PROVISION_REMOVE_SHAREABLE';
export const PROVISION_SET_CONTACTS = 'PROVISION_SET_CONTACTS';
export const PROVISION_ADD_CUSTOM_CONTACTS = 'PROVISION_ADD_CUSTOM_CONTACTS';
export const PROVISION_REMOVE_CUSTOM_CONTACTS = 'PROVISION_REMOVE_CUSTOM_CONTACTS';

export const PROVISION_SET_TRANSPORT = 'PROVISION_SET_TRANSPORT';
export const PROVISION_SET_MESSAGING_TEMPLATE = 'PROVISION_SET_MESSAGING_TEMPLATE';

export const PROVISION_SEND_ASYNC = 'PROVISION_SEND_ASYNC';
export const PROVISION_SEND_ASYNC_START = 'PROVISION_SEND_ASYNC_START';
export const PROVISION_SEND_ASYNC_SUCCESSFUL = 'PROVISION_SEND_ASYNC_SUCCESSFUL';
export const PROVISION_SEND_ASYNC_ERROR = 'PROVISION_SEND_ASYNC_ERROR';
