import { isAfter, subMinutes } from 'date-fns';
/**
  * the following function filters 10min before, current time and future time
  * @param {Date} time
  * @param {number} offset  - (minutes?) default is 10.
  * @return {boolean}
  */
export default function isFutureTime(time, offset = 10) {
    return isAfter(time, subMinutes(new Date(), offset));
}
