/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Typography } from 'antd';
import styles from './index.module.less';

const { Text } = Typography;

const DateTime = ({ timestamp }) => (
    <>
        <Text strong>
            <FormattedTime
                value={new Date(timestamp * 1000)}
                hour="numeric"
                minute="numeric"
            />
        </Text>
        <br />

        <Text style={{ whiteSpace: 'nowrap' }} className={styles.date}>
            <FormattedDate
                value={new Date(timestamp * 1000)}
                year="numeric"
                month="short"
                day="2-digit"
            />
        </Text>
    </>
);

export default DateTime;
