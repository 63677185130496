/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Spin } from 'antd';
import React from 'react';
import styles from './index.module.less';

export const SpinnerCentered = (props) => (
    <div className={styles.spinCenter}>
        <Spin className={props?.className} {...props} />
    </div>
);
