/* eslint-disable max-len */
import React, { useState } from 'react';
import { Alert, Button, Col, Divider, Modal, Row, Spin, Typography, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { callsActions } from '../../bus/calls/actions';
import { useCallFieldsLoader } from '../../bus/calls/hooks/useCallFieldsLoader';
import { Iframe } from './Iframe';
import { useSubmitCallFields } from '../../bus/calls/hooks/useSubmitCallFields';
import styles from './index.module.less';
import { book } from '../../routing/book';
import CallFields from './CallFields';

const { Text } = Typography;

export const CallFieldsModal = () => {
    const intl = useIntl();
    const [openIframeModal, setOpenIframeModal] = useState(false);

    const {
        callFields,
        isFetching,
        error: errorGetCallFields,
    } = useCallFieldsLoader();

    const { submitCallFields,
        isFetchingSubmitFields,
        error: submitFieldsError,
    } = useSubmitCallFields();

    const [iFrameModalData, setIFrameModalData] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    const { callId } = useParams();

    const back = (e) => {
        e.stopPropagation();
        dispatch(callsActions.clearAllError());
        history.push(book.calls);
    };
    const isSubmitButtonDisabled = (submiting, valid, errors) => submiting || !valid || errors;
    const getInitialValues = () => {
        const initialValues = {};
        if (!callFields?.length) return initialValues;

        for (const field of callFields) {
            if (field.type === 'text') {
                initialValues[field.id] = '';
            } else if (field.type === 'external' && !field.options.required) {
                initialValues[field.id] = { startTime: false };
            } else {
                initialValues[field.id] = null;
            }
        }
        return initialValues;
    };

    const getSchema = () => {
        const schema = {};
        if (callFields) {
            callFields.forEach((field) => {
                if (field.type === 'number') { schema[field.id] = Yup.number().typeError('Must be a number').nullable(); }
                if (field.options.required) { schema[field.id] = Yup.mixed().required('Required'); }
                return null;
            });
        }
        return schema;
    };

    const handleExternalClick = (field) => {
        setIFrameModalData(field);
        setOpenIframeModal(true);
    };

    const errorGetCallFieldsJXS = errorGetCallFields && (
        <Alert message={<FormattedMessage id="error.getCallFields" />} type="error" />
    );
    const errorSubmitFieldsJSX = submitFieldsError && (
        <Alert message={<FormattedMessage id="error.submitCallFields" />} type="error" />
    );

    return (
        <Formik
            initialValues={getInitialValues()}
            enableReinitialize
            onSubmit={(values) => submitCallFields(callId, callFields, values)}
            validationSchema={Yup.object().shape(getSchema())}
        >
            {(properties) => {
                const { touched, errors, isSubmitting, handleSubmit, isValid, setFieldValue } = properties;

                return (
                    <Modal
                        title={intl.formatMessage({ id: 'postCallReporting.header' })}
                        onCancel={back}
                        centered
                        visible
                        footer={[
                            <Space key="error" className="float-left">{errorGetCallFieldsJXS || errorSubmitFieldsJSX}</Space>,
                            <Button
                                key="submit"
                                onClick={handleSubmit}
                                disabled={isSubmitButtonDisabled(
                                    isSubmitting,
                                    isValid,
                                    errorGetCallFields,
                                )}
                                loading={isFetchingSubmitFields || isSubmitting}
                                type="primary"
                            >
                                <FormattedMessage id="postCallReporting.saveAndSubmit" />
                            </Button>,

                        ]}
                        maskClosable={false}
                        width={720}
                        bodyStyle={{ padding: '20px', maxHeight: '82vh', overflowY: 'auto' }}
                    >
                        <Spin
                            tip={intl.formatMessage({ id: 'app.loading' })}
                            spinning={isFetching}
                        >
                            <form data-testid="post-call-reporting-form">
                                {callFields
                    && callFields.map((field) => (
                        <Row key={field.id} gutter={16}>
                            <Col xs={24} md={12}>
                                <label
                                    htmlFor={field.id}
                                    style={{ display: 'block' }}
                                    className={
                                        field.options.required ? styles.required : null
                                    }
                                >
                                    {field.name}
                                </label>
                                <Text style={{ display: 'block' }}>
                                    {field.description}
                                </Text>
                            </Col>
                            <Col xs={24} md={12}>
                                <CallFields formikProps={properties} field={field} handleExternalClick={handleExternalClick} />
                                {errors[field.id] && touched[field.id] && (
                                    <div className={styles.inputFeedback}>
                                        {errors[field.id]}
                                    </div>
                                )}
                            </Col>
                            <Divider />
                        </Row>
                    ))}

                                {iFrameModalData?.options.url && openIframeModal && (
                                    <Iframe
                                        field={iFrameModalData}
                                        openIframeModal={openIframeModal}
                                        setOpenIframeModal={setOpenIframeModal}
                                        setFieldValue={setFieldValue}
                                        setIFrameModalData={setIFrameModalData}
                                    />
                                )}
                                {/* use this component to see the state of component */}
                                {/* <DisplayFormikState {...props} /> */}

                            </form>
                        </Spin>
                    </Modal>
                );
            }}
        </Formik>
    );
};
