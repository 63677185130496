import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import userIcon from '../../ag-icons/Rainmaker_soft_icon_37_User.svg';
import styles from './index.module.less';
import { goToLogin, logout } from '../../helpers';

export const AvatarDropdown = ({ userData }) => {
    const { SubMenu } = Menu;
    const intl = useIntl();
    const fullName = () => userData && `${userData.firstName} ${userData.lastName}`;
    const supportPhone = '+45 70 23 23 12'; // temporarily hard-coded.
    const supportEmail = 'support@agnitio.com'; // temporarily hard-coded.

    const avatarMenu = (
        <Menu selectedKeys={[]}>
            <div className="mb-2 mx-4 text-sm">
                <div className="text-gray-700">{ userData && userData.username }</div>
                <div className="mt-px text-gray-500">{ fullName() }</div>
                <div className="mt-4 mb-0 org text-gray-700">{userData && userData.organizationName}</div>
                <button
                    type="button"
                    className="mt-px text-blue-400"
                    onClick={() => goToLogin()}
                >
                    <FormattedMessage id="app.switchOrganization" />
                </button>
            </div>
            <Menu.Divider className="mb-px" />

            <SubMenu key="sub1" title={intl.formatMessage({ id: 'app.supportAndLegal' })} className="flex w-full my-1 py-1 px-1 text-gray-700 text-sm hover:bg-gray-200">
                <Menu.ItemGroup title={intl.formatMessage({ id: 'app.support' })}>
                    <div className="px-4 text-xs font-light text-gray-700">
                        <p>{supportPhone}</p>
                        <p><a href={`mailto: ${supportEmail}`}>{supportEmail}</a></p>
                    </div>
                </Menu.ItemGroup>
                <Menu.ItemGroup title={intl.formatMessage({ id: 'app.legal' })}>
                    <a
                        href={process.env.REACT_APP_EULA_DOC_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex w-full my-1 py-1 px-4 text-xs text-blue-400 hover:text-blue-600 hover:bg-gray-200"
                    >
                        <FormattedMessage id="app.eula" />
                    </a>
                    <a
                        href={process.env.REACT_APP_PRIVACY_DOC_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex w-full my-1 py-1 px-4 text-xs text-blue-400 hover:text-blue-600 hover:bg-gray-200"
                    >
                        <FormattedMessage id="app.privacyAgreement" />
                    </a>
                </Menu.ItemGroup>
            </SubMenu>

            <button type="button" onClick={() => logout()} className="flex w-full my-1 py-1 px-4 text-gray-700 text-sm hover:bg-gray-200">
                <FormattedMessage id="app.logout" />
            </button>
        </Menu>
    );

    return (
        <Dropdown overlay={avatarMenu} overlayClassName="shadow-xl cursor-pointer hover:cursor-pointer">
            <span className={styles.action + styles.account}>
                <Avatar className={`${styles.avatar} cursor-pointer`} size="small" src={userIcon} alt="avatar" />
            </span>
        </Dropdown>
    );
};

AvatarDropdown.propTypes = {
    userData: PropTypes.shape({
        organizationName: PropTypes.string,
        username: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    }).isRequired,
};
