import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { HeaderRightContent } from '../../elements/HeaderRightContent';
import styles from './index.module.less';
import { SiderContent } from '../../components/SiderContent';

export const MainLayout = (props) => {
    const { Header, Content } = Layout;
    const { children, userData, userPermissions } = props;

    return (
        <Layout className={styles.mainLayout}>
            <SiderContent
                userPermissions={userPermissions}
            />
            <Layout className="site-layout">
                <Header className={styles.siteLayoutBackground}>
                    <HeaderRightContent
                        key="rightContent"
                        userData={userData}
                    />
                </Header>
                <Content className={`${styles.content} ${styles.siteLayoutBackground}`}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

MainLayout.propTypes = {
    userData: PropTypes.shape({
        username: PropTypes.string,
    }).isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }),
    userPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

MainLayout.defaultProps = {
    location: {
        pathname: '',
    },
};
