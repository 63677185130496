/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callsActions } from '../actions';
import { getDashboardTwoWeeksWindowTimeFromNowPayload } from '../../../helpers';

export const useGetDashboardCalls = () => {
    const dispatch = useDispatch();
    const conductedCalls = useSelector((state) => state.calls.getIn(['conductedCalls', 'entities']));
    const plannedCalls = useSelector((state) => state.calls.getIn(['plannedCalls', 'entities']));
    const isFetching = useSelector((state) => state.calls.getIn(['conductedCalls', 'isFetching']));
    const error = useSelector((state) => state.calls.getIn(['conductedCalls', 'error']));

    const loadCalls = () => {
        dispatch(callsActions.getDashboardCallsAsync(getDashboardTwoWeeksWindowTimeFromNowPayload()));
    };

    /* initial load */
    useEffect(() => {
        loadCalls();
    }, [dispatch]);

    return {
        plannedCalls,
        conductedCalls,
        isFetching,
        error,
        loadCalls,
    };
};
