import { api } from '../../../../config/api';
import {
    sendProvisionSuccessful,
    sendProvisionHasError,
    sendProvisionStart,
} from '../../action';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';

export function* provisionWorker({ payload }) {
    const options = {
        fetcher: api.provision.sendProvision,
        fetcherParam: payload,
        startFetching: sendProvisionStart,
        successAction: sendProvisionSuccessful,
        setErrorAction: sendProvisionHasError,
    };
    yield makeRequestWorker(options);
}
