import { api } from '../../../../config/api';
import { makeRequestWorker } from '../../../../workers/makeRequestWorker';
import { callsActions } from '../../actions';

export function* getContactsWorker({ payload }) {
    const options = {
        fetcher: api.calls.getContacts,
        fetcherParam: payload,
        successAction: callsActions.fillContacts,
        startFetching: callsActions.getContactsStartFetching,
        stopFetching: callsActions.getContactsStopFetching,
        setErrorAction: callsActions.getContactsError,
        schema: null,
    };

    yield makeRequestWorker(options);
}
