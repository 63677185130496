import React from 'react';
import { ReactComponent as ContactIcon } from '../../ag-icons/Rainmaker_soft_icon_21_Contact.svg';
import { SelectedItems } from '../SelectedItems';
import { convertCustomContactArrayToTagsArray } from '../../helpers';
import { PropTypeArray, PropTypeFunc } from '../../helpers/propTypes';

export function ListOfContacts({ contacts, onDelete }) {
    return (
        <SelectedItems
            items={convertCustomContactArrayToTagsArray(
                contacts,
                ContactIcon,
                '-mr-2 w-5 text-grey-500 fill-current',
            )}
            onCloseTag={(item) => onDelete(item)}
        />
    );
}

ListOfContacts.defaultProps = {
    contacts: [],
};
ListOfContacts.propTypes = {
    contacts: PropTypeArray,
    onDelete: PropTypeFunc.isRequired,
};
